import List from "../../layout/List/List";
import styles from "./OurServicesMain.module.scss";

const services = [
  {
    title: "Nieruchomość",
    list: [
      "Konsultacje w kwestii nabycia nieruchomości w Bułgarii",
      "Indywidualny wybór nieruchomości podyktowany wymaganiami Klienta",
      "Umówione spotkanie i odbiór Klienta w wybranych portach lotniczych w Bułgarii",
      "Oględziny wybranych nieruchomości w Bułgarii",
      "Konsultacje prawne w zakresie nabycia nieruchomości",
      "Rejestracja nieruchomości w Urzędzie Skarbowym, sądzie, wpis do rejestru państwowego",
      "Wykonanie remontów nieruchomości",
      "Pomóc w zakresie zakupu wyposażenia nieruchomości",
      "Pomóc w wynajmie i zarządzaniu kupionej nieruchomości oraz usługi z tym związane (sprzątanie wymiana pościeli, pielęgnacja ogrodu, basenu itp.)",
      "Rozliczanie opłat za media",
      "Rozliczanie podatków od nieruchomości",
    ],
  },
  {
    title: "Transport i zakwaterowanie",
    list: [
      "Zapewniamy bezpłatny transfer z i na lotnisko, a także bezpłatny nocleg dla 2-4 osób podczas przeglądania naszych ofert dla klientów, którzy dokonali przedpłaty przy zakupie apartamentu.",
      "Transfer",
      "Zapewnimy Ci rejestrację samochodu w urzędzie",
      "Wypożyczalnia samochodów",
      "Cumowanie statków",
    ],
  },
  {
    title: "Obsługa prawna",
    list: [
      "Usługi prawne i notarialne",
      "Usługi tłumaczeniowe",
      "Ubezpieczenia majątkowe",
    ],
  },
  {
    title: "Budowa i remont",
    list: [
      "Usługi rzemieślnicze",
      "Przebudowa i remonty nieruchomości",
      "Roboty budowlane",
    ],
  },
];

const OurServicesMain = () => {
  return (
    <>
      <section className={styles.ourServicesMain}>        
          <h2 className={`${styles.ourServicesMain__title} titleH2`}>
            Możemy Ci pomóc w każdym aspekcie zakupu nieruchomości w Bułgarii.{" "}
          </h2>
          <h4 className={styles.ourServicesMain__subtitle}>
            Pomożemy Ci, nawet jeśli nie kupiłeś u nas nieruchomosci. <br />W jakich obszarach możemy Ci pomóc?
          </h4>        
        <div className={styles.ourServicesMain__list}>
          {services.map((item, index) => (
            <List title={item.title} list={item.list} key={index} />
          ))}
        </div>
      </section>
    </>
  );
};

export default OurServicesMain;
