import { useState } from 'react'
import Topbar from './Topbar/Topbar'
import Sidebar from './Sidebar/Sidebar'



const Navbar = () => {
const [sidebarOpened,setSidebarOpened] = useState(false)

const openSidebar = () => setSidebarOpened(true)
const closeSidebar = () => setSidebarOpened(false)

  return (
    <>
      <Topbar openSidebar={openSidebar} />
      <Sidebar show={sidebarOpened} onClose={closeSidebar} />
    </>
  );
}

export default Navbar