import styles from "./AboutMain.module.scss";

function AboutMain() {
  return (
    <>
      <main className={styles.aboutMain}>
        <h2 className={`${styles.aboutMain__title} titleH2 textCenter`}>
          Wierzymy w uczciwą współpracę. <br /> 
          Wszystko co mówimy jest prawdą!
        </h2>
        <p className={`${styles.aboutMain__contentTxt} contentTxt`}>
          Od kilku lat zajmujemy się sprzedażą nieruchomości w
          krajach i lokalizacjach nad morzem. Dla zainteresowanych zapewniamy
          pełną obsługę – od oferty, przez zwiedzanie nieruchomości (w tym
          noclegi i transfery lotniskowe) aż po sprzedaż i późniejszy wynajem
          nieruchomości.
        </p>
        <h6 className={styles.aboutMain__mottoTitle}>Nasze motto:</h6>
        <p className={styles.aboutMain__mottoTxt}>
          "Nasza relacja nie kończy się na sprzedaży nieruchomości, ale zaczyna
          się!"
        </p>
      </main>
    </>
  );
}

export default AboutMain;
