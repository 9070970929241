import { aboutHeaderImage, aboutHeaderWebpImage } from "../../../assets/images";
import Header from "../../global/Header/Header";

import styles from "./AboutHeader.module.scss";

const AboutHeader = () => {
  return (
    <Header
      webpImage={aboutHeaderWebpImage}
      mainImage={aboutHeaderImage}
      backgroundClass={styles.aboutHeader}
      title="O Firmie"
      subtitle="Filozofia i wartości naszej Firmy"
    />
  );
};

export default AboutHeader;
