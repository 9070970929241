import React from 'react'
import AdminArchiveWrapper from '../../../components/Admin/AdminArchivePage/AdminArchiveWrapper/AdminArchiveWrapper'


const AdminArchivePage = () => {
  return (
    <AdminArchiveWrapper/>
  )
}

export default AdminArchivePage