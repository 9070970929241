import ReactQuill from "react-quill-new";
import {
  objectStatusNames,
  objectStatusNamesArr,
  typeOptionNames,
} from "../../../constants/propertyTexts";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { useFormValue } from "../../../hooks/useFormValue";
import {
  EPropertyTypes,
  IProperty,
  IPropertyImage,
} from "../../../models/IProperty";
import { ISelectValue } from "../../../models/UI/ISelectValue";
import MainInput from "../../layout/MainInput/MainInput";
import Select from "../../layout/Select/Select";
import AdminPropertiesModalForm from "./AdminPropertiesFunctionButtons/AdminPropertiesFunctionButtons";

import "react-quill/dist/quill.snow.css"; // Import Quill styles
import { crossIcon, primaryIcon, recomendationIcon } from "../../../assets/svg";
import MainBtn from "../../layout/MainBtn/MainBtn";
import Map from "../../layout/Map/Map";
import Svg from "../../layout/Svg/Svg";

import { FormEventHandler, memo, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorTypes } from "../../../constants/errors";
import { useMapSearch } from "../../../hooks/useMapSearch";
import {
  AddPropertyForm,
  AddPropertyRequest,
} from "../../../models/api/addProperty";
import { IFormFunction } from "../../../models/UI/IFormFunctions";
import TransitionProvider, {
  TransitionStyleTypes,
} from "../../../providers/TransitionProvider";
import { adminPropertiesPagePath } from "../../../router/path";
import {
  addProperty,
  addPropertyEquipment,
  addPropertyLocation,
  deletePropertyEquipment,
  deletePropertyLocation,
  editProperty,
  editPropertyEquipment,
  editPropertyLocation,
  getPropertyFilters,
  setAddEquipmentError,
  setAddLocationError,
  setCreateError,
  setEditError,
} from "../../../store/slices/propertiesSlice";
import { openTooltip } from "../../../store/slices/UISlice";
import { dispatchWithErrorHandling } from "../../../store/tools/dispatchWithErrorHandling";
import { formatDate } from "../../../utils/functions/date";
import { imagePath } from "../../../utils/functions/files";
import DataLoader from "../../layout/DataLoader/DataLoader";
import DeleteModal from "../../layout/DeleteModal/DeleteModal";
import LoaderPopup from "../../layout/LoaderPopup/LoaderPopup";
import styles from "./AdminPropertiesForm.module.scss";

type Props = {
  title: string;
  property?: IProperty;
  isEdit?: boolean;
};

interface IForm extends AddPropertyForm {}

const quilModules = {
  toolbar: [
    [{ size: [] }],
    ["bold", "italic", "underline"],
    [{ color: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ align: [] }],
    ["link"],
    ["clean"],
  ],
};

const objectStatusOptions: ISelectValue[] = objectStatusNamesArr.map(
  (item) => ({
    value: item,
    name: objectStatusNames[item],
  })
);

const FormSelectOption = ({
  onDelete,
  disabled,
}: {
  onDelete: () => void;
  disabled?: boolean;
}) => (
  <div className={styles.adminPropertiesForm__selectOption}>
    <button
      disabled={disabled}
      type="button"
      onClick={(e) => {
        e.stopPropagation();
        onDelete();
      }}
      className={styles.adminPropertiesForm__selectOptionBtn}
    >
      <Svg
        id={crossIcon}
        className={styles.adminPropertiesForm__selectOptionIcon}
      />
    </button>
  </div>
);

const PropertyImage = memo(
  ({
    item,
    index,
    mainImageId,
    mainImageIndex,
    setMainImage,
    onDeleteSavedImage,
    onDeleteUploadedImage,
  }: {
    item: File | IPropertyImage;
    index: number;
    mainImageId: number | null;
    mainImageIndex: number | null;
    setMainImage: (idOrIndex: number, isSaved?: boolean) => void;
    onDeleteSavedImage: (item: IPropertyImage) => void;
    onDeleteUploadedImage: (item: File) => void;
  }) => {
    const isSaved = "id" in item;
    const path = isSaved ? imagePath(item.path) : URL.createObjectURL(item);
    return (
      <div className={styles.adminPropertiesForm__imageWrapper}>
        <img
          crossOrigin="anonymous"
          src={path}
          alt="upload"
          className={styles.adminPropertiesForm__img}
        />
        <div className={styles.adminPropertiesForm__imgOverlay}>
          <button
            onClick={() => setMainImage(isSaved ? item.id : index, isSaved)}
            type="button"
            className={`${styles.adminPropertiesForm__imgOverlayBtn} ${
              (isSaved && mainImageId === item.id) ||
              (!isSaved && index === mainImageIndex)
                ? styles.adminPropertiesForm__imgOverlayBtn_active
                : ""
            }`}
          >
            <Svg
              id={primaryIcon}
              className={styles.adminPropertiesForm__imgPrimaryIcon}
            />
          </button>
          <button
            type="button"
            onClick={() =>
              isSaved ? onDeleteSavedImage(item) : onDeleteUploadedImage(item)
            }
            className={styles.adminPropertiesForm__imgOverlayBtn}
          >
            <Svg
              id={crossIcon}
              className={styles.adminPropertiesForm__imgDeleteIcon}
            />
          </button>
        </div>
      </div>
    );
  }
);

const AdminPropertiesForm = ({ title, property, isEdit }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const searchLocation = useMapSearch();
  const locations = useAppSelector(
    (state) => state.properties.filterCounts.locations
  );
  const error = useAppSelector(
    (state) => state.properties[isEdit ? "editError" : "createError"]
  );
  const addLocationError = useAppSelector(
    (state) => state.properties.addLocationError
  );
  const addEquipmentError = useAppSelector(
    (state) => state.properties.addEquipmentError
  );
  const types = useAppSelector((state) => state.properties.filterCounts.types);
  const equipments = useAppSelector(
    (state) => state.properties.filterCounts.equipments
  );
  const [deletingLocationId, setDeletingLocationId] = useState<number | null>(
    null
  );
  const [deletingEquipmentId, setDeletingEquipmentId] = useState<number | null>(
    null
  );

  const [loading, setLoading] = useState(false);
  const [mapSearchValue, setMapSearchValue] = useState("");
  const locationSearchTimeoutRef = useRef<NodeJS.Timer | null>(null);
  const {
    onChange,
    onNumberChange,
    onChangeSelect,
    formData,
    setFormData,
    clearInputError,
    getError,
  } = useFormValue(
    {
      title: "",
      location: "",
      type: "",
      price: "",
      rooms: "",
      flat: "",
      plotSize: "",
      floor: "",
      distanceFromTheBeach: "",
      equipments: [],
      objectStatus: "",
      description: "",
      locationOnMapLat: "",
      locationOnMapLng: "",
      images: [],
      savedImages: [],
      mainImageId: null,
      mainImageIndex: null,
    } as IForm,
    isEdit ? setEditError : setCreateError,
    error
  );

  useEffect(() => {
    dispatch(getPropertyFilters());

    return () => {
      isEdit ? dispatch(setEditError(null)) : dispatch(setCreateError(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (property) {
      const [locationOnMapLat, locationOnMapLng] = (
        property.locationOnMap || ","
      )?.split(",");
      setFormData({
        title: property.title,
        location: property.location.id,
        type: property.type,
        price: property.price?.toString() || "",
        rooms: property.rooms?.toString() || "",
        flat: property.flat?.toString() || "",
        plotSize: property.plotSize?.toString() || "",
        floor: property.floor?.toString() || "",
        distanceFromTheBeach: property.distanceFromTheBeach?.toString() || "",
        equipments: property.equipments.map((item) => item.id),
        objectStatus: property.objectStatus,
        description: property.description || "",
        locationOnMapLat,
        locationOnMapLng,
        images: [],
        savedImages: property.images,
        mainImageId:
          property.images.find((item) => item.isMainImage)?.id || null,
        mainImageIndex: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property]);

  useEffect(() => {
    if (formData.type === EPropertyTypes.buisnessCenter && formData.rooms) {
      onChangeSelect("rooms", "");
    }
    if (formData.type !== EPropertyTypes.house && formData.plotSize) {
      onChangeSelect("plotSize", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.type]);

  const onSearchLocation = (value: string) => {
    setMapSearchValue(value);

    if (locationSearchTimeoutRef.current) {
      clearTimeout(locationSearchTimeoutRef.current);
      locationSearchTimeoutRef.current = null;
    }
    locationSearchTimeoutRef.current = setTimeout(() => {
      searchLocation(value, ({ x, y }) => {
        onChangeSelect("locationOnMapLat", y);
        onChangeSelect("locationOnMapLng", x);
      });
    }, 600);
  };

  const locationOptions: ISelectValue[] = locations
    ? [...locations]
        .sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        })
        .map((item) => ({
          value: item.id.toString(),
          name: item.name,
          button: (
            <FormSelectOption
              onDelete={() => setDeletingLocationId(item.id)}
              disabled={!!(item.count || item.archiveCount)}
            />
          ),
        }))
    : [];

  const equipmentOptions: ISelectValue[] = [...equipments]
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    })
    .map((item) => ({
      value: item.id.toString(),
      name: item.name,
      button: (
        <FormSelectOption
          onDelete={() => setDeletingEquipmentId(item.id)}
          disabled={!!(item.count || item.archiveCount)}
        />
      ),
    }));

  const typesOptions = Object.keys(types).map((item) => ({
    value: item,
    name: typeOptionNames[item as EPropertyTypes],
  }));

  const fields: {
    label: string;
    key: keyof IForm;
    inputType?: string;
    isMultiselect?: boolean;
    showCondition?: boolean;
    selectOptions?: ISelectValue[];
    openState?: boolean;
    functions?: IFormFunction;
  }[] = [
    {
      label: "Title",
      key: "title",
    },
    {
      label: "Location",
      key: "location",
      selectOptions: locationOptions,
      openState: !!deletingLocationId,
      functions: {
        add: (value) =>
          dispatchWithErrorHandling(
            dispatch,
            addPropertyLocation({ name: value }),
            "Location added"
          ),
        edit: (id, value) =>
          dispatchWithErrorHandling(
            dispatch,
            editPropertyLocation({ id, name: value }),
            "Location updated"
          ),
        clearAddError: () => dispatch(setAddLocationError(null)),

        duplicateErrorTxt: "This location already exists.",
        addError: addLocationError,
        editDisabled: !formData.location,
        initialValue: locationOptions.find(
          (item) => +item.value === +formData.location
        )?.name,
      },
    },
    {
      label: "Property type",
      key: "type",
      selectOptions: typesOptions,
    },
    {
      label: "Rooms",
      key: "rooms",
      inputType: "number",
      showCondition:
        formData.type === EPropertyTypes.house ||
        formData.type === EPropertyTypes.building,
    },
    {
      label: "Area",
      key: "flat",
      inputType: "number",
    },
    {
      label: "100 sqm land",
      key: "plotSize",
      inputType: "number",
      showCondition: formData.type === EPropertyTypes.house,
    },
    {
      label: "Floor",
      key: "floor",
      inputType: "number",
    },
    {
      label: "Property Status",
      key: "objectStatus",
      selectOptions: objectStatusOptions,
    },
    {
      label: "Distance to the Beach",
      key: "distanceFromTheBeach",
      inputType: "number",
    },
    {
      label: "Additional information",
      key: "equipments",
      isMultiselect: true,
      selectOptions: equipmentOptions,
      openState: !!deletingEquipmentId,
      functions: {
        add: (value) =>
          dispatchWithErrorHandling(
            dispatch,
            addPropertyEquipment({ name: value }),
            "Additional information added"
          ),
        edit: (id, value) =>
          dispatchWithErrorHandling(
            dispatch,
            editPropertyEquipment({ id, name: value }),
            "Additional information updated"
          ),
        clearAddError: () => dispatch(setAddEquipmentError(null)),
        duplicateErrorTxt: "This information already exists.",

        addError: addEquipmentError,
        editDisabled: formData.equipments.length !== 1,
        initialValue: equipmentOptions.find(
          (item) => +item.value === +formData.equipments[0]
        )?.name,
      },
    },
    {
      label: "Price",
      key: "price",
      inputType: "number",
    },
  ];

  const filterImages = (value: FileList) => {
    const { length, ...files } = value;
    let allFiles = Object.values(files);
    const filesArr = formData.images.filter((item) =>
      allFiles.every((file, index) => file.name !== item.name)
    );
    return filesArr;
  };

  const onClickUploadImg = (e: React.MouseEvent<HTMLInputElement>) => {
    (e.target as HTMLInputElement).value = "";
  };

  const onUploadImg = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files) return;
    const filteredImages = filterImages(files);
    clearInputError("images");
    setFormData((prevState) => ({
      ...prevState,
      images: [...filteredImages, ...Array.from(files)],
    }));
  };

  const onDeleteUploadedImage = (image: File) => {
    const filteredImages = formData.images.filter((item) => item !== image);
    setFormData((prevState) => ({
      ...prevState,
      images: filteredImages,
    }));
  };
  const onDeleteSavedImage = (image: IPropertyImage) => {
    const filteredImages = formData.savedImages.filter(
      (item) => item.id !== image.id
    );
    setFormData((prevState) => ({
      ...prevState,
      savedImages: filteredImages,
    }));
  };

  const setMainImage = (idOrIndex: number, isSaved?: boolean) => {
    if (formData.mainImageIndex !== null)
      onChangeSelect("mainImageIndex", null);
    if (formData.mainImageId !== null) onChangeSelect("mainImageId", null);
    if (isSaved) onChangeSelect("mainImageId", idOrIndex);
    else onChangeSelect("mainImageIndex", idOrIndex);
  };

  const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    (async () => {
      setLoading(true);
      try {
        const {
          savedImages,
          locationOnMapLat,
          location,
          locationOnMapLng,
          mainImageId,
          title,
          ...mainData
        } = formData;
        const reqData: AddPropertyRequest = {
          ...mainData,
          title: title.trim(),
          locationId: location as number,
          locationOnMap:
            locationOnMapLat &&
            locationOnMapLng &&
            `${locationOnMapLat},${locationOnMapLng}`,
        };
        if (isEdit) {
          if (!property?.id) return;
          reqData.savedImages = savedImages.map((item) => item.id);
          reqData.mainImageId = mainImageId;

          const res = (await dispatch(
            editProperty({ id: property.id, ...reqData })
          )) as {
            error?: unknown;
          };
          if (res.error) throw new Error("error");
        } else {
          const res = (await dispatch(addProperty(reqData))) as {
            error?: unknown;
          };
          if (res.error) throw new Error("error");
        }
        navigate(adminPropertiesPagePath);
        dispatch(openTooltip(`Property ${isEdit ? "updated" : "added"}`));
      } catch (error) {
        dispatch(openTooltip("Please fill in all required fields."));
      } finally {
        setLoading(false);
      }
    })();
  };

  return (
    <>
      <section className={styles.adminPropertiesForm}>
        <header className={styles.adminPropertiesForm__header}>
          <h2 className={"titleH3Light"}>{title}</h2>
          {property?.createdAt && (
            <p className={styles.adminPropertiesForm__dateTxt}>
              Date of create: <strong>{formatDate(property?.createdAt)}</strong>
            </p>
          )}
        </header>
        {isEdit && !property ? (
          <DataLoader isLoaded={false} show />
        ) : (
          <form
            onKeyDown={(e) => {
              if (e.key === "Enter") e.preventDefault();
            }}
            onSubmit={onSubmit}
            className={styles.adminPropertiesForm__wrapper}
          >
            <div className={styles.adminPropertiesForm__container}>
              <div className={styles.adminPropertiesForm__wrapperMain}>
                {fields.map((item, index) => (
                  <TransitionProvider
                    inProp={
                      typeof item.showCondition === "boolean"
                        ? item.showCondition
                        : true
                    }
                    height={"150px"}
                    style={TransitionStyleTypes.height}
                    key={index}
                    className={styles.adminPropertiesForm__field}
                  >
                    <label
                      htmlFor={item.key}
                      className={styles.adminPropertiesForm__label}
                    >
                      {item.label}
                    </label>
                    {item?.selectOptions ? (
                      <Select
                        isInvalid={!!getError(item.key)}
                        name={"Select"}
                        valuesArr={item?.selectOptions}
                        onChange={(val) => onChangeSelect(item.key, val)}
                        disableState={!item?.selectOptions.length}
                        selectedValueProp={
                          (Array.isArray(formData[item.key])
                            ? (formData[item.key] as number[]).map((item) =>
                                item.toString()
                              )
                            : formData[item.key]?.toString() || "") as
                            | string
                            | string[]
                        }
                        isMultiSelect={item.isMultiselect}
                        isWithInput={item?.selectOptions.length > 10}
                        openState={item.openState}
                        disableTranslate
                      />
                    ) : (
                      <MainInput
                        autoComplete="off"
                        isInvalid={!!getError(item.key)}
                        type={"text"}
                        minLength={item?.inputType === "number" ? 0 : undefined}
                        value={formData[item.key] as string}
                        onChange={
                          item?.inputType === "number"
                            ? onNumberChange
                            : onChange
                        }
                        name={item.key}
                        id={item.key}
                      />
                    )}
                    {item?.functions && (
                      <AdminPropertiesModalForm
                        {...item?.functions}
                        id={
                          Array.isArray(formData[item.key])
                            ? +(formData[item.key] as number[])[0]
                            : +(formData[item.key] as string)
                        }
                      />
                    )}
                  </TransitionProvider>
                ))}
                <label className={styles.adminPropertiesForm__label}>
                  Property description
                </label>
                <ReactQuill
                  modules={quilModules}
                  formats={[
                    "bold",
                    "italic",
                    "underline",
                    "strike",
                    "list",
                    "bullet",
                    "link",
                    "size",
                    "color",
                  ]}
                  style={{ minHeight: "100px", background: "#fff" }}
                  value={formData.description}
                  onChange={(val) => onChangeSelect("description", val)}
                />
                <p
                  className={`errorTxt ${
                    getError("description") ? "errorTxt_active" : ""
                  }`}
                >
                  The description is a required field
                </p>
              </div>
              <div className={styles.adminPropertiesForm__wrapperAdds}>
                <h4 className={styles.adminPropertiesForm__addsTitle}>
                  Location
                </h4>
                <div className={styles.adminPropertiesForm__addsField}>
                  <label
                    htmlFor="locationOnMapLat"
                    className={styles.adminPropertiesForm__label}
                  >
                    Geographical coordinates
                  </label>
                  <MainInput
                    isInvalid={
                      !!(
                        getError("locationOnMapLat") ||
                        getError("locationOnMapLng")
                      )
                    }
                    placeholder="Search"
                    value={mapSearchValue}
                    onChange={(e) => onSearchLocation(e.target.value)}
                  />
                </div>

                <div className={styles.adminPropertiesForm__mapWrapper}>
                  <Map
                    values={[
                      +formData.locationOnMapLat,
                      +formData.locationOnMapLng,
                    ]}
                    initialLocation={[42.7339, 25.4858]}
                    onChange={(val) => {
                      setFormData((prevstate) => ({
                        ...prevstate,
                        locationOnMapLat: val[0].toString(),
                        locationOnMapLng: val[1].toString(),
                      }));
                      clearInputError("locationOnMapLat");
                      clearInputError("locationOnMapLng");
                    }}
                  />
                </div>
                <h5 className={styles.adminPropertiesForm__addsTitle}>
                  Select image
                </h5>
                <div className={styles.adminPropertiesForm__recomendation}>
                  <Svg
                    id={recomendationIcon}
                    className={styles.adminPropertiesForm__recomedationIcon}
                  />
                  <p
                    className={`${styles.adminPropertiesForm__recomendationTxt} ${styles.adminPropertiesForm__label}`}
                  >
                    <span>Recommended image size: 800x600 pixels.</span>
                    <span>Maximum file size: 5&nbsp;МБ.</span>
                  </p>
                </div>
                <div className={styles.adminPropertiesForm__imageUploadBlock}>
                  <p
                    className={`${styles.adminPropertiesForm__label} ${styles.adminPropertiesForm__imageLabel}`}
                  >
                    Upload Image
                  </p>
                  <label
                    className={styles.adminPropertiesForm__fileLabel}
                    htmlFor="imgUploadInput"
                  >
                    <MainBtn
                      className={styles.adminPropertiesForm__fileBtn}
                      type="button"
                    >
                      Choose image
                    </MainBtn>
                    <input
                      type="file"
                      name="images"
                      id="imgUploadInput"
                      accept="image/*"
                      onChange={onUploadImg}
                      onClick={onClickUploadImg}
                      multiple
                      className={styles.adminPropertiesForm__fileInput}
                    />
                  </label>
                </div>
                <p
                  className={`errorTxt textCenter ${
                    getError("images") ? "errorTxt_active" : ""
                  }`}
                >
                  {getError("images") === ErrorTypes.emptyField
                    ? "Uploading an image is required"
                    : getError("images") === ErrorTypes.largeFile
                    ? "The file is too big"
                    : ""}
                  &nbsp;
                </p>
                <div
                  className={`scrollbarDef ${styles.adminPropertiesForm__imageList}`}
                >
                  {formData.images.map((item, index) => (
                    <PropertyImage
                      key={item.name}
                      item={item}
                      index={index}
                      mainImageId={formData.mainImageId}
                      mainImageIndex={formData.mainImageIndex}
                      setMainImage={setMainImage}
                      onDeleteSavedImage={onDeleteSavedImage}
                      onDeleteUploadedImage={onDeleteUploadedImage}
                    />
                  ))}
                  {formData.savedImages.map((item, index) => (
                    <PropertyImage
                      key={item.id}
                      item={item}
                      index={index}
                      mainImageId={formData.mainImageId}
                      mainImageIndex={formData.mainImageIndex}
                      setMainImage={setMainImage}
                      onDeleteSavedImage={onDeleteSavedImage}
                      onDeleteUploadedImage={onDeleteUploadedImage}
                    />
                  ))}
                </div>
              </div>
            </div>
            <MainBtn className={styles.adminPropertiesForm__submitBtn}>
              Save
            </MainBtn>
          </form>
        )}
      </section>
      <LoaderPopup show={loading} />
      <DeleteModal
        onConfirm={() => {
          try {
            if (deletingLocationId) {
              return dispatchWithErrorHandling(
                dispatch,
                deletePropertyLocation({ id: deletingLocationId }),
                "Location  deleted"
              );
            }
          } catch (error) {}
        }}
        title={"Are you sure you want to delete?"}
        show={!!deletingLocationId}
        onClose={() => setDeletingLocationId(null)}
      />
      <DeleteModal
        onConfirm={() => {
          try {
            if (deletingEquipmentId) {
              return dispatchWithErrorHandling(
                dispatch,
                deletePropertyEquipment({ id: deletingEquipmentId }),
                "Additional information deleted"
              );
            }
          } catch (error) {}
        }}
        title={"Are you sure you want to delete?"}
        show={!!deletingEquipmentId}
        onClose={() => setDeletingEquipmentId(null)}
      />
    </>
  );
};

export default AdminPropertiesForm;
