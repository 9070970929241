import { useEffect, useRef, useState } from "react";
import TransitionProvider, {
  TransitionStyleTypes,
} from "../../../../../providers/TransitionProvider";
import MainBtn from "../../../../layout/MainBtn/MainBtn";
import MainInput from "../../../../layout/MainInput/MainInput";

import { IFormFunction } from "../../../../../models/UI/IFormFunctions";
import styles from "./AdminPropertiesFunctionModal.module.scss";

type Props = {
  buttonRef: React.RefObject<HTMLElement>;
  show: boolean;
  onClose: () => void;
  onSave: (value: string) => Promise<void>;
  initialValue?: string;
  error?: IFormFunction["addError"];
  duplicateErrorTxt?: string;
  clearAddError?: () => void;
};

const AdminPropertiesFunctionModal = ({
  buttonRef,
  show,
  onClose,
  onSave,
  initialValue,
  error: formError,
  duplicateErrorTxt,
  clearAddError,
}: Props) => {
  const [value, setValue] = useState(initialValue || "");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const ref = useRef(null);
  const loadingRef = useRef(loading);
  const showRef = useRef(show);

  const errorTxtShowing =
    typeof formError === "object" && formError?.name && duplicateErrorTxt;

  useEffect(() => {
    loadingRef.current = loading;
  }, [loading]);

  useEffect(() => {
    showRef.current = show;

    if (!show) {
      if (clearAddError && formError) clearAddError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    if (initialValue) setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      const el = e.target as HTMLElement;
      const isRef = [ref, buttonRef].every(
        (value) => value.current && !value.current.contains(el)
      );

      if (showRef.current && !loadingRef.current && isRef) {
        onClose();
      }
    };
    document.addEventListener("click", checkIfClickedOutside);

    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    if (error) setError(false);
    if (clearAddError && formError) clearAddError();
  };

  useEffect(() => {
    if (!show && value) {
      setValue(initialValue || "");
      setError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const onSaveChange = () => {
    (async () => {
      try {
        setLoading(true);
        await onSave(value);
        onClose();
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    })();
  };

  return (
    <TransitionProvider
      className={styles.adminPropertiesFunctionModal}
      inProp={show}
      style={TransitionStyleTypes.opacity}
    >
      <div ref={ref}>
        <div className={styles.adminPropertiesFunctionModal__main}>
          <MainInput
            isInvalid={error}
            onChange={onChange}
            value={value}
            placeholder={initialValue || "Add"}
          />

          <div
            className={`${styles.adminPropertiesFunctionModal__loader} ${
              loading ? styles.adminPropertiesFunctionModal__loader_active : ""
            }`}
          >
            <div
              className={styles.adminPropertiesFunctionModal__loaderInner}
            ></div>
          </div>
        </div>
        {
          <p className={`errorTxt ${errorTxtShowing && "errorTxt_active"}`}>
            {duplicateErrorTxt}&nbsp;
          </p>
        }
        <div className={styles.adminPropertiesFunctionModal__buttons}>
          <MainBtn
            disabled={loading || !value || value === initialValue}
            type="button"
            onClick={onSaveChange}
          >
            Save
          </MainBtn>
          <MainBtn disabled={loading} type="button" onClick={onClose}>
            Cancel
          </MainBtn>
        </div>
      </div>
    </TransitionProvider>
  );
};

export default AdminPropertiesFunctionModal;
