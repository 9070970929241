import {
  instalmentHeaderImage,
  instalmentHeaderWebpImage,
} from "../../../assets/images";
import Header from "../../global/Header/Header";

import styles from "./InstallmentPlanHeader.module.scss";

const InstallmentPlanHeader = () => {
  return (
    <Header
      webpImage={instalmentHeaderWebpImage}
      mainImage={instalmentHeaderImage}
      backgroundClass={styles.aboutHeader}
      title="Plan ratalny"
      subtitle="Współpracujemy bezpośrednio z developerami"
    />
  );
};

export default InstallmentPlanHeader;
