import CookiePrivacyWrapper from "../components/CookiePrivacyPage/CookiePrivacyWrapper/CookiePrivacyWrapper";
import Head from '../components/global/Head/Head'


const CookiePrivacyPage = () => {
  return (
    <>
    <Head
        keyword={"ochrona danych osobowych, poufność, polityka prywatności, cookies, bezpieczeństwo danych, dane osobowe, RODO, "}
        title={
          "Reality Bulgaria | Polityka prywatności i bezpieczeństwo"
        }
        ogTitle={
          "Reality Bulgaria | Polityka prywatności i bezpieczeństwo"
        }
        description={
          "Rzetelnie chronimy Twoje dane osobowe i zapewniamy całkowitą poufność podczas korzystania z naszego serwisu"
        }
        ogDescription={
          "Rzetelnie chronimy Twoje dane osobowe i zapewniamy całkowitą poufność podczas korzystania z naszego serwisu"
        }
      />
      <CookiePrivacyWrapper/>      
    </>
  )
}

export default CookiePrivacyPage
