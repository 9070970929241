import {
  aboutPagePath,
  contactPagePath,
  forBuyerPagePath,
  installmentPagePath,
  ourServicesPagePath,
  propertiesPagePath,
} from "../router/path";

export const menuItems = [
  {
    link: propertiesPagePath,
    text: "Nieruchomość",
  },
  {
    link: aboutPagePath,
    text: "O nas",
  },
  {
    link: ourServicesPagePath,
    text: "Usługi",
  },
  {
    link: forBuyerPagePath,
    text: "Dla kupujących",
  },
  {
    link: installmentPagePath,
    text: "Raty",
  },
  {
    link: contactPagePath,
    text: "Kontakt",
  },
];
