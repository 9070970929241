import ImageWebp from "../../layout/ImageWebp/ImageWebp";

import {
  contactImage,
  contactWebpImage,
  fbImage,
  fbWebpImage,
} from "../../../assets/images";
import {
  facebookUrl,
  mailAddress,
  phoneNumber,
} from "../../../constants/contacts";
import styles from "./ContactWrapper.module.scss";

const ContactWrapper = () => {
  return (
    <main className={`container ${styles.contactWrapper}`}>
      <div className="headerDistance"></div>
      <section className={styles.contactWrapper__main}>
        <div className={styles.contactWrapper__mainTexts}>
          <h3 className={`titleH3 ${styles.contactWrapper__mainTitle}`}>
            Skontaktuj się z nami
          </h3>
          <h4 className={styles.contactWrapper__mainSubTitle}>
            Jestesmy polskim oddzialem REALITY BULGARIA
          </h4>
          <p className={`contentTxt ${styles.contactWrapper__mainInfoTxt}`}>
            Możesz również skontaktować się z nami pod numerem telefonu podanym
            poniżej. <br /> Jeśli nie odbierzemy telefonu, jesteśmy umówieni na
            spotkanie i na pewno oddzwonimy.
          </p>
          <h4 className={styles.contactWrapper__title}>Nasze kontakty </h4>
          <p className={`contentTxt ${styles.contactWrapper__infoLink}`}>
            WhatsApp:{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://wa.me/${phoneNumber.replaceAll(" ", "")}`}
            >
              {phoneNumber}
            </a>
          </p>
          <p className={`contentTxt ${styles.contactWrapper__infoLink}`}>
            E-mail:{" "}
            <a target="_blank" rel="noreferrer" href={`mailto:${mailAddress}`}>
              {mailAddress}
            </a>
          </p>
          <a target="_blank" rel="noreferrer" href={facebookUrl}>
            <ImageWebp
              src={fbImage}
              srcSet={fbWebpImage}
              alt="facebook"
              className={styles.contactWrapper__fbImg}
            />
          </a>
        </div>
        <div className={styles.contactWrapper__mainImgWrapper}>
          <ImageWebp
            src={contactImage}
            srcSet={contactWebpImage}
            alt="contact"
            className={styles.contactWrapper__mainImg}
          />
        </div>
      </section>
      <div className={styles.contactWrapper__location}>
        <h4 className={styles.contactWrapper__title}>
          Lokalizacja oraz dane firmy:
        </h4>
        <p className={`contentTxt ${styles.contactWrapper__locatioTxt}`}>
          REALITY BULGARIA SUNNY BEACH LLC, INN: BG206851337
          <br />
          <strong>Office in Bulharsk: str. Tervel 1, Old Nessebar</strong>
        </p>
        <p className={`contentTxt ${styles.contactWrapper__locatioTxt}`}>
          REALITY BULGARIA SUNNY BEACH LLC, Czech Republic,
          <br />
          <strong>Biuro w Czechach: Kestranska 140/4, 143 00, Prague 4.</strong>
        </p>
      </div>
    </main>
  );
};

export default ContactWrapper;
