import ForBuyersHeader from "../components/ForBuyersPage/ForBuyersHeader/ForBuyersHeader";
import ForBuyersList from "../components/ForBuyersPage/ForBuyersList/ForBuyersList";
import Head from '../components/global/Head/Head'

const ForBuyerPage = () => {
  return <>
  <Head
        keyword={"umowa kupna, zawarcie umowy, pełnomocnictwo, notariusz, poświadczenie notarialne, płatność, wydatki, wydatki dodatkowe, ceny transferowe, koszty rejestracji, tłumaczenia, urzędowe, podatki, podatek od nieruchomości, finansowanie, raty, sprzedaż ratalna"}
        title={
          "Kupno nieruchomości w Bułgarii | pomoc w zakupie i finansowaniu"
        }
        ogTitle={
          "Kupno nieruchomości w Bułgarii | pomoc w zakupie i finansowaniu"
        }
        description={
          "Instrukcje krok po kroku dotyczące wyboru i zakupu nieruchomości: rejestracja, zawarcie umowy, płatność i informacja o wydatkach."
        }
        ogDescription={
          "Instrukcje krok po kroku dotyczące wyboru i zakupu nieruchomości: rejestracja, zawarcie umowy, płatność i informacja o wydatkach."
        }
      />
    <ForBuyersHeader/>
    <ForBuyersList/>
  </>;
};

export default ForBuyerPage;
