import React, {FC, memo, ReactNode} from 'react';

import styles from "./SecondayBtn.module.scss"

interface SecondayBtnProps extends React.ButtonHTMLAttributes<any> {
    className?: string,
    children: ReactNode
}

const SecondayBtn: FC<SecondayBtnProps> = memo(({className, children, ...properties}) => {
    return (
        <button
            className={`${styles['secondayBtn']} ${className ? className : ''}`}
            {...properties}
        >{children}</button>
    );
})

export default SecondayBtn;