import { useNavigate } from "react-router-dom";
import { useFilter } from "../../../hooks/useFilter";

import MainBtn from "../../layout/MainBtn/MainBtn";
import Select from "../../layout/Select/Select";
import Svg from "../../layout/Svg/Svg";

import { homeHeaderImage, homeHeaderWebpImage } from "../../../assets/images";
import { arrowLinkIcon } from "../../../assets/svg";
import { useImageLoader } from "../../../hooks/useImageLoader";
import { EPropertyTypes } from "../../../models/IProperty";
import { IFiltersQuery } from "../../../models/UI/IFilters";
import { propertiesPagePath } from "../../../router/path";
import styles from "./HomeHeader.module.scss";

const HomeHeader = () => {
  const navigate = useNavigate();
  const loading = useImageLoader(homeHeaderWebpImage, homeHeaderImage);
  const naviagateWithSearchParams = (data: IFiltersQuery) => {
    const filters = data;
    for (let k in data) {
      const key = k as keyof IFiltersQuery;
      if (!data[key]) delete filters[key];
    }
    const queryParams = new URLSearchParams(filters).toString();

    // Navigate to the new route with query parameters
    navigate(`${propertiesPagePath}?${queryParams}`);
  };

  const {
    onSubmit,
    locationSelectProps,
    minFlatSelectProps,
    maxFlatSelectProps,
    typeSelectProps,
    minPriceSelectProps,
    maxPriceSelectProps,
    floorSelectProps,
  } = useFilter(naviagateWithSearchParams);

  const navigateByTypeFilter = (type: EPropertyTypes | "") => {
    if (!type) navigate(propertiesPagePath);
    else naviagateWithSearchParams({ type });
  };

  return (
    <header
      className={`${styles.homeHeader} ${
        loading ? styles.homeHeader_load : ""
      }`}
    >
      <div className={"headerDistance"}></div>      
        <h1 className={styles.homeHeader__title}>
        Oferujemy sprzedaż nieruchomości w Bułgarii. Również sprzedaż na raty z pierwszą wpłatą w wysokości 10% ceny pozostałe 90% rozłożone na stałe raty na okres do 10 lat
        </h1>      
      <div className={styles.homeHeader__main}>
        <div className={styles.homeHeader__types}>
          <button
            onClick={() => navigateByTypeFilter(EPropertyTypes.building)}
            className={styles.homeHeader__typeLink}
          >
            <Svg
              id={arrowLinkIcon}
              className={styles.homeHeader__typeIcon}
            ></Svg>            
              <span className={styles.homeHeader__typeTxt}>Mieszkania</span>            
          </button>
          <button
            onClick={() => navigateByTypeFilter(EPropertyTypes.house)}
            className={styles.homeHeader__typeLink}
          >
            <Svg
              id={arrowLinkIcon}
              className={styles.homeHeader__typeIcon}
            ></Svg>            
              <span className={styles.homeHeader__typeTxt}>Domy</span>            
          </button>
          <button
            onClick={() => navigateByTypeFilter(EPropertyTypes.buisnessCenter)}
            className={styles.homeHeader__typeLink}
          >
            <Svg
              id={arrowLinkIcon}
              className={styles.homeHeader__typeIcon}
            ></Svg>
            <span className={styles.homeHeader__typeTxt}>
              Obiekty biznesowe
            </span>
          </button>
          <button
            onClick={() => navigateByTypeFilter("")}
            className={styles.homeHeader__typeLink}
          >
            <Svg
              id={arrowLinkIcon}
              className={styles.homeHeader__typeIcon}
            ></Svg>            
              <span className={styles.homeHeader__typeTxt}>Wszystkie</span>            
          </button>
        </div>
        <form onSubmit={onSubmit} className={styles.homeHeader__form}>
          <Select isTransparent={true} {...locationSelectProps} />
          <Select isTransparent={true} {...typeSelectProps} />
          <Select isTransparent={true} {...floorSelectProps} />
          <div className={styles.homeHeader__formWrapper}>
            <div className={styles.homeHeader__selectWrappper}>
              <Select isTransparent={true} {...minFlatSelectProps} />
            </div>
            <div className={styles.homeHeader__selectWrappper}>
              <Select isTransparent={true} {...maxFlatSelectProps} />
            </div>
            <div className={styles.homeHeader__selectWrappper}>
              <Select isTransparent={true} {...minPriceSelectProps} />
            </div>
            <div className={styles.homeHeader__selectWrappper}>
              <Select isTransparent={true} {...maxPriceSelectProps} />
            </div>
            <MainBtn className={styles.homeHeader__searchBtn}>
              Wyszukaj
            </MainBtn>
          </div>
        </form>
      </div>
    </header>
  );
};

export default HomeHeader;
