import SinglePropertyHeader from "../components/SinglePropertyPage/SinglePropertyHeader/SinglePropertyHeader";
import SinglePropertyMain from "../components/SinglePropertyPage/SinglePropertyMain/SinglePropertyMain";
import SinglePropertySlider from "../components/SinglePropertyPage/SinglePropertySlider/SinglePropertySlider";

const SinglePropertyPage = () => {
  return (
    <main className="container">
      <div className="headerDistance"></div>
      <SinglePropertyHeader />
      <SinglePropertyMain />
      <SinglePropertySlider />
    </main>
  );
};

export default SinglePropertyPage;
