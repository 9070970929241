import ImageWebp from "../../layout/ImageWebp/ImageWebp";
import List from "../../layout/List/List";

import {
  servicesCard1Image,
  servicesCard1WebpImage,
  servicesCard2Image,
  servicesCard2WebpImage,
  servicesCard3Image,
  servicesCard3WebpImage,
  servicesCard4Image,
  servicesCard4WebpImage,
} from "../../../assets/images";

import styles from "./OurServicesBolgaria.module.scss";

const cards = [
  {
    title: "Stabilność polityczna i gospodarcza",
    list: [
      "Jest członkiem UE i NATO.",
      "Stabilna polityka finansowa i najniższe zadłużenie w UE (TOP-3).",
      "Bułgaria planuje przystąpienie do strefy euro 1 stycznia 2025 r. i w związku z tym spodziewa się wzrostu cen nieruchomości.",
      "Kurs walutowy jest stabilny - od 1997 roku waluta jest powiązana z euro po stałym kursie 1 euro = 1,96 lewa.",
      "Podatek dochodowy od osób prawnych i fizycznych wynosi tu tylko 10% (najniższy w UE)!",
    ],
    img: [servicesCard1Image, servicesCard1WebpImage],
  },
  {
    title: "Nieruchomości i inwestycje",
    list: [
      "Nieruchomości nad morzem w Bułgarii są kilkukrotnie tańsze niż w innych podobnych miejscach.",
      "Bułgaria ma potencjał, aby się rozwijać i obserwuje się co roczny wzrost wartości nieruchomości zwiększać wartość Twojej inwestycji.",
      "Bułgaria jest popularnym celem podróży Polaków, Czechów i Słowaków. Bułgarzy ich lubią.",
      "Bułgaria ma najtańszą energię (woda, gaz, elektryczność) w UE.",
    ],
    img: [servicesCard2Image, servicesCard2WebpImage],
  },
  {
    title: "Życie i praca",
    list: [
      "Bułgarzy pracujący w sektorze nieruchomości są przyzwyczajeni do tego, że polscy turyści odwiedzają Morze Czarne od 60 lat.",
      "Bułgaria ma całoroczne zastosowanie w turystyce. Jest morze i góry.",
      "Kurs walutowy jest stabilny - od 1997 roku waluta jest powiązana z euro po stałym kursie 1 euro = 1,96 lewa.",
      "Bułgaria jest tania. Możesz beztrosko mieszkać i pracować nad brzegiem morza przez cały rok (bardzo szybki internet) lub spędzić tu przyjemne wakacje na emeryturze.",
    ],
    img: [servicesCard3Image, servicesCard3WebpImage],
  },
  {
    title: "Turystyka i przyroda",
    list: [
      "Bułgaria ma piękne plaże, dobre jedzenie i wysokiej jakości obsługę.",
      "Bułgaria ma piękną przyrodę.",
      "Latem Riwiera Bułgarska, a zimą znany na całym świecie ośrodek narciarski Bansko oraz kurorty Pamporovo, Pirin i Borovets.",
      "Można do niej dojechać samochodem (drogą lądową) i samolotem.",
      "Bułgaria ma bogatą historię.",
    ],
    img: [servicesCard4Image, servicesCard4WebpImage],
  },
];

const OurServicesBolgaria = () => {
  return (
    <section className={styles.ourServicesBolgaria}>      
        <h2 className={`${styles.ourServicesBolgaria__title} titleH2`}>
          Dlaczego Bułgaria?
        </h2>
        <div className={styles.ourServicesBolgaria__singleTexts}>
          <p className={styles.ourServicesBolgaria__singleTxtItem}>
            <strong>Bułgaria</strong> (bułg. България, Bălgarija), której pełna
            nazwa brzmi Republika Bułgarii, to państwo w południowo-wschodniej
            Europie, na Półwyspie Bałkańskim. Od północy graniczy z Rumunią
            (granica przebiega głównie wzdłuż Dunaju), od zachodu z Serbią i
            Macedonią Północną, od południa z Grecją, a od południowego wschodu
            z Turcją. Wschodnią granicę stanowi Morze Czarne.
          </p>
          <p className={styles.ourServicesBolgaria__singleTxtItem}>
            Od 1946 roku Bułgaria jest republiką i częścią bloku wschodniego.
            Reżim komunistyczny został obalony w 1989 roku i od tego czasu
            Bułgarzy rozwijają demokrację i wolny rynek. W 2004 r. kraj ten
            przystąpił do NATO, a 1 stycznia 2007 r. do UE.
          </p>
          <p className={styles.ourServicesBolgaria__singleTxtItem}>
            <strong>Stolica i największe miasto</strong> — Sofia liczy 1,27
            miliona mieszkańców, co stanowi 17,5 procent całej populacji
            Bułgarii. Inne ważne miasta to Płowdiw, Warna i Burgas. Z
            powierzchnią 110 994 kilometrów kwadratowych Bułgaria jest 16. co do
            wielkości krajem w Europie i 11. co do wielkości w Unii
            Europejskiej.
          </p>
          <p className={styles.ourServicesBolgaria__singleTxtItem}>
            <strong>Bułgaria</strong> – państwo unitarne i republika
            parlamentarna. W ostatnich latach kraj ten odnotował spadek liczby
            ludności, co jest poważnym problemem politycznym. W styczniu 2021 r.
            liczba ludności wynosiła 6 916 548, choć pod koniec lat 80. wciąż
            zbliżała się do granicy dziewięciu milionów. W kraju mieszka
            znacząca mniejszość turecka i romska.
          </p>
          <p className={styles.ourServicesBolgaria__singleTxtItem}>
          <strong>Eksperci szacują,</strong> że Bułgaria, to aktualnie najlepsze miejsce do inwestowania na rynku nieruchomości w Europie. Powodów jest kilka, ale najważniejszym jest to, że nieruchomości w Bułgarii charakteryzują się najwyższym potencjałem wzrostu cen w średnim i długim terminie. Inwestorzy z całego świata polecają Bułgarię, jako jedne z najlepszych
          miejsc na pierwsze inwestycje. Jeśli więc ktoś planuje zakup z myślą o wynajmie, szanse na atrakcyjny, dodatkowy dochód są
          tutaj spore.
          </p>
          <p className={styles.ourServicesBolgaria__singleTxtItem}>
            <strong>Porównanie cen nieruchomości w Bułgarii z innymi krajami Europy </strong>Podczas gdy we Włoszech lub Hiszpanii trudno znaleźć obiekty w cenie
poniżej 1000 euro za metr kwadratowy, w Bułgarii wartość metra kwadratowego szacuje się od 700 euro.
Dla przykładu ceny domów w Bułgarii w naszym katalogu zaczynają się już od 100 tys. euro za ok. 130
metrów kwadratowych, a najtańsze nieruchomości w Bułgarii nad morzem na sprzedaż dostępne są w
cenach poniżej 20 tysięcy euro.
Oprócz niskich cen zakupu samych mieszkań, w Bułgarii można liczyć również na niższy podatek od
wynajmu nieruchomości, który wynosi 10%. Dodatkowym plusem jest koszt
utrzymania nieruchomości w Bułgarii – można tu liczyć na opłaty za media nawet 70% tańsze niż w
Polsce.
          </p>
        </div>      
      <div className={styles.ourServicesBolgaria__cards}>
        {cards.map((item, index) => (
          <div
            className={`${styles.ourServicesBolgaria__cardItem} ${
              index % 2 ? styles.ourServicesBolgaria__cardItem_wraped : ""
            }`}
            key={index}
          >
            <div className={styles.ourServicesBolgaria__cardListWrapper}>
              <List
                title={item.title}
                list={item.list}
                isTitleBlack={true}
                ItemclassName={styles.ourServicesBolgaria__listItem}
              />
            </div>
            <div className={styles.ourServicesBolgaria__cardImgWrapper}>
              <ImageWebp
                src={item.img[0]}
                srcSet={item.img[1]}
                alt="card"
                useLoader
                className={styles.ourServicesBolgaria__cardImg}
              />
            </div>
          </div>
        ))}
      </div>      
        <h3 className={styles.ourServicesBolgaria__subtitle}>
          Przyjedź do Bułgarii!
        </h3>      
    </section>
  );
};

export default OurServicesBolgaria;
