import { MouseEventHandler, useState } from "react";
import NewPortalProvider from "../../../providers/NewPortalProvider";
import TransitionProvider, {
  TransitionStyleTypes,
} from "../../../providers/TransitionProvider";
import Backdrop from "../Backdrop/Backdrop";
import MainBtn from "../MainBtn/MainBtn";
import SecondayBtn from "../SecondayBtn/SecondayBtn";

import { useAppDispatch } from "../../../hooks/redux";
import { openTooltip } from "../../../store/slices/UISlice";
import styles from "./DeleteModal.module.scss";

type Props = {
  show: boolean;
  onClose: () => void;
  title: string;
  btnTxt?: string;
  onConfirm: () => Promise<void> | undefined;
};

const DeleteModal = ({ show, onClose, title, onConfirm, btnTxt }: Props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const onClickConfirm: MouseEventHandler<any> = (e) => {
    e.stopPropagation();
    (async () => {
      try {
        setLoading(true);
        await onConfirm();
        onClose();
      } catch (error) {
        dispatch(openTooltip("Deletion error"));
      } finally {
        setLoading(false);
      }
    })();
  };
  return (
    <>
      <Backdrop onClose={onClose} inProp={show} />
      <NewPortalProvider>
        <TransitionProvider
          inProp={show}
          style={TransitionStyleTypes.opacity}
          className={styles.deleteModal}
        >
          <h4 className={styles.deleteModal__title}>{title}</h4>
          <div className={styles.deleteModal__buttons}>
            <MainBtn isNegative disabled={loading} onClick={onClickConfirm}>
              {btnTxt || "Delete"}
            </MainBtn>
            <SecondayBtn
              disabled={loading}
              onClick={(e) => {
                e.stopPropagation();
                onClose();
              }}
            >
              Cancel
            </SecondayBtn>
          </div>
        </TransitionProvider>
      </NewPortalProvider>
    </>
  );
};

export default DeleteModal;
