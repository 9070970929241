import ImageWebp from "../../layout/ImageWebp/ImageWebp";
import List from "../../layout/List/List";

import {
  installmentImage,
  installmentWebpImage,
} from "../../../assets/images";

import styles from "./InstallmentPlanConditions.module.scss";

const lists = [
  {
    title: "Warunki ratalne",
    list: [
      "Nie interesują nas Twoje dochody i wydatki",
      "Nie potrzebujemy zeznań podatkowych",
      "Nie potrzebujemy wyciągów bankowych",
      "Wystarczy jeden dokument tożsamości",
      "Rozłożenie płatności na raty jest finansowane przez bezpośredniego właściciela - inwestora lub dewelopera, naszego partnera, a nie przez bank.",
    ],
  },
  {
    title: "Informacje prawne i gwarancje",
    list: [
      "Nieruchomość jest do Twojej dyspozycji od razu po podpisaniu umowy , możesz z niej korzystać, możesz ją wynająć.",
      "Nieruchomość jest własnością sprzedającego do momentu zapłaty ostatniej raty.",
      "Oprocentowanie pozostającej do spłacenia należności dla dewelopera wynosi od 5,2% do 9,7% w skali roku to niższe odsetki niż kredyt bankowy",
    ],
  },
  {
    title: "Przykład planu ratalnego",
    list: [
      "Opłata wstępna 10%, reszta w ciągu 1-7 lat, dla droższych nieruchomości do 10 lat.",
      "Lub 10% opłaty wstępnej przy podpisaniu, następnie 3 x 10% kwartalnie, pozostałe 60% można podzielić na płatności miesięczne lub kwartalne w okresie od jednego do siedmiu lat.",
      "Nieruchomość możesz spłacić wcześniej w dowolnym momencie , a zostaną Ci umorzone odsetki za kolejne okresy.",
      "Lub 30% opłaty wstępnej , a pozostałe 70% przez okres od 1 do 7 lat, w wyjątkowych przypadkach przez 10 lat.",
      "Nawet jeśli posiadasz nieruchomość na raty, możesz ją sprzedać za naszym pośrednictwem w dowolnym momencie z zyskiem po wartości rynkowej lub odsprzedać nieruchomość z pozostającymi ratami do spłacenia",
    ],
  },
];

const InstallmentPlanConditions = () => {
  return (
    <section className={`container ${styles.installmentPlanConditions}`}>      
        <h2 className={`titleH2 ${styles.installmentPlanConditions__title}`}>
        Warunki uzyskania mieszkania na raty
        </h2>
        <h4 className={styles.installmentPlanConditions__subtitle}>
          To bardzo proste
        </h4>      
      <div className={styles.installmentPlanConditions__lists}>
        {lists.map((item, index) => (
          <List title={item.title} list={item.list} key={index} />
        ))}
      <div className={styles.installmentPlanConditions__installmentImgWrapper}> 
        <ImageWebp
          src={installmentImage}
          srcSet={installmentWebpImage}
          alt="Installment"
          className={styles.installmentPlanConditions__installmentImg}
        />
        </div>
      </div>
    </section>
  );
};

export default InstallmentPlanConditions;
