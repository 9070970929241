import React, { FC } from "react";

import { useImageLoader } from "../../../hooks/useImageLoader";
import styles from "./ImageWebp.module.scss";

interface ImageWebpProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  srcSet: string;
  src: string;
  pictureClass?: string;
  forwardedRef?: React.Ref<HTMLImageElement>;
  alt: string;
  useLoader?: boolean;
}

const ImageWebp: FC<ImageWebpProps> = ({
  srcSet,
  pictureClass,
  forwardedRef,
  alt,
  src,
  useLoader,
  ...properties
}) => {
  const loading = useImageLoader(srcSet, src, !!useLoader);

  return (
    <picture
      className={`${styles.imageWebp} ${
        useLoader ? styles.imageWebp_loader : ""
      } ${pictureClass ? pictureClass : ""}`}
    >
      <source srcSet={srcSet} type="image/webp" />
      <img
        style={{ opacity: loading ? 0 : 1 }}
        alt={alt}
        src={src}
        ref={forwardedRef}
        {...properties}
      />
    </picture>
  );
};

export default ImageWebp;
