export enum EPropertyTypes {
  house = "house",
  building = "building",
  buisnessCenter = "buisnessCenter",
}

export enum EObjectStatusTypes {
  wooden = "wooden",
  likeNew = "likeNew",
  newConstruction = "newConstruction",
  repairsRequired = "repairsRequired",
  afterReconstruction = "afterReconstruction",
  satisfactorily = "satisfactorily",
  new = "new",
  veryGood = "veryGood",
  great = "great",
  secondaryMarket = "secondaryMarket",
}

export interface IProopertyLocation {
  id: number;
  name: string;
}
export interface IProopertyEquipment {
  id: number;
  name: string;
}

export interface IPropertyImage {
  id: number;
  path: string;
  isMainImage?: boolean;
}

export interface IPropertyVideo {
  id: number;
  path: string;
}

export interface IProperty {
  id: number;
  title: string;
  price: number;
  flat: number;
  floor: number;
  location: IProopertyLocation;
  images: IPropertyImage[];
  //   only in single ^
  plotSize: number;
  rooms?: number;
  locationId: number;
  distanceFromTheBeach: number;
  type: EPropertyTypes;
  objectStatus: EObjectStatusTypes;
  description: string;
  locationOnMap: string;
  createdAt: Date;
  updatedAt: Date;
  equipments: IProopertyEquipment[];
}
