import AboutCounters from "../AboutCounters/AboutCounters";
import AboutMain from "../AboutMain/AboutMain";
import AboutText from "../AboutText/AboutText";

import styles from "./AboutWrapper.module.scss"

const AboutWrapper = () => {
  return (
    <main className={styles.aboutWrapper}>
      <AboutMain />
      <AboutCounters />
      <AboutText />
    </main>
  );
};

export default AboutWrapper;
