import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { getPropertyList } from "../../../store/slices/propertiesSlice";

import TransitionProvider, {
  TransitionStyleTypes,
} from "../../../providers/TransitionProvider";
import PropertyCard from "../../global/PropertyCard/PropertyCard";
import DataLoader from "../../layout/DataLoader/DataLoader";

import { IFilters, IFiltersQuery } from "../../../models/UI/IFilters";
import { scrollTop } from "../../../utils/functions/scrollTop";
import PaginationStyled from "../../layout/PaginationStyled/PaginationStyled";
import styles from "./PropertiesList.module.scss";

const PropertiesList = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const properties = useAppSelector((state) => state.properties.data);
  const getLoading = useAppSelector((state) => state.properties.getLoading);
  const resultCount = useAppSelector((state) => state.properties.resultCount);

  const [curPage, setCurPage] = useState(1);

  useEffect(() => {
    const filters: IFiltersQuery = {
      minPrice: undefined,
      maxPrice: undefined,
      maxFlat: undefined,
      minFlat: undefined,
      type: undefined,
      page: undefined,
      sort: undefined,
      pageSize: undefined,
      location: undefined,
      rooms: undefined,
    };

    const queryParams = new URLSearchParams(location.search);

    if (queryParams.get("page"))
      setCurPage(+(queryParams.get("page") as string));

    for (let k in filters) {
      const key = k as keyof IFilters;
      if (queryParams.get(key)) {
        filters[key] = queryParams.get(key) as string;
      }
    }

    dispatch(getPropertyList(filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const onPageChange = (page: number) => {
    scrollTop();
    searchParams.set("page", page.toString());

    setSearchParams(searchParams);
  };

  return (
    <section className={styles.propertiesList}>
      <DataLoader isLoaded={false} show={getLoading} />
      <TransitionProvider
        inProp={!getLoading}
        style={TransitionStyleTypes.opacity}
      >
        <div className={styles.propertiesList__main}>
          {properties
            ? properties.map((item) => (
                <PropertyCard item={item} key={item.id} />
              ))
            : ""}
        </div>
      </TransitionProvider>
      <PaginationStyled
        onPageChange={onPageChange}
        totalCount={resultCount}
        curPage={curPage}
      />
    </section>
  );
};

export default PropertiesList;
