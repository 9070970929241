import React, { useEffect, useRef, useState } from "react";
import { ISelectValue } from "../models/UI/ISelectValue";

export interface UseDropdownSelectProps {
  onChange: (val: string | string[]) => void;
  valuesArr: ISelectValue[];
  name: string;
  selectedChild?: string;
  disableState?: boolean;
  openState?: boolean;
  isWithInput?: boolean;
  isMultiSelect?: true | false;
  selectedValueProp?: string | string[];
}

export function useDropdownSelect(props: UseDropdownSelectProps) {
  const {
    onChange,
    selectedValueProp,
    valuesArr,
    selectedChild,
    disableState,
    openState,
    name,
    isMultiSelect,
    isWithInput,
  } = props;

  const selectedRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const [selectedValue, setSelectedValue] = useState<string | string[]>(
    selectedValueProp ? selectedValueProp : isMultiSelect ? [] : ""
  );
  const [dropDownOpened, setDropDownOpened] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>(name || "");
  const openStateRef = useRef(openState);
  const dropDownOpenedRef = useRef(dropDownOpened);

  useEffect(() => {
    openStateRef.current = openState;
  }, [openState]);

  useEffect(() => {
    dropDownOpenedRef.current = dropDownOpened;
  }, [dropDownOpened]);

  useEffect(() => {
    const ref = [selectedRef];
    const checkIfClickedOutside = (e: MouseEvent) => {
      const el = e.target as HTMLElement;
      const isRef = ref.every(
        (value) => value.current && !value.current.contains(el)
      );

      if (dropDownOpenedRef.current && !openStateRef.current && isRef) {
        oncloseDropdowns();
      }
    };
    document.addEventListener("click", checkIfClickedOutside);

    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, []);

  const updateSearchValue = (
    valuesArr: ISelectValue[],
    selectedValue: string | string[]
  ) => {
    const selectedName = isMultiSelect
      ? valuesArr
          .filter((item) => selectedValue.includes(item.value))
          .map((item) => item.name)
          .join()
      : valuesArr.find((item) => item.value === (selectedValue as string))
          ?.name || "";

    const newValue = selectedValue && selectedName ? selectedName : name;

    if (newValue !== searchValue) setSearchValue(newValue);
  };

  useEffect(() => {
    if (!dropDownOpened) {
      updateSearchValue(valuesArr, selectedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropDownOpened]);

  useEffect(() => {
    if (
      selectedValueProp !== undefined &&
      selectedValueProp.toString() !== selectedValue.toString()
    ) {
      updateSearchValue(valuesArr, selectedValueProp);
      setSelectedValue(selectedValueProp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValueProp]);

  useEffect(() => {
    if (!valuesArr.length && dropDownOpened) setDropDownOpened(false);
    if (isWithInput && dropDownOpened) return;

    updateSearchValue(valuesArr, selectedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuesArr]);

  useEffect(() => {
    setDisabled(!!disableState);
    if (disableState) setSelectedValue(isMultiSelect ? [] : "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableState]);

  const onToggleDropdowns = () => {
    if (!disabled) {
      setDropDownOpened((prevState) => !prevState);
      if (!dropDownOpened && searchInputRef?.current) {
        setSearchValue("");
        searchInputRef.current.focus();
      }
      if (dropDownOpened && searchInputRef?.current) {
        setSearchValue("");
        searchInputRef.current.blur();
      }
    }
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const oncloseDropdowns = () => {
    setDropDownOpened(false);
  };

  const onChangeSelectValues = (value: string) => {
    if (isMultiSelect) {
      let valueArr = selectedValue;
      if (Array.isArray(selectedValue)) {
        valueArr = selectedValue.includes(value)
          ? selectedValue.filter((item) => item !== value)
          : [...selectedValue, value];
      } else {
        valueArr = [value];
      }
      setSelectedValue((prevState) => {
        if (Array.isArray(prevState)) {
          return prevState.includes(value)
            ? prevState.filter((item) => item !== value)
            : [...prevState, value];
        } else {
          return [value];
        }
      });
      onChange(valueArr);
    } else {
      setSelectedValue(value);
      oncloseDropdowns();
      onChange(value);
    }
  };

  const valuesFiltered = selectedValueProp
    ? valuesArr.filter((item) => item.value !== selectedValue)
    : valuesArr;

  const dropdownContentArr = valuesFiltered;

  const sortedArr = !isMultiSelect
    ? [
        ...dropdownContentArr.filter((item) =>
          selectedValue.includes(item.value)
        ),
        ...dropdownContentArr.filter(
          (item) => !selectedValue.includes(item.value)
        ),
      ]
    : dropdownContentArr;
  let sortedContentArr = isMultiSelect ? sortedArr : dropdownContentArr;

  let btnText;
  if (!Array.isArray(selectedValue)) {
    if (selectedChild) {
      btnText = selectedChild;
    } else if (!selectedChild && selectedValue) {
      btnText =
        valuesArr.find((item) => item.value === selectedValue)?.name || name;
    } else {
      btnText = name;
    }
  } else {
    if (selectedValue?.length) {
      btnText = valuesArr
        .filter((item) => selectedValue.includes(item.value))
        .map((item) => item.name)
        .join();
    } else {
      btnText = name;
    }
  }

  const finalContent = isWithInput
    ? sortedContentArr.filter((item) =>
        item.name.toLowerCase().includes(searchValue.toLowerCase())
      )
    : sortedContentArr;

  const clearValue = () => {
    setSelectedValue("");
    onChange("");
  };

  return {
    selectedRef,
    dropDownOpened,
    disabled,
    onToggleDropdowns,
    oncloseDropdowns,
    clearValue,
    onChangeSelectValues,
    btnText,
    selectedValue,
    sortedContentArr: finalContent,
    searchInputRef,
    onSearch,
    searchValue,
  };
}
