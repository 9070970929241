import {
  ourServicesHeaderImage,
  ourServicesHeaderWebpImage,
} from "../../../assets/images";
import Header from "../../global/Header/Header";

import styles from "./OurServicesHeader.module.scss";

const OurServicesHeader = () => {
  return (
    <Header
      webpImage={ourServicesHeaderWebpImage}
      mainImage={ourServicesHeaderImage}
      backgroundClass={styles.aboutHeader}
      title="Co oferujemy?"
      subtitle=""
    />
  );
};

export default OurServicesHeader;
