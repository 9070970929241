import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import FilterSelectOption from "../components/layout/FilterSelectOption/FilterSelectOption";
import { SelectProps } from "../components/layout/Select/Select";
import { typeOptionNames } from "../constants/propertyTexts";
import { ILocationFilter } from "../models/ILocationFilter";
import { EPropertyTypes } from "../models/IProperty";
import { IFiltersQuery } from "../models/UI/IFilters";
import { ISelectValue } from "../models/UI/ISelectValue";
import { getPropertyFilters } from "../store/slices/propertiesSlice";
import { formatNumber } from "../utils/functions/formatNumber";
import { useAppDispatch, useAppSelector } from "./redux";
import { useFormValue } from "./useFormValue";

const initialState: IFiltersQuery = {
  minPrice: "",
  maxPrice: "",
  maxFlat: "",
  rooms: "",
  minFlat: "",
  type: "",
  location: "",
  page: "",
  pageSize: "",
};

const flatOptions = [
  20, 50, 100, 200, 500, 1000, 1500, 2000, 2500, 5000, 10000,
];

const floorOptions: ISelectValue[] = [1, 2, 3, 4, 5, 6, 7, 8].map((item) => ({
  value: item.toString(),
  name: item.toString(),
}));

const priceOptions = [
  10000, 20000, 30000, 40000, 50000, 60000, 70000, 80000, 90000, 100000, 120000,
  150000, 200000, 250000,
];

type UseFilterType = (
  onUpdate: (data: IFiltersQuery) => void,
  disableTranslate?: boolean,
  isArchive?: boolean
) => {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  onClearFilters: () => void;
  onTabChange: (val: EPropertyTypes | "") => void;
  setFormData: React.Dispatch<React.SetStateAction<IFiltersQuery>>;
  locationSelectProps: SelectProps;
  minFlatSelectProps: SelectProps;
  maxFlatSelectProps: SelectProps;
  typeSelectProps: SelectProps;
  minPriceSelectProps: SelectProps;
  maxPriceSelectProps: SelectProps;
  floorSelectProps: SelectProps;
  initialState: IFiltersQuery;
  locations: ILocationFilter[] | null;
};

export const useFilter: UseFilterType = (
  onUpdate,
  disableTranslate,
  isArchive
) => {
  const filterCountsKey = isArchive ? "archiveFilterCounts" : "filterCounts";
  const optionCountKey = isArchive ? "archiveCount" : "count";
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const locations = useAppSelector(
    (state) => state.properties[filterCountsKey].locations
  );
  const types = useAppSelector(
    (state) => state.properties[filterCountsKey].types
  );
  const { formData, onChangeSelect, onResetForm, setFormData } =
    useFormValue(initialState);

  // select options
  const locationOptions: ISelectValue[] = locations
    ? locations
        .filter((item) => item.count)
        .map((item) => ({
          item: (
            <FilterSelectOption name={item.name} count={item[optionCountKey]} />
          ),
          value: item.id.toString(),
          name: item.name,
          disabled: !item[optionCountKey],
        }))
    : [];

  const typesOptions = Object.keys(types).map((item) => ({
    value: item,
    name: typeOptionNames[item as EPropertyTypes],
    item: (
      <FilterSelectOption
        name={typeOptionNames[item as EPropertyTypes]}
        count={types[item as EPropertyTypes]}
      />
    ),
    disabled: !types[item as EPropertyTypes],
  }));
  const minFlatOptions: ISelectValue[] = (
    formData.maxFlat
      ? flatOptions.filter((item) => item < +(formData.maxFlat as string))
      : flatOptions
  ).map((item) => ({
    value: item.toString(),
    name: `${formatNumber(item)} m²`,
  }));
  const maxFlatOptions: ISelectValue[] = (
    formData.minFlat
      ? flatOptions.filter((item) => item > +(formData.minFlat as string))
      : flatOptions
  ).map((item) => ({
    value: item.toString(),
    name: `${formatNumber(item)} m²`,
  }));

  const minPriceOptions: ISelectValue[] = (
    formData.maxPrice
      ? priceOptions.filter((item) => item < +(formData.maxPrice as string))
      : priceOptions
  ).map((item) => ({
    value: item.toString(),
    name: `€ ${formatNumber(item)}`,
  }));
  const maxPriceOptions: ISelectValue[] = (
    formData.minPrice
      ? priceOptions.filter((item) => item > +(formData.minPrice as string))
      : priceOptions
  ).map((item) => ({
    value: item.toString(),
    name: `€ ${formatNumber(item)}`,
  }));

  useEffect(() => {
    dispatch(getPropertyFilters(isArchive));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onUpdate(formData);
  };

  const onClearFilters = () => {
    onResetForm();
    setSearchParams({});
  };

  const onTabChange = (val: EPropertyTypes | "") => {
    onChangeSelect("type", val as string);
    onChangeSelect("page", "1" as string);

    onUpdate({
      ...formData,
      type: val,
      page: "1",
    });
  };

  const locationSelectProps: SelectProps = {
    selectedValueProp: formData.location,
    useClearButton: true,
    isWithInput: true,
    disableState: !locations?.length,
    onChange: (val) => onChangeSelect("location", val as string),
    valuesArr: locationOptions,
    name: disableTranslate ? "Location" : "Lokalizacja",
    disableTranslate,
  };

  const minFlatSelectProps: SelectProps = {
    selectedValueProp: formData.minFlat,
    useClearButton: true,
    onChange: (val) => onChangeSelect("minFlat", val as string),
    valuesArr: minFlatOptions,
    name: disableTranslate ? "Area from" : "Powierzchnia od",
    disableTranslate,
  };

  const maxFlatSelectProps: SelectProps = {
    selectedValueProp: formData.maxFlat,
    useClearButton: true,
    onChange: (val) => onChangeSelect("maxFlat", val as string),
    valuesArr: maxFlatOptions,
    name: disableTranslate ? "Area to" : "Powierzchnia do",
    disableTranslate,
  };
  const typeSelectProps: SelectProps = {
    selectedValueProp: formData.type,
    useClearButton: true,
    onChange: (val) => {
      onChangeSelect("type", val as string);
      if (formData.rooms) onChangeSelect("rooms", "" as string);
    },
    valuesArr: typesOptions,
    name: disableTranslate ? "Property type" : "Typ nieruchomości",
    disableTranslate,
  };
  const minPriceSelectProps: SelectProps = {
    selectedValueProp: formData.minPrice,
    useClearButton: true,
    onChange: (val) => onChangeSelect("minPrice", val as string),
    valuesArr: minPriceOptions,
    name: disableTranslate ? "Price from" : "Cena od",
    disableTranslate,
  };
  const maxPriceSelectProps: SelectProps = {
    selectedValueProp: formData.maxPrice,
    useClearButton: true,
    onChange: (val) => onChangeSelect("maxPrice", val as string),
    valuesArr: maxPriceOptions,
    name: disableTranslate ? "Price up to" : "Cena do",
    disableTranslate,
  };
  const floorSelectProps: SelectProps = {
    selectedValueProp: formData.rooms,
    useClearButton: true,
    onChange: (val) => onChangeSelect("rooms", val as string),
    valuesArr: floorOptions,
    disableState: formData.type === EPropertyTypes.buisnessCenter,
    name: disableTranslate ? "Rooms" : "Pokoje",
    disableTranslate,
  };

  return {
    onSubmit,
    onClearFilters,
    onTabChange,
    setFormData,
    locationSelectProps,
    minFlatSelectProps,
    maxFlatSelectProps,
    typeSelectProps,
    minPriceSelectProps,
    maxPriceSelectProps,
    floorSelectProps,
    initialState,
    locations,
  };
};
