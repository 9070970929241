import L from "leaflet";
import { useEffect } from "react";
import {
  MapContainer,
  Marker,
  TileLayer,
  useMap,
  useMapEvents,
} from "react-leaflet";
import { mapMarkerImage } from "../../../assets/images";

type LocationType = [number, number];

type Props = {
  initialLocation: LocationType;
  values?: LocationType;
  onChange?: (val: LocationType) => void;
};

const customIcon = new L.Icon({
  iconUrl: mapMarkerImage,
  iconSize: [25, 35],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const MapCenter = ({ values }: { values?: LocationType }) => {
  const map = useMap();

  useEffect(() => {
    if (values) map.setView(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return null;
};

const Map = ({ initialLocation, onChange, values }: Props) => {
  interface AddMarkerProps {
    setMarker: typeof onChange;
  }

  const AddMarker: React.FC<AddMarkerProps> = ({ setMarker }) => {
    useMapEvents({
      click(e) {
        if (onChange) onChange([e.latlng.lat, e.latlng.lng]);
      },
    });
    return null;
  };

  return (
    <MapContainer
      center={initialLocation}
      zoom={13}
      style={{
        height: "300px",
        width: "100%",
        borderRadius: "10px",
        zIndex: "0",
      }}
    >
      <TileLayer
        url="https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=CiqYEAO6E5y2wYqipCBi"
        maxNativeZoom={19}
        maxZoom={21}
        // minZoom={0}
      />
      <MapCenter values={values} />
      <Marker position={values || initialLocation} icon={customIcon}></Marker>
      {onChange && <AddMarker setMarker={onChange} />}
    </MapContainer>
  );
};

export default Map;
