import OurServicesBolgaria from "../components/OurServicesPage/OurServicesBolgaria/OurServicesBolgaria";
import OurServicesHeader from "../components/OurServicesPage/OurServicesHeader/OurServicesHeader";
import OurServicesMain from "../components/OurServicesPage/OurServicesMain/OurServicesMain";
import Head from '../components/global/Head/Head'

const OurServicesPage = () => {
  return (
    <>
    <Head
        keyword={"usługi prawne, usługi notarialne i prawnicze, usługi bezpłatne, ubezpieczenia majątku, transport i zakwaterowanie, transfery, prace budowlano-remontowe, prace remontowe"}
        title={
          "Nieruchomości | Usługi prawne i budowlane"
        }
        ogTitle={
          "Nieruchomości | Usługi prawne i budowlane"
        }
        description={
          "Usługi sprzedaży nieruchomości: ubezpieczenia majątku, usługi notarialne i prawne, a także prace remontowe, transfery."
        }
        ogDescription={
          "Usługi sprzedaży nieruchomości: ubezpieczenia majątku, usługi notarialne i prawne, a także prace remontowe, transfery."
        }
      />
      <OurServicesHeader />
      <main className="container">
        <OurServicesMain />
        <OurServicesBolgaria />
      </main>
    </>
  );
};

export default OurServicesPage;
