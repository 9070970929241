import { FormError } from "../../models/Errors/FormError";
import { IFetchError } from "../../models/Errors/IFetchError";
import { getLSItem } from "../../utils/functions/localStorage";
import { lsProps } from "../../utils/lsProps";

type FetchMethods = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

export const isProduction = process.env.NODE_ENV === "production";
export const baseUrl = "/api/v1";
export const proxy = isProduction
  ? "https://realitybulgaria.pl"
  : "http://localhost:5000";

export const authConfig = (isFormData?: boolean) => {
  const token = getLSItem<string>(lsProps.token, true);

  const headers: HeadersInit = {
    Authorization: token ? `Bearer ${token}` : "",
  };

  if (!isFormData) {
    headers["Content-Type"] = "application/json";
  }
  return { headers };
};

// properties
export const getPropertiesUrl = "/property";
export const createPropertyUrl = "/property/create";
export const getPropertiesFiltersUrl = "/property/filters";
export const archivePropertyUrl = "/property/archive";

// locations
export const createLocationUrl = "/location/create";
export const getLocationUrl = "/location/";

// equipments
export const createEquipmentUrl = "/equipment/create";
export const getEquipmentUrl = "/equipment/";

// user
export const loginUrl = "/users/login";
export const changePasswordUrl = "/users/changePassword";
export const forgotPasswordUrl = "/users/forgotPassword";
export const resetPasswordUrl = "/users/resetPassword";

export const fetchRequest = async <Res, Body extends object = {}>(
  fetchUrl: string,
  method: FetchMethods = "GET",
  body: Body | null = null,
  config = authConfig()
) => {
  const filteredBody: Partial<Body> = {};

  const isFormData = body instanceof FormData;
  if (body && !isFormData) {
    for (let key in body) {
      if (body[key]) {
        filteredBody[key] = body[key];
      }
    }
  }

  const response = await fetch(`${proxy}${baseUrl}${fetchUrl}`, {
    method: method,
    body: !body || isFormData ? body : JSON.stringify(filteredBody),
    ...config,
  });

  const resData: Res = await response.json();

  if (!response.ok) {
    // eslint-disable-next-line no-throw-literal
    throw { message: resData, status: response.status };
  }
  return resData;
};

export const createFormData = <T extends object>(data: T) => {
  const formData = new FormData();

  for (let key in data) {
    const item = data[key as keyof T];
    if (!item && item !== 0) continue;
    if (Array.isArray(item)) {
      for (let i = 0; i < item.length; i++) {
        formData.append(`${key}[]`, item[i]);
      }
    } else {
      formData.append(key, item as string);
    }
  }

  return formData;
};

export const setFormError = <T extends object>(error: IFetchError<T>) => {
  let payload: FormError<T> = "Error";
  if (error?.message?.error?.errors) {
    payload = error?.message?.error?.errors;
  }

  return payload;
};
