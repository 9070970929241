import { FormEventHandler, ReactNode } from "react";
import { logoImage, logoWebpImage } from "../../../assets/images";
import { ErrorTypes } from "../../../constants/errors";
import { SetErrorType, useFormValue } from "../../../hooks/useFormValue";
import { FormError } from "../../../models/Errors/FormError";
import ImageWebp from "../../layout/ImageWebp/ImageWebp";
import LoaderPopup from "../../layout/LoaderPopup/LoaderPopup";
import MainBtn from "../../layout/MainBtn/MainBtn";
import styles from "./AdminAuthWrapper.module.scss";

interface IField {
  key: string;
  name: string;
  type?: string;
  defaultValue?: string | boolean;
}

type IErrorTexts<T extends { [key in string]: string | boolean }> = {
  [key in keyof T]: {
    [ErrorTypes.emptyField]: string;
    [ErrorTypes.invalidvalue]: string;
  };
};

type Props<T extends { [key in string]: string | boolean }> = {
  fields: IField[];
  error: FormError<T> | null;
  loading: boolean;
  setError: SetErrorType<T>;
  submitAction: (data: T) => void;
  title: string;
  btnTxt: string;
  errorTexts: IErrorTexts<T>;
  renderAdditionalField?: (
    formData: T,
    onFieldChange: <Value>(key: keyof T, value: Value) => void
  ) => ReactNode;
  additionalContent?: ReactNode;
};

const AdminAuthWrapper = <T extends { [key in string]: string | boolean }>({
  error,
  loading,
  setError,
  fields,
  submitAction,
  title,
  btnTxt,
  errorTexts,
  renderAdditionalField,
  additionalContent,
}: Props<T>) => {
  const initialState = fields.reduce((acc, cur) => {
    acc[cur.key as keyof T] =
      (cur.defaultValue as T[keyof T]) || ("" as T[keyof T]);
    return acc;
  }, {} as T);

  const { formData, onChange, onChangeSelect, getError } = useFormValue(
    initialState,
    setError,
    error
  );

  const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    submitAction(formData);
  };

  return (
    <section className={styles.authWrapper}>
      <ImageWebp
        src={logoImage}
        srcSet={logoWebpImage}
        alt="logo"
        className={styles.authWrapper__logoImg}
      />
      <h3 className={styles.authWrapper__title}>{title}</h3>
      <form onSubmit={onSubmit} className={styles.authWrapper__form}>
        {fields
          .filter(
            (item) =>
              typeof item.defaultValue === "string" ||
              item.defaultValue === undefined
          )
          .map((item) => {
            const errorkey = item.key as keyof typeof errorTexts;
            const curError = getError(item.key) as
              | ErrorTypes.emptyField
              | ErrorTypes.invalidvalue;
            return (
              <div className={styles.authWrapper__inputWrapper}>
                <input
                  value={formData[item.key] as string}
                  onChange={onChange}
                  name={item.key}
                  type={item.type || "text"}
                  className={styles.authWrapper__input}
                  placeholder={item.name}
                />
                <p
                  className={`${styles.authWrapper__errorTxt} ${
                    curError ? styles.authWrapper__errorTxt_active : ""
                  }`}
                >
                  {(error &&
                    typeof error === "object" &&
                    curError &&
                    errorkey in errorTexts &&
                    curError in errorTexts[errorkey] &&
                    errorTexts[errorkey]?.[curError]) ||
                    ""}
                  &nbsp;
                </p>
              </div>
            );
          })}
        {renderAdditionalField &&
          renderAdditionalField(formData, onChangeSelect)}
        <MainBtn className={styles.authWrapper__btn}>{btnTxt}</MainBtn>
      </form>
      {additionalContent || null}
      <LoaderPopup show={loading} />
    </section>
  );
};

export default AdminAuthWrapper;
