import InstallmentPlanConditions from "../components/InstallmentPlanPage/InstallmentPlanConditions/InstallmentPlanConditions";
import InstallmentPlanHeader from "../components/InstallmentPlanPage/InstallmentPlanHeader/InstallmentPlanHeader";
import InstallmentPlanMortgage from '../components/InstallmentPlanPage/InstallmentPlanMortgage/InstallmentPlanMortgage';
import Head from '../components/global/Head/Head'

const InstallmentPlanPage = () => {
  return <>
  <Head
        keyword={"plan ratalny, nieruchomość na raty, warunki raty, deweloper, pożyczka, wpłata 10%, umowa, nieruchomość, nieruchomości, wyciągi bankowe, zeznania podatkowe, kredyt, finansowanie"}
        title={
          "Raty na nieruchomości w Bułgarii | 10% na okres do 10 lat"
        }
        ogTitle={
          "Raty na nieruchomości w Bułgarii | 10% na okres do 10 lat"
        }
        description={
          "Korzystne warunki rat i pożyczka od dewelopera. Nie wymagamy wyciągów bankowych – wystarczy dokument tożsamości."
        }
        ogDescription={
          "Korzystne warunki rat i pożyczka od dewelopera. Nie wymagamy wyciągów bankowych – wystarczy dokument tożsamości."
        }
      />
  <InstallmentPlanHeader/>
  <InstallmentPlanConditions/>
  <InstallmentPlanMortgage/>
  </>;
};

export default InstallmentPlanPage;
