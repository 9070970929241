import {
  enFlagImage,
  enFlagWebpImage,
  plFlagImage,
  plFlagWebpImage,
  ruFlagImage,
  ruFlagWebpImage,
} from "../assets/images";

export enum ELanguagesKeys {
  pl = "pl",
  ru = "ru",
  en = "en",
}

export const languages = {
  [ELanguagesKeys.pl]: {
    key: ELanguagesKeys.pl,
    name: "Polski",
    img: [plFlagImage, plFlagWebpImage],
  },
  [ELanguagesKeys.ru]: {
    key: ELanguagesKeys.ru,
    name: "Русский",
    img: [ruFlagImage, ruFlagWebpImage],
  },
  [ELanguagesKeys.en]: {
    key: ELanguagesKeys.en,
    name: "English",
    img: [enFlagImage, enFlagWebpImage],
  },
};
