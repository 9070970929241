import List from "../../layout/List/List";

import styles from "./InstallmentPlanMortgage.module.scss";

const InstallmentPlanMortgage = () => {
  return (
    <main className={styles.installmentPlanMortgage}>
      
      <section
        className="container">
<h3 className={styles.installmentPlanMortgage__listTitle}>
              Każdy zakup nieruchomości jest wyjątkowy, co oznacza, że jego finansowanie jest również skrojone pod konkretną nieruchomość
            </h3>
        <div className={styles.installmentPlanMortgage__container}>
         
        
        <div className={styles.installmentPlanMortgage__list}>          
                     
          <List
            ItemclassName={styles.installmentPlanMortgage__listItem}
            list={[
              "Dobierzemy konkretne opcje finansowania nieruchomości Twoich marzeń, w górach lub nad morzem.",
            ]}
          />
        </div>
        <div className={styles.installmentPlanMortgage__contactBlock}>          
            <p className={styles.installmentPlanMortgage__contactInfoTxt}>
              <span>
                
              </span>
              <span>
      
              </span>
            </p>          
        </div>
        </div>
      </section>
    </main>
  );
};

export default InstallmentPlanMortgage;
