import { Fragment, ReactNode } from "react";

import styles from "./List.module.scss";

type Props = {
  title?: string;
  isTitleBlack?: boolean;
  list: ReactNode[] | ReactNode[][];
  ItemclassName?: string;
  showBulletForSingle?: boolean;
};

const List = ({
  title,
  ItemclassName,
  isTitleBlack,
  list,
  showBulletForSingle,
}: Props) => {
  return (
    <div className={styles.list}>      
        {title && (
          <h3
            className={`${styles.list__title} ${
              isTitleBlack ? styles.list__title_black : ""
            }`}
          >
            {title}
          </h3>
        )}
        <ul className={styles.list__ul}>
          {list.map((item, index) => (
            <Fragment key={index}>
              {Array.isArray(item) ? (
                item.map((innerItem, innedIndex) => (
                  <li
                    key={innedIndex}
                    className={`${styles.list__item} ${
                      styles.list__item_inner
                    } ${ItemclassName || ""}`}
                  >
                    {innerItem}
                  </li>
                ))
              ) : (
                <li
                  key={index}
                  className={`${styles.list__item} ${
                    list.filter(
                      (item) => !showBulletForSingle && !Array.isArray(item)
                    ).length === 1
                      ? styles.list__item_noBullet
                      : ""
                  } ${ItemclassName || ""}`}
                >
                  {item}
                </li>
              )}
            </Fragment>
          ))}
        </ul>      
    </div>
  );
};

export default List;
