import {
  forBuyersHeaderImage,
  forBuyersHeaderWebpImage,
} from "../../../assets/images";
import Header from "../../global/Header/Header";

import styles from "./ForBuyersHeader.module.scss";

const ForBuyersHeader = () => {
  return (
    <Header
      webpImage={forBuyersHeaderWebpImage}
      mainImage={forBuyersHeaderImage}
      backgroundClass={styles.aboutHeader}
      title="Dla kupujących"
      subtitle="Pełne informacje dla osób zainteresowanych zakupem"
    />
  );
};

export default ForBuyersHeader;
