import React from 'react'
import AdminLoginWrapper from '../../components/Admin/AdminLoginPage/AdminLoginWrapper/AdminLoginWrapper'


type Props = {}

const AdminLoginPage = (props: Props) => {
  return (
    <AdminLoginWrapper/>
  )
}

export default AdminLoginPage