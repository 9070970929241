import { ReactNode, useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper/types"; // Import Swiper type
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";

import { Navigation } from "swiper/modules";
import {
  getRandomPropertyList,
  getRecomendedPropertyList,
} from "../../../store/slices/propertiesSlice";

import PropertyCard from "../PropertyCard/PropertyCard";

import { sliderArrowLeftIcon, sliderArrowRightIcon } from "../../../assets/svg";
import { EPropertyTypes } from "../../../models/IProperty";
import AccordionProvider from "../../layout/AccordionProvider/AccordionProvider";
import Svg from "../../layout/Svg/Svg";
import styles from "./PropertiesSlider.module.scss";

type Props = {
  type?: EPropertyTypes;
  id?: number;
  title?: ReactNode;
};

const PropertiesSlider = ({ type, id, title }: Props) => {
  const dispatch = useAppDispatch();
  const nextButtonRef = useRef(null);
  const prevButtonRef = useRef(null);
  const data = useAppSelector(
    (state) =>
      state.properties[type ? "recommendedProperties" : "randomProperties"]
  );
  const [arrowsShowing, setArrowsShowing] = useState(true);
  const dataLengthRef = useRef(data?.length || 0);
  const filtredData = id ? data?.filter((item) => item.id !== id) : data;

  useEffect(() => {
    dataLengthRef.current = filtredData?.length || 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const showArrowsCondition = () => {
    const width = window.innerWidth;

    if (width < 500 && dataLengthRef.current === 1) setArrowsShowing(false);
    else if (width > 500 && width < 768 && (dataLengthRef.current || 0) <= 2)
      setArrowsShowing(false);
    else if (width > 768 && width < 1024 && (dataLengthRef.current || 0) <= 3)
      setArrowsShowing(false);
    else if (width > 1024 && (dataLengthRef.current || 0) <= 4)
      setArrowsShowing(false);
    else setArrowsShowing(true);
  };

  useEffect(() => {
    if (type) dispatch(getRecomendedPropertyList({ type }));
    else dispatch(getRandomPropertyList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    showArrowsCondition();
    window.addEventListener("resize", showArrowsCondition);

    return () => {
      window.removeEventListener("resize", showArrowsCondition);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    showArrowsCondition();
  }, [data?.length]);

  if (!filtredData?.length) return null;
  return (
    <>
      {title && <h2 className={styles.propertySlider__title}>{title}</h2>}
      <Swiper
        className={styles.propertySlider}
        modules={[Navigation]}
        navigation={{
          nextEl: styles.propertySlider__arrowBtn_left,
          prevEl: styles.propertySlider__arrowBtn_right,
        }}
        onBeforeInit={(swiper: SwiperType) => {
          if (typeof swiper.params.navigation === "object")
            swiper.params.navigation.nextEl = nextButtonRef.current;
          if (typeof swiper.params.navigation === "object")
            swiper.params.navigation.prevEl = prevButtonRef.current;
        }}
        spaceBetween={40}
        slidesPerView={1}
        breakpoints={{
          500: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 35,
          },
          1024: {
            slidesPerView: 4,
          },
        }}
      >
        {(filtredData || []).map((item) => (
          <SwiperSlide key={item.id}>
            <PropertyCard className={styles.propertySlider__item} item={item} />
          </SwiperSlide>
        ))}
        <AccordionProvider active={arrowsShowing}>
          <div className={styles.propertySlider__arrows}>
            <button
              ref={prevButtonRef}
              className={`${styles.propertySlider__arrowBtn} ${styles.propertySlider__arrowBtn_left}`}
            >
              <Svg
                id={sliderArrowLeftIcon}
                className={styles.propertySlider__arrowIcon}
              />
            </button>
            <button
              ref={nextButtonRef}
              className={`${styles.propertySlider__arrowBtn} ${styles.propertySlider__arrowBtn_right}`}
            >
              <Svg
                id={sliderArrowRightIcon}
                className={styles.propertySlider__arrowIcon}
              />
            </button>
          </div>
        </AccordionProvider>
      </Swiper>
    </>
  );
};

export default PropertiesSlider;
