import { useState } from "react";
import { searchIcon } from "../../../../assets/svg";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { deleteAllArchives } from "../../../../store/slices/propertiesSlice";
import { dispatchWithErrorHandling } from "../../../../store/tools/dispatchWithErrorHandling";
import PropertiesFilter from "../../../global/PropertiesFilter/PropertiesFilter";
import DeleteModal from "../../../layout/DeleteModal/DeleteModal";
import MainBtn from "../../../layout/MainBtn/MainBtn";
import Svg from "../../../layout/Svg/Svg";
import PropertiesTable from "../../PropertiesTable/PropertiesTable";
import styles from "./AdminArchiveWrapper.module.scss";

type Props = {};

const AdminArchiveWrapper = (props: Props) => {
  const [deleteModalOpened, setDeleteModalOpened] = useState(false);
  const dispatch = useAppDispatch();
  const archiveResultCount = useAppSelector(
    (state) => state.properties.archiveResultCount
  );
  return (
    <section className={styles.adminArchiveWrapper}>
      <h2 className={`${styles.adminArchiveWrapper__title} titleH3Light`}>
        Property Archive
      </h2>
      <div className={styles.adminArchiveWrapper__searchBlock}>
        <Svg
          id={searchIcon}
          className={styles.adminArchiveWrapper__searchIcon}
        />
      </div>
      <PropertiesFilter hideTabs hideTitle disableTranslate isArchive />
      {!!archiveResultCount && (
        <MainBtn
          onClick={() => setDeleteModalOpened(true)}
          className={styles.adminArchiveWrapper__addBtn}
        >
          Delete all Items
        </MainBtn>
      )}
      <PropertiesTable isArchive />
      <DeleteModal
        show={deleteModalOpened}
        onClose={() => setDeleteModalOpened(false)}
        title="Are you sure you want to delete all items?"
        onConfirm={() =>
          dispatchWithErrorHandling(
            dispatch,
            deleteAllArchives(),
            "Archives deleted"
          )
        }
      />
    </section>
  );
};

export default AdminArchiveWrapper;
