import { useNavigate } from "react-router-dom";

import MainBtn from "../../layout/MainBtn/MainBtn";
import { contactPagePath } from "../../../router/path";
import { scrollTop } from "../../../utils/functions/scrollTop";
import styles from "./AboutText.module.scss";

const AboutText = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className={styles.aboutText}>        
          <p className={`contentTxt ${styles.aboutText__lightTxt}`}>
            Nie wahaj się z nami skontaktować, jeśli masz jakiekolwiek inne
            pytania
          </p>
          <p className={`${styles.aboutText__txt} contentTxt`}>
            Możemy również pomóc i doradzić w innych sprawach, takich jak
            zarządzanie nieruchomościami, usługi naszych prawników, transfery
            lotniskowe, sprzątanie mieszkania, większe i mniejsze naprawy, takie
            jak malowanie, montaż podłóg, montaż płytek i kostki brukowej,
            wymiana armatury wodno-kanalizacyjnej, kuchni i szafek. Na
            zamówienie, montaż klimatyzatorów, rolet, żaluzji i zasłon,
            ubezpieczenie mienia, pomoc w zakupie mebli, sprzętu elektrycznego,
            wynajem samochodów i skuterów, posiadamy sprawdzone firmy zajmujące
            się budową domu lub dowolnego budynku, zakupem pojazdu itp.
          </p>
          <h3 className={styles.aboutText__titleTxt}>
            Możemy zrobić wszystko i nie znamy słowa NIE.{" "}
          </h3>        

        <MainBtn
          onClick={() => {
            navigate(contactPagePath);
            scrollTop();
          }}
          className={styles.aboutText__btn}
        >
          Skontaktuj się z nami
        </MainBtn>
      </div>
    </>
  );
};

export default AboutText;
