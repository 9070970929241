import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from 'swiper'; // Import Swiper type
import { Navigation } from "swiper/modules";
import { useRef } from "react";

import ImageWebp from "../../layout/ImageWebp/ImageWebp";
import styles from "./HomeClients.module.scss";

import { sliderArrowLeftIcon, sliderArrowRightIcon } from "../../../assets/svg";
import Svg from "../../layout/Svg/Svg";

import {
  partner10Image,
  partner10WebpImage,
  partner11Image,
  partner11WebpImage,
  partner12Image,
  partner12WebpImage,
  partner13Image,
  partner13WebpImage,
  partner14Image,
  partner14WebpImage,
  partner15Image,
  partner15WebpImage,
  partner16Image,
  partner16WebpImage,
  partner17Image,
  partner17WebpImage,
  partner18Image,
  partner18WebpImage,
  partner19Image,
  partner19WebpImage,
  partner1Image,
  partner1WebpImage,
  partner20Image,
  partner20WebpImage,
  partner21Image,
  partner21WebpImage,
  partner2Image,
  partner2WebpImage,
  partner3Image,
  partner3WebpImage,
  partner4Image,
  partner4WebpImage,
  partner5Image,
  partner5WebpImage,
  partner6Image,
  partner6WebpImage,
  partner7Image,
  partner7WebpImage,
  partner8Image,
  partner8WebpImage,
  partner9Image,
  partner9WebpImage,
} from "../../../assets/images";



const partnerImages = [
  [partner1Image, partner1WebpImage],
  [partner2Image, partner2WebpImage],
  [partner3Image, partner3WebpImage],
  [partner4Image, partner4WebpImage],
  [partner5Image, partner5WebpImage],
  [partner6Image, partner6WebpImage],
  [partner7Image, partner7WebpImage],
  [partner8Image, partner8WebpImage],
  [partner9Image, partner9WebpImage],
  [partner10Image, partner10WebpImage],
  [partner11Image, partner11WebpImage],
  [partner12Image, partner12WebpImage],
  [partner13Image, partner13WebpImage],
  [partner14Image, partner14WebpImage],
  [partner15Image, partner15WebpImage],
  [partner16Image, partner16WebpImage],
  [partner17Image, partner17WebpImage],
  [partner18Image, partner18WebpImage],
  [partner19Image, partner19WebpImage],
  [partner20Image, partner20WebpImage],
  [partner21Image, partner21WebpImage],
];

const HomeClients = () => {
  const nextButtonRef = useRef(null);
  const prevButtonRef = useRef(null);

  return (
    <section className={styles.homeClients}>
      <div className={styles.homeClients__report}>
        <div className="container">
          <h2 className={styles.homeClients__reportTitle}>
            Opinie naszych klientów
          </h2>

          <Swiper
  className={styles.homeClients__slider}
  modules={[Autoplay, Navigation]}
  onBeforeInit={(swiper: SwiperType) => {  // Define the swiper type
    if (typeof swiper.params.navigation === 'object') {
      swiper.params.navigation.nextEl = nextButtonRef.current;
      swiper.params.navigation.prevEl = prevButtonRef.current;
    }
  }}
  slidesPerView={1}
  autoplay={{
    delay: 3000,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  }}
  navigation={{
    nextEl: styles.homeClients__arrowBtn_left,
    prevEl: styles.homeClients__arrowBtn_right,
  }}
  loop
>
            <SwiperSlide>
              <p className={styles.homeClients__reportTxt}>
                Bardzo chwalę i polecam Reality Bulgaria, a w szczególności
                Andreasa Fialę. To człowiek na swoim miejscu. Ma ogólne pojęcie
                o wszystkim i pomimo mojej początkowej niepewności i niezliczonych
                pytań, zawsze zapewniał mnie o swojej niezawodności, perspektywie i
                zainteresowaniu zadowoleniem swoich klientów. Zawsze był pod
                telefonem mimo nawału pracy, zawsze odpowiadał, doradzał,
                organizował co trzeba i co obiecał, pomagał i nadal pomaga. Dzięki
                niemu spełniło się nasze marzenie o zakupie nieruchomości nad
                morzem i nie możemy się doczekać lata „w domu” z naszym psem.
                Wielkie DZIĘKUJEMY Andreas, z niecierpliwością czekamy na
                współpracę z Tobą w przyszłości i chętnie Cię polecimy.
              </p>
              <h6 className={styles.homeClients__reporterTxt}>Marie Strauss</h6>
            </SwiperSlide>

            <SwiperSlide>              
              <p className={styles.homeClients__reportTxt}>
                Super podejście, super wycieczki, super jedzenie, super
                zakwaterowanie. Kupiliśmy mieszkanie w Słonecznym Brzegu i
                dziękujemy za doskonałe podejście ze specjalnymi bezpłatnymi
                usługami pana Andreasa. Chcesz kupić mieszkanie w Bułgarii?
                Tylko od Andreasa
              </p>
                <h6 className={styles.homeClients__reporterTxt}>Klara Ticha</h6>              
            </SwiperSlide>
            <SwiperSlide>              
              <p className={styles.homeClients__reportTxt}>
                Po moim szalonym pomyśle zainwestowania w apartamenty w
                Bułgarii, miałem szczęście spotkać pana Andreasa Fialę, który
                okazał się świetnym ekspertem na lokalnym rynku i pomógł nam
                zdobyć nasze wymarzone mieszkanie nad morzem i w górach w
                Bansko. Oprócz perfekcyjnego zamknięcia transakcji i
                transkrypcji z lokalnymi władzami, pomógł nam poznać lokalne
                zwyczaje, kulturę, a jego obsługa posprzedażowa i komunikacja
                są nienaganne. Jeśli masz podobny plan jak my, mogę go tylko
                polecić.
              </p>
              <h6 className={styles.homeClients__reporterTxt}>Pavel Hoda</h6>              
            </SwiperSlide>



            <div className={styles.homeClients__arrows}>
              <button
                ref={prevButtonRef}
                className={`${styles.homeClients__arrowBtn} ${styles.homeClients__arrowBtn_left}`}
              >
                <Svg id={sliderArrowLeftIcon}
                 className={styles.homeClients__arrowIcon} />
              </button>
              <button
                ref={nextButtonRef}
                className={`${styles.homeClients__arrowBtn} ${styles.homeClients__arrowBtn_right}`}
              >
                <Svg id={sliderArrowRightIcon} 
                className={styles.homeClients__arrowIcon} />
              </button>
            </div>
          </Swiper>
        </div>
      </div>

{/* Partners block start */}

      <div className={`container ${styles.homeClients__partners}`}>
        <h2 className={`titleH2 ${styles.homeClients__partnersTitle}`}>
          Nasi partnerzy
        </h2>
        <Swiper
          className={styles.homeClients__slider}
          modules={[Autoplay]}
          spaceBetween={20}
          slidesPerView={1}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          loop
          breakpoints={{
            576: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 35,
            },
            1024: {
              slidesPerView: 4,
            },
          }}
        >
          {partnerImages.map((item, index) => (
            <SwiperSlide key={index}>
              <div className={styles.homeClients__sliderImgWrapper}>
                <ImageWebp
                  src={item[0]}
                  srcSet={item[1]}
                  alt="partner"
                  className={styles.homeClients__sliderImg}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default HomeClients;
