import { useNavigate } from "react-router-dom";

import PropertiesSlider from "../../global/PropertiesSlider/PropertiesSlider";
import MainBtn from "../../layout/MainBtn/MainBtn";

import { useAppSelector } from "../../../hooks/redux";
import { propertiesPagePath } from "../../../router/path";
import { formatNumber } from "../../../utils/functions/formatNumber";
import { scrollTop } from "../../../utils/functions/scrollTop";
import styles from "./HomeOffer.module.scss";

const HomeOffer = () => {
  const totalCount = useAppSelector((state) => state.properties.totalCount);
  const navigate = useNavigate();
  return (
    <section className={styles.homeOffer}>
      <PropertiesSlider
        title={
          <span className={`${styles.homeOffer__title} titleH2`}>
            Nasze oferty
          </span>
        }
      />      
        <p className={`${styles.homeOffer__propertiesCountTxt}`}>
          Obecnie oferujemy <strong>{formatNumber(totalCount || 0)}</strong>{" "}
          nieruchomości
        </p>      
      <MainBtn
        className={styles.homeOffer__btn}
        onClick={() => {
          navigate(propertiesPagePath);
          scrollTop();
        }}
      >
        Zobacz wszystkie
      </MainBtn>
    </section>
  );
};

export default HomeOffer;
