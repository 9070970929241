import PropeertiesWrapper from "../../../components/Admin/AdminPropertiesPage/AdminPropeertiesWrapper/AdminPropeertiesWrapper";

const AdminPropertiesPage = () => {
  return (
    <>
     <PropeertiesWrapper/>
    </>
  );
};

export default AdminPropertiesPage;
