import { useNavigate } from "react-router-dom";

import List from "../../layout/List/List";
import MainBtn from "../../layout/MainBtn/MainBtn";

import { installmentPagePath } from "../../../router/path";
import { scrollTop } from "../../../utils/functions/scrollTop";
import styles from "./HomeInstallment.module.scss";

const HomeInstallment = () => {
  const navigate = useNavigate();
  return (
    <main className={styles.homeInstallment}>      
        <h2 className={`titleH2 ${styles.homeInstallment__titleH2}`}>
        Jeśli chcesz kupić mieszkanie lub dom w Bułgarii na raty.<br /> Nie musisz starać się o kredyt.<br /> 
        </h2>
        <h5 className={styles.homeInstallment__subtitle}>
        Sprzedajemy nieruchomości na raty bezpośrednio od inwestora - dewelopera. Nieruchomości są składnikiem jego aktywów.<br />{" "}
        </h5>
        <h5 className={styles.homeInstallment__subtitlePrimary}>
          <i>Mieszkania na raty z zaliczką 10% bez wkładu własnego</i>
        </h5>      
      <div className={styles.homeInstallment__lists}>
        <List
          list={[
            "Pierwsza wpłata 10% ceny i mieszkasz w ciągu 48 godzin lub wynajmujesz",
            "Okres spłaty do 10 lat",
            "Wystarczy dowód osobisty",
            "Brak sprawdzania rejestrów",
            "Brak weryfikacji dochodów",
          ]}
        />
        <List
          list={[
            "Brak deklaracji podatkowych",
            "Brak wyciągów z konta bankowego",
            "Wystarczy podpisać umowę",
            "Wpłać wybraną zaliczkę przelewem bankowym",
            "Mieszkasz lub wynajmujesz w ciągu 48 godzin",
          ]}
        />
      </div>
      <MainBtn
        className={styles.homeInstallment__btn}
        onClick={() => {
          navigate(installmentPagePath);
          scrollTop();
        }}
      >
        Więcej informacji
      </MainBtn>      
        <p className={styles.homeInstallment__endingTxt}>
          Oferujemy również transfery z i na lotnisko + zakwaterowanie od jednej
          do 4 osób całkowicie GRATIS!!!
        </p>      
    </main>
  );
};

export default HomeInstallment;
