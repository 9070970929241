export function formatDate(dateStr: string | Date, reversed?: boolean) {
  const date = new Date(dateStr);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  let result = `${day}.${month}.${year}`;
  if (reversed) {
    return result.split(".").reverse().join(".");
  } else {
    return `${result}`;
  }
}

export const formatDateLong = (dateStr: string | Date) => {
  const date = new Date(dateStr);

  const formatter = new Intl.DateTimeFormat("pl-PL", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  return formatter.format(date).replace(",", "");
};
