import HomeAddvantages from "../components/HomePage/HomeAddvantages/HomeAddvantages";
import HomeClients from "../components/HomePage/HomeClients/HomeClients";
import HomeHeader from "../components/HomePage/HomeHeader/HomeHeader";
import HomeInstallment from "../components/HomePage/HomeInstallment/HomeInstallment";
import HomeOffer from "../components/HomePage/HomeOffer/HomeOffer";

const HomePage = () => {
  return (
    <>
      <HomeHeader />
      <main className="container">
        <HomeInstallment />
        <HomeOffer />
        <HomeAddvantages />
      </main>
      <HomeClients />
    </>
  );
};

export default HomePage;
