import { EObjectStatusTypes, EPropertyTypes } from "../models/IProperty";

export const typeOptionNames = {
  [EPropertyTypes.building]: "Mieszkania",
  [EPropertyTypes.house]: "Domy",
  [EPropertyTypes.buisnessCenter]: "Obiekty biznesowe",
};

export const objectStatusNames = {
  [EObjectStatusTypes.wooden]: "Drewniany",
  [EObjectStatusTypes.likeNew]: "Jak nowy",
  [EObjectStatusTypes.newConstruction]: "Nowa budowa",
  [EObjectStatusTypes.repairsRequired]: "Wymaga remontu",
  [EObjectStatusTypes.afterReconstruction]: "po remoncie",
  [EObjectStatusTypes.satisfactorily]: "Zadowalający",
  [EObjectStatusTypes.new]: "Nowy",
  [EObjectStatusTypes.veryGood]: "Bardzo dobry",
  [EObjectStatusTypes.great]: "Wspaniały",
  [EObjectStatusTypes.secondaryMarket]: "Rynek wtórny",
};

export const objectStatusNamesArr = [
  EObjectStatusTypes.wooden,
  EObjectStatusTypes.likeNew,
  EObjectStatusTypes.newConstruction,
  EObjectStatusTypes.repairsRequired,
  EObjectStatusTypes.afterReconstruction,
  EObjectStatusTypes.satisfactorily,
  EObjectStatusTypes.new,
  EObjectStatusTypes.veryGood,
  EObjectStatusTypes.great,
  EObjectStatusTypes.secondaryMarket,
];
