import { createSlice } from "@reduxjs/toolkit";
import { ELanguagesKeys } from "../../constants/languages";

export interface UIState {
  tooltipTxt: string;
  tooltipshowing: boolean;
  tooltipUpdateCount: number;
  siteLanguage: ELanguagesKeys;
}

const initialState: UIState = {
  tooltipTxt: "",
  tooltipshowing: false,
  tooltipUpdateCount: 0,
  siteLanguage: ELanguagesKeys.pl,
};

export const UISlice = createSlice({
  name: "uISlice",
  initialState,
  reducers: {
    openTooltip(state, { payload }) {
      state.tooltipTxt = payload;
      state.tooltipshowing = true;
      state.tooltipUpdateCount = state.tooltipUpdateCount + 1;
    },
    closeTooltip(state) {
      state.tooltipshowing = false;
      state.tooltipUpdateCount = 0;
    },
    setSiteLangauge(state, { payload }) {
      state.siteLanguage = payload;
    },
  },
});

export const { openTooltip, closeTooltip, setSiteLangauge } = UISlice.actions;

export default UISlice.reducer;
