import { useEffect, useRef, useState } from "react";
import { useLazy } from "../../../hooks/useLazyLoad";
import styles from "./AboutCounters.module.scss";

const countersEndState = {
  year: 5,
  properties: 644,
  clients: 248,
  regions: 26,
};

const AboutCounters = () => {
  const { isInView, ref } = useLazy(0.5, true);
  const [counters, setCounters] = useState({
    year: 0,
    properties: 0,
    clients: 0,
    regions: 0,
  });
  const countersref = useRef(counters);

  useEffect(() => {
    countersref.current = counters;
  }, [counters]);

  useEffect(() => {
    if (isInView) {
      const interval = setInterval(() => {
        if (countersref.current.year < countersEndState.year) {
          const updatedCounter: Partial<typeof counters> = {};

          for (let key in counters) {
            updatedCounter[key as keyof typeof updatedCounter] = Math.round(
              countersref.current[key as keyof typeof countersref.current] +
                countersEndState[key as keyof typeof updatedCounter] / 10
            );
          }
          setCounters(updatedCounter as typeof counters);
        } else {
          setCounters(countersEndState);
          clearInterval(interval);
        }
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView]);

  return (
    <>
      <section className={styles.aboutCounters}>
        <h2 className={`primaryTitleH2 ${styles.aboutCounters__title}`}>
          Rzetelność i zaufanie
        </h2>
        <div className={styles.aboutCounters__main} ref={ref}>
          <div className={styles.aboutCounters__years}>
            <h2 className={styles.aboutCounters__yearsCountTxt}>
              {counters.year}
            </h2>
            <h4 className={styles.aboutCounters__yearstxt}>Lata</h4>
          </div>
          <div className={styles.aboutCounters__mainItem}>
            <h3 className={styles.aboutCounters__mainCounterTxt}>
              {counters.properties}+
            </h3>
            <h6 className={styles.aboutCounters__mainCounterTypeTxt}>
              Nieruchomość
            </h6>
          </div>
          <div className={styles.aboutCounters__mainItem}>
            <h3 className={styles.aboutCounters__mainCounterTxt}>
              {counters.clients}+
            </h3>
            <h6 className={styles.aboutCounters__mainCounterTypeTxt}>
              Klientów
            </h6>
          </div>
          <div className={styles.aboutCounters__mainItem}>
            <h3 className={styles.aboutCounters__mainCounterTxt}>
              {counters.regions}+
            </h3>
            <h6 className={styles.aboutCounters__mainCounterTypeTxt}>
              Regionów
            </h6>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutCounters;
