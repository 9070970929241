import { IFilters, IFiltersQuery } from "../../models/UI/IFilters";

export const getUrlWithFiltersQuery = (
  url: string,
  filters: IFiltersQuery = {}
) => {
  let filtersQuery = "";
  if (filters) {
    for (let key in filters) {
      const filter = filters[key as keyof IFilters];
      if (!filter && typeof filter !== "boolean") continue;
      let value = filter;
      filtersQuery += `${filtersQuery ? "&" : "?"}${key}=${value}`;
    }
  }
  return `${url}${filtersQuery}`;
};
