import { useEffect, useRef, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

import ImageWebp from "../../../layout/ImageWebp/ImageWebp";
import Svg from "../../../layout/Svg/Svg";
import FaqModal from "../../FaqModal/FaqModal";

import {
  logoDarkImage,
  logoDarkWebpImage,
  logoImage,
  logoWebpImage,
} from "../../../../assets/images";
import { burgerIcon, faqIcon, whatsappIcon } from "../../../../assets/svg";
import { phoneNumber } from "../../../../constants/contacts";
import { menuItems } from "../../../../constants/menuItems";
import {
  contactPagePath,
  cookiePolicyPagePath,
  errorPagePath,
  homePagePath,
  propertiesPagePath,
} from "../../../../router/path";
import { scrollTop } from "../../../../utils/functions/scrollTop";
import styles from "./Topbar.module.scss";

type Props = {
  openSidebar: () => void;
};

const Topbar = ({ openSidebar }: Props) => {
  const location = useLocation();
  const [faqModalopened, setFaqModalopened] = useState(false);
  const [isTransparent, setIsTransparent] = useState(false);
  const isTransparentRef = useRef(isTransparent);
  const pathNameRef = useRef(location.pathname);
  const checkIsOnTop = () => {
    const notTransparentPages = [
      propertiesPagePath,
      contactPagePath,
      cookiePolicyPagePath,
      errorPagePath,
    ];

    if (
      !notTransparentPages.find((item) => pathNameRef.current.includes(item)) &&
      window.scrollY === 0
    ) {
      setIsTransparent(true);
    } else if (isTransparentRef.current) setIsTransparent(false);
  };

  useEffect(() => {
    isTransparentRef.current = isTransparent;
  }, [isTransparent]);

  useEffect(() => {
    pathNameRef.current = location.pathname;
    checkIsOnTop();
  }, [location.pathname]);

  useEffect(() => {
    window.addEventListener("scroll", checkIsOnTop);

    return () => {
      window.removeEventListener("scroll", checkIsOnTop);
    };
  }, []);

  const setActiveNavLinkClass = () => {
    return ({ isActive }: { isActive: boolean }) =>
      isActive
        ? `${styles.topbar__menuLink} ${styles.topbar__menuLink_active}`
        : styles.topbar__menuLink;
  };

  const activeNavLinks = setActiveNavLinkClass();

  return (
    <>
      <div className={styles.topbar}>
        <div
          className={`${styles.topbar__main} ${
            isTransparent ? styles.topbar__main_transparent : ""
          }`}
        >
          <div className={styles.topbar__main_container}>
            <Link
              to={homePagePath}
              onClick={scrollTop}
              className={styles.topbar__logoLink}
            >
              <ImageWebp
                style={{ display: !isTransparent ? "block" : "none" }}
                src={logoDarkImage}
                srcSet={logoDarkWebpImage}
                alt="logo"
                className={styles.topbar__logoImg}
              />
              <ImageWebp
                style={{ display: isTransparent ? "block" : "none" }}
                src={logoImage}
                srcSet={logoWebpImage}
                alt="logo"
                className={styles.topbar__logoImg}
              />
            </Link>
            <nav className={styles.topbar__menu}>
              <ul className={styles.topbar__menuList}>
                {menuItems.map((item, index) => (
                  <li className={styles.topbar__menuListItem} key={index}>
                    <NavLink
                      to={item.link}
                      className={activeNavLinks}
                      onClick={scrollTop}
                    >
                      {item.text}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </nav>
            <div className={styles.topbar__options}>
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://wa.me/${phoneNumber.replaceAll(" ", "")}`}
                className={`${styles.topbar__whatsappLink} ${styles.topbar_tabHidden}`}
              >
                <Svg
                  id={whatsappIcon}
                  className={styles.topbar__whatsappIcon}
                />
                <span className={styles.topbar__whatsappTxt}>
                  {phoneNumber}
                </span>
              </a>
              <button
                className={styles.topbar__faqBtn}
                onClick={() => setFaqModalopened(true)}
              >
                <Svg id={faqIcon} className={styles.topbar__faqIcon} />
              </button>

              <div className={styles.topbar__burgerWrapper}>
                <button
                  className={styles.topbar__burgerBtn}
                  onClick={openSidebar}
                >
                  <Svg id={burgerIcon} className={styles.topbar__burgerIcon} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FaqModal
        show={faqModalopened}
        onClose={() => setFaqModalopened(false)}
      />
    </>
  );
};

export default Topbar;
