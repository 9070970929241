import styles from "./CookiePrivacyWrapper.module.scss";

const CookiePrivacyWrapper = () => {
  return (
    <main className={`container ${styles.cookiePrivacyWrapper}`}>
      <div className="headerDistance"></div>
      <h2 className={styles.title}>Polityka prywatności / cookies</h2>
      <h3>1. Postanowienia ogólne</h3>
      <p>
        Polityka prywatności określa, jak zbierane, przetwarzane i przechowywane
        są dane osobowe Użytkowników niezbędne do świadczenia usług drogą
        elektroniczną za pośrednictwem serwisu internetowego{" "}
        <a
          href="https://www.realitybulgaria.pl"
          rel="noreferrer"
          target="_blank"
        >
          https://www.realitybulgaria.pl
        </a>{" "}
        (dalej: Serwis).
      </p>
      <p>
        Serwis zbiera wyłącznie dane osobowe niezbędne do świadczenia i rozwoju
        usług w nim oferowanych.
      </p>
      <p>
        Dane osobowe zbierane za pośrednictwem Serwisu są przetwarzane zgodnie z
        Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27
        kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z
        przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich
        danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o
        ochronie danych, dalej RODO) oraz ustawą o ochronie danych osobowych z
        dnia 10 maja 2018 r.
      </p>
      <h3>2. Administrator danych</h3>
      <p>
        Administratorem danych osobowych zbieranych poprzez Serwis jest (dalej:
        Administrator). JAWORSKI ZBIGNIEW Am Apselbaum 10, 51580 REICHSHOF
        GERMANY. adres poczty elektronicznej:{" "}
        <a href="mailto:zjrealitybulgaria@gmail.com"> zjrealitybulgaria@gmail.com</a>.
      </p>
      <h3>3. Cel zbierania danych osobowych</h3>
      <h4>Dane osobowe wykorzystywane są w celu:</h4>
      <ul>
        <li>rejestracji konta i weryfikacji tożsamości Użytkownika,</li>
        <li>umożliwienia logowania do Serwisu,</li>
        <li>realizacji umowy dotyczącej usług i e-usług,</li>
        <li>
          komunikacji z Użytkownikiem (livechat, formularz kontaktowy itp.)
        </li>
        <li>
          wysyłki newslettera (po wyrażeniu zgody Użytkownika na jego
          otrzymywanie),
        </li>
        <li>prowadzenia systemu komentarzy,</li>
        <li>świadczenia usług społecznościowych,</li>
        <li>promocji oferty Administratora,</li>
        <li>marketingu, remarketingu, afiliacji,</li>
        <li>personalizacji Serwisu dla Użytkowników,</li>
        <li>działań analitycznych i statystycznych,</li>
        <li>windykacji należności,</li>
        <li>ustalenia i dochodzenia roszczeń albo obrony przed nimi.</li>
      </ul>
      <p>
        Podanie danych jest dobrowolne, ale niezbędne do zawarcia umowy albo
        skorzystania z innych funkcjonalności Serwisu.
      </p>
      <h3>4. Rodzaj przetwarzanych danych osobowych</h3>
      <p>
        Administrator może przetwarzać dane osobowe Użytkownika: imię i
        nazwisko, data urodzenia, adres zamieszkania, adres e-mail, numer
        telefonu, NIP.
      </p>
      <h3>5. Okres przetwarzania danych osobowych</h3>
      <h4>Dane osobowe Użytkowników będą przetwarzane przez okres:</h4>
      <ol>
        <li>
          gdy podstawą przetwarzania danych jest wykonanie umowy – do momentu
          przedawnienia roszczeń po jej wykonaniu,
        </li>
        <li>
          gdy podstawą przetwarzania danych jest zgoda – do momentu jej
          odwołania, a po odwołaniu zgody do przedawnienia roszczeń.
        </li>
      </ol>
      <p>
        W obu przypadkach termin przedawnienia wynosi 6 lat, a dla roszczeń o
        świadczenia okresowe i roszczeń dotyczących prowadzenia działalności
        gospodarczej – 3 lata (jeśli przepis szczególny nie stanowi inaczej).
      </p>
      <h3>6. Udostępnianie danych osobowych</h3>
      <p>
        Dane osobowe Użytkowników mogą być przekazywane: podmiotom powiązanym z
        Administratorem, jego podwykonawcom, podmiotom współpracującym z
        Administratorem np. firmom obsługującym e-płatności, firmom świadczącym
        usługi kurierskie/pocztowe, kancelariom prawnym.
      </p>
      <p>
        Dane osobowe Użytkowników nie będą/będą przekazywane poza teren
        Europejskiego Obszaru Gospodarczego (EOG).
      </p>
      <h3>7. Prawa Użytkowników</h3>
      <p>
        Użytkownik Serwisu ma prawo do: dostępu do treści swoich danych
        osobowych, ich sprostowania, usunięcia, ograniczenia przetwarzania,
        przenoszenia, wniesienia sprzeciwu wobec przetwarzania, cofnięcia zgody
        w każdej chwili (co nie ma wpływu na zgodność z prawem przetwarzania
        dokonanego w oparciu o zgodę przed jej cofnięciem).
      </p>
      <p>
        Zgłoszenie o wystąpieniu przez Użytkownika z uprawnieniem wynikającym z
        wymienionych praw należy przesłać na adres{" "}
        <a href="zjrealitybulgaria@gmail.com"> zjrealitybulgaria@gmail.com</a>.
      </p>
      <p>
        Administrator spełnia lub odmawia spełnienia żądania niezwłocznie –
        maksymalnie w ciągu miesiąca od jego otrzymania.
      </p>
      <p>
        Użytkownik ma prawo złożyć skargę do Prezesa Urzędu Ochrony Danych
        Osobowych, jeśli uzna, że przetwarzanie narusza jego prawa i wolności
        (RODO).
      </p>
      <h3>8. Pliki cookies</h3>
      <p>
        Serwis zbiera informacje za pomocą plików cookies – sesyjnych, stałych i
        podmiotów zewnętrznych.
      </p>
      <p>
        Zbieranie plików cookies wspiera poprawne świadczenie usług w Serwisie i
        służy celom statystycznym.
      </p>
      <p>
        Użytkownik może określić zakres dostępu plików cookies do swojego
        urządzenia w ustawieniach przeglądarki.
      </p>
      <h3>9. Zautomatyzowane podejmowanie decyzji i profilowanie</h3>
      <p>
        Dane Użytkowników nie mogą być przetwarzane w zautomatyzowany sposób
        tak, że na skutek tego mogłyby zapaść wobec nich jakiekolwiek decyzje.
      </p>
      <p>
        Dane Użytkowników mogą być profilowane celem dostosowania treści i
        personalizacji oferty po wyrażeniu przez nich zgody.
      </p>
      <h3>10. Postanowienia końcowe</h3>
      <p>
        Administrator ma prawo do wprowadzenia zmian w Polityce prywatności,
        przy czym prawa Użytkowników nie zostaną ograniczone.
      </p>
      <p>
        Informacja o wprowadzonych zmianach pojawi się w formie komunikatu
        dostępnego w Serwisie.
      </p>
      <p>
        W sprawach nieuregulowanych w niniejszej Polityce prywatności obowiązują
        przepisy RODO i przepisy prawa polskiego.
      </p>
      <p>
        Informacje na temat polityki prywatności i plików cookies znajdziesz na{" "}
        <a
          href="https://www.wygranaonline.com"
          rel="noreferrer"
          target="_blank"
        >
          https://www.wygranaonline.com
        </a>
        .
      </p>
    </main>
  );
};

export default CookiePrivacyWrapper;
