import { ReactNode } from "react";

import AdminFooter from "../AdminFooter/AdminFooter";
import styles from "./AdminWrapper.module.scss";
import { useAppSelector } from "../../../hooks/redux";

type Props = {
  children: ReactNode;
};

const AdminWrapper = ({ children }: Props) => {
  const token = useAppSelector((state) => state.user.token);

  return (
    <main
      className={`${styles.appWrapper} ${
        token ? styles.appWrapper_active : styles.appWrapper_pasive
      }`}
    >
      <div className={styles.appWrapper__container}>{children}</div>
      <AdminFooter />
    </main>
  );
};

export default AdminWrapper;
