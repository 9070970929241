import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  AddPropertyForm,
  AddPropertyRequest,
} from "../../models/api/addProperty";
import { GetPropertiesResponse } from "../../models/api/getProperties";
import { GetPropertyFiltersResponse } from "../../models/api/getPropertyFilters";
import { FormError } from "../../models/Errors/FormError";
import { IFetchError } from "../../models/Errors/IFetchError";
import { IEquipment } from "../../models/IEquipment";
import { ILocationFilter } from "../../models/ILocationFilter";
import { EPropertyTypes, IProperty } from "../../models/IProperty";
import { IFiltersQuery } from "../../models/UI/IFilters";
import { RootState } from "../store";
import {
  archivePropertyUrl,
  authConfig,
  createEquipmentUrl,
  createFormData,
  createLocationUrl,
  createPropertyUrl,
  fetchRequest,
  getEquipmentUrl,
  getLocationUrl,
  getPropertiesFiltersUrl,
  getPropertiesUrl,
  setFormError,
} from "../tools/fetchTools";
import { getUrlWithFiltersQuery } from "../tools/getUrlWithFiltersQuery";

interface IFilterCounts {
  locations: ILocationFilter[] | null;
  equipments: IEquipment[];
  types: {
    building: number;
    house: number;
    buisnessCenter: number;
  };
}

export interface PropertyState {
  data: IProperty[] | null;
  archiveData: IProperty[] | null;
  randomProperties: IProperty[] | null;
  recommendedProperties: IProperty[] | null;
  getLoading: boolean;
  getArchiveLoading: boolean;
  getError: string | null;
  getOneLoading: boolean;
  getOneError: string | null;
  totalCount: number | null;
  archiveTotalCount: number | null;
  archiveResultCount: number | null;
  resultCount: number;
  filterCounts: IFilterCounts;
  archiveFilterCounts: IFilterCounts;
  createError: null | FormError<AddPropertyForm>;
  editError: null | FormError<AddPropertyForm>;
  addLocationError: null | FormError<{ name: string }>;
  addEquipmentError: null | FormError<{ name: string }>;
}

const initialFilters = {
  locations: null,
  equipments: [],
  types: {
    [EPropertyTypes.building]: 0,
    [EPropertyTypes.house]: 0,
    [EPropertyTypes.buisnessCenter]: 0,
  },
};

const initialState: PropertyState = {
  data: null,
  archiveData: null,
  randomProperties: null,
  recommendedProperties: null,
  getLoading: false,
  getArchiveLoading: false,
  getError: null,
  createError: null,
  editError: null,
  addLocationError: null,
  addEquipmentError: null,
  getOneLoading: false,
  getOneError: null,
  totalCount: null,
  archiveTotalCount: null,
  archiveResultCount: null,
  resultCount: 0,
  filterCounts: initialFilters,
  archiveFilterCounts: initialFilters,
};

export const fetchProperties = async (filters: IFiltersQuery) => {
  const resData = await fetchRequest<GetPropertiesResponse>(
    getUrlWithFiltersQuery(getPropertiesUrl, filters)
  );
  return resData;
};

export const getPropertyList = createAsyncThunk<
  {
    data: IProperty[];
    resultCount: number;
  },
  IFiltersQuery,
  { rejectValue: string }
>("properties/getPropertyList", async (filters, { rejectWithValue }) => {
  try {
    const resData = await fetchProperties(filters);
    return {
      data: resData.data,
      resultCount: resData.resultCount,
    };
  } catch (error) {
    return rejectWithValue("Error");
  }
});

export const getArchivedPropertyList = createAsyncThunk<
  {
    data: IProperty[];
    resultCount: number;
  },
  IFiltersQuery,
  { rejectValue: string }
>(
  "properties/getArchivedPropertyList",
  async (filters, { rejectWithValue }) => {
    try {
      const resData = await fetchProperties({ ...filters, archived: "1" });
      return {
        data: resData.data,
        resultCount: resData.resultCount,
      };
    } catch (error) {
      return rejectWithValue("Error");
    }
  }
);

export const getRandomPropertyList = createAsyncThunk<
  IProperty[],
  void,
  { rejectValue: string }
>("properties/getRandom", async (_, { rejectWithValue }) => {
  try {
    const resData = await fetchProperties({
      pageSize: "12",
      sort: "random",
    });
    return resData.data;
  } catch (error) {
    return rejectWithValue("Error");
  }
});

export const getRecomendedPropertyList = createAsyncThunk<
  IProperty[],
  { type: EPropertyTypes },
  { rejectValue: string }
>("properties/getRecomended", async (filters, { rejectWithValue }) => {
  try {
    const resData = await fetchProperties({
      pageSize: "12",
      sort: "random",
      type: filters.type,
    });
    return resData.data;
  } catch (error) {
    return rejectWithValue("Error");
  }
});

export const getOneProperty = createAsyncThunk<
  IProperty[],
  { id: number },
  { rejectValue: string }
>("properties/getOne", async ({ id }, { rejectWithValue, getState }) => {
  try {
    const resData = await fetchRequest<{ property: IProperty }>(
      `${getPropertiesUrl}/${id}`
    );
    const state = getState() as RootState;
    const properties = [...(state.properties.data || [])];
    const updatingItemIndex = properties.findIndex((item) => item.id === id);
    if (updatingItemIndex === -1) {
      properties.push(resData.property);
    } else {
      properties[updatingItemIndex] = resData.property;
    }
    return properties;
  } catch (error) {
    return rejectWithValue("Error");
  }
});

export const getPropertyFilters = createAsyncThunk<
  PropertyState["filterCounts"] & { totalCount: number; isArchive?: boolean },
  boolean | undefined,
  { rejectValue: string }
>("properties/getPropertyFilters", async (isArchive, { rejectWithValue }) => {
  try {
    const resData = await fetchRequest<GetPropertyFiltersResponse>(
      `${getPropertiesFiltersUrl}${isArchive ? "/?archive=true" : ""}`
    );
    return {
      totalCount: resData.counts.all,
      isArchive,
      locations: resData.counts.Location,
      equipments: resData.counts.Equipment,
      types: {
        building: resData.counts.type.building,
        house: resData.counts.type.house,
        buisnessCenter: resData.counts.type.buisnessCenter,
      },
    };
  } catch (error) {
    return rejectWithValue("Error");
  }
});

export const addPropertyLocation = createAsyncThunk<
  ILocationFilter[],
  { name: string },
  { rejectValue: FormError<{ name: string }> }
>(
  "properties/addPropertyLocation",
  async ({ name }, { rejectWithValue, getState }) => {
    try {
      const resData = await fetchRequest<Omit<ILocationFilter, "count">>(
        createLocationUrl,
        "POST",
        {
          name,
        }
      );

      const state = getState() as RootState;
      const locations = [
        ...(state.properties.filterCounts.locations || []),
        { ...resData, count: 0 },
      ];
      return locations;
    } catch (error) {
      return rejectWithValue(
        setFormError(error as IFetchError<{ name: string }>)
      );
    }
  }
);

export const editPropertyLocation = createAsyncThunk<
  ILocationFilter[],
  { id: number; name: string },
  { rejectValue: string }
>(
  "properties/editPropertyLocation",
  async ({ id, name }, { rejectWithValue, getState }) => {
    try {
      await fetchRequest<Omit<ILocationFilter, "count">>(
        getLocationUrl + id,
        "PUT",
        {
          name,
        }
      );

      const state = getState() as RootState;
      const locations = [...(state.properties.filterCounts.locations || [])];
      const updateingItemIndex = locations.findIndex((item) => item.id === id);

      if (updateingItemIndex !== -1) {
        locations[updateingItemIndex] = {
          ...locations[updateingItemIndex],
          name,
        };
      }

      return locations;
    } catch (error) {
      return rejectWithValue("Error");
    }
  }
);

export const deletePropertyLocation = createAsyncThunk<
  ILocationFilter[],
  { id: number },
  { rejectValue: string }
>(
  "properties/deletePropertyLocation",
  async ({ id }, { rejectWithValue, getState }) => {
    try {
      await fetchRequest<void>(getLocationUrl + id, "DELETE");

      const state = getState() as RootState;
      const locations = [
        ...(state.properties.filterCounts.locations || []),
      ].filter((item) => item.id !== id);
      return locations;
    } catch (error) {
      return rejectWithValue("Error");
    }
  }
);

export const addPropertyEquipment = createAsyncThunk<
  IEquipment[],
  { name: string },
  { rejectValue: FormError<{ name: string }> }
>(
  "properties/addPropertyEquipment",
  async ({ name }, { rejectWithValue, getState }) => {
    try {
      const resData = await fetchRequest<IEquipment>(
        createEquipmentUrl,
        "POST",
        {
          name,
        }
      );

      const state = getState() as RootState;
      const equipments = [
        ...(state.properties.filterCounts.equipments || []),
        resData,
      ];
      return equipments;
    } catch (error) {
      return rejectWithValue(
        setFormError(error as IFetchError<{ name: string }>)
      );
    }
  }
);

export const editPropertyEquipment = createAsyncThunk<
  IEquipment[],
  { id: number; name: string },
  { rejectValue: string }
>(
  "properties/editPropertyEquipment",
  async ({ id, name }, { rejectWithValue, getState }) => {
    try {
      await fetchRequest<IEquipment>(getEquipmentUrl + id, "PUT", {
        name,
      });

      const state = getState() as RootState;
      const equipments = [...(state.properties.filterCounts.equipments || [])];
      const updateingItemIndex = equipments.findIndex((item) => item.id === id);

      if (updateingItemIndex !== -1) {
        equipments[updateingItemIndex] = {
          ...equipments[updateingItemIndex],
          name: name,
        };
      }

      return equipments;
    } catch (error) {
      return rejectWithValue("Error");
    }
  }
);

export const deletePropertyEquipment = createAsyncThunk<
  IEquipment[],
  { id: number },
  { rejectValue: string }
>(
  "properties/deletePropertyEquipment",
  async ({ id }, { rejectWithValue, getState }) => {
    try {
      await fetchRequest<void>(getEquipmentUrl + id, "DELETE");

      const state = getState() as RootState;
      const equipments = [
        ...(state.properties.filterCounts.equipments || []),
      ].filter((item) => item.id !== id);

      return equipments;
    } catch (error) {
      return rejectWithValue("Error");
    }
  }
);

export const addProperty = createAsyncThunk<
  void,
  AddPropertyRequest,
  { rejectValue: FormError<AddPropertyForm> }
>("properties/addProperty", async (payload, { rejectWithValue }) => {
  try {
    const reqData = createFormData(payload);
    await fetchRequest<void>(
      createPropertyUrl,
      "POST",
      reqData,
      authConfig(true)
    );
  } catch (error) {
    const formError = setFormError(error as IFetchError<AddPropertyRequest>);
    const changedError: FormError<AddPropertyForm> = {
      title: null,
      location: null,
      type: null,
      price: null,
      rooms: null,
      flat: null,
      plotSize: null,
      floor: null,
      distanceFromTheBeach: null,
      equipments: null,
      objectStatus: null,
      description: null,
      locationOnMapLat: null,
      locationOnMapLng: null,
      images: null,
      savedImages: null,
      mainImageIndex: null,
      mainImageId: null,
    };
    if (typeof formError === "object") {
      for (const key in formError) {
        if (key === "locationOnMap") {
          changedError.locationOnMapLat = formError.locationOnMap;
          changedError.locationOnMapLng = formError.locationOnMap;
        } else if (key === "locationId") {
          changedError.location = formError.locationId;
        } else if (key in changedError) {
          changedError[key as keyof typeof changedError] =
            formError[key as keyof typeof formError] || null;
        }
      }
    }

    return rejectWithValue(changedError);
  }
});
export const editProperty = createAsyncThunk<
  void,
  AddPropertyRequest & { id: number },
  { rejectValue: FormError<AddPropertyForm> }
>("properties/editProperty", async (payload, { rejectWithValue }) => {
  try {
    const { id, ...reqPayload } = payload;
    const reqData = createFormData(reqPayload);
    await fetchRequest<void>(
      `${getPropertiesUrl}/${id}`,
      "PUT",
      reqData,
      authConfig(true)
    );
  } catch (error) {
    const formError = setFormError(error as IFetchError<AddPropertyRequest>);
    const changedError: FormError<AddPropertyForm> = {
      title: null,
      location: null,
      type: null,
      price: null,
      rooms: null,
      flat: null,
      plotSize: null,
      floor: null,
      distanceFromTheBeach: null,
      equipments: null,
      objectStatus: null,
      description: null,
      locationOnMapLat: null,
      locationOnMapLng: null,
      images: null,
      savedImages: null,
      mainImageIndex: null,
      mainImageId: null,
    };
    if (typeof formError === "object") {
      for (const key in formError) {
        if (key === "locationOnMap") {
          changedError.locationOnMapLat = formError.locationOnMap;
          changedError.locationOnMapLng = formError.locationOnMap;
        } else if (key === "locationId") {
          changedError.location = formError.locationId;
        } else if (key in changedError) {
          changedError[key as keyof typeof changedError] =
            formError[key as keyof typeof formError] || null;
        }
      }
    }

    return rejectWithValue(changedError);
  }
});

export const deleteProperty = createAsyncThunk<
  { data: IProperty[]; archive: IProperty[] },
  { id: number },
  { rejectValue: string }
>(
  "properties/deleteProperty",
  async ({ id }, { rejectWithValue, getState }) => {
    try {
      await fetchRequest<void>(`${getPropertiesUrl}/${id}`, "DELETE");

      const state = getState() as RootState;

      return {
        data: (state.properties.data || []).filter((item) => item.id !== id),
        archive: (state.properties.archiveData || []).filter(
          (item) => item.id !== id
        ),
      };
    } catch (error) {
      return rejectWithValue("Error");
    }
  }
);

export const archiveProperty = createAsyncThunk<
  IProperty[],
  { id: number },
  { rejectValue: string }
>(
  "properties/archiveProperty",
  async ({ id }, { rejectWithValue, getState }) => {
    try {
      await fetchRequest<void>(`${archivePropertyUrl}/${id}`, "PUT");

      const state = getState() as RootState;

      return (state.properties.data || []).filter((item) => item.id !== id);
    } catch (error) {
      return rejectWithValue("Error");
    }
  }
);

export const unarchiveProperty = createAsyncThunk<
  IProperty[],
  { id: number },
  { rejectValue: string }
>(
  "properties/unarchiveProperty",
  async ({ id }, { rejectWithValue, getState }) => {
    try {
      await fetchRequest<void>(`${archivePropertyUrl}/${id}`, "PUT");

      const state = getState() as RootState;

      return (state.properties.archiveData || []).filter(
        (item) => item.id !== id
      );
    } catch (error) {
      return rejectWithValue("Error");
    }
  }
);

export const deleteAllArchives = createAsyncThunk<
  void,
  void,
  { rejectValue: string }
>("properties/deleteAllArchives", async (_, { rejectWithValue }) => {
  try {
    await fetchRequest<void>(archivePropertyUrl, "DELETE");
  } catch (error) {
    return rejectWithValue("Error");
  }
});

export const propertySlice = createSlice({
  name: "propertySlice",
  initialState,
  reducers: {
    setCreateError(state, { payload }) {
      state.createError = payload;
    },
    setEditError(state, { payload }) {
      state.editError = payload;
    },
    setAddLocationError(state, { payload }) {
      state.addLocationError = payload;
    },
    setAddEquipmentError(state, { payload }) {
      state.addEquipmentError = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPropertyList.pending, (state) => {
        state.getLoading = true;
        state.getError = null;
      })
      .addCase(getPropertyList.fulfilled, (state, { payload }) => {
        state.data = payload.data;
        state.resultCount = payload.resultCount;
        state.getLoading = false;
      })
      .addCase(getPropertyList.rejected, (state, { payload }) => {
        state.getLoading = false;
        if (payload) state.getError = payload;
      })
      .addCase(getArchivedPropertyList.pending, (state) => {
        state.getArchiveLoading = true;
      })
      .addCase(getArchivedPropertyList.fulfilled, (state, { payload }) => {
        state.archiveData = payload.data;
        state.archiveResultCount = payload.resultCount;
        state.getArchiveLoading = false;
      })
      .addCase(getArchivedPropertyList.rejected, (state) => {
        state.getArchiveLoading = false;
      })

      .addCase(getRecomendedPropertyList.fulfilled, (state, { payload }) => {
        state.recommendedProperties = payload;
      })
      .addCase(getRandomPropertyList.fulfilled, (state, { payload }) => {
        state.randomProperties = payload;
      })

      .addCase(getOneProperty.pending, (state) => {
        state.getOneLoading = true;
        state.getOneError = null;
      })
      .addCase(getOneProperty.fulfilled, (state, { payload }) => {
        state.getOneLoading = false;
        state.data = payload;
      })
      .addCase(getOneProperty.rejected, (state, { payload }) => {
        state.getOneLoading = false;
        if (payload) state.getOneError = payload;
      })
      .addCase(addProperty.rejected, (state, { payload }) => {
        state.getOneLoading = false;
        if (payload) state.createError = payload;
      })
      .addCase(addPropertyLocation.rejected, (state, { payload }) => {
        state.getOneLoading = false;
        if (payload) state.addLocationError = payload;
      })
      .addCase(addPropertyEquipment.rejected, (state, { payload }) => {
        state.getOneLoading = false;
        if (payload) state.addEquipmentError = payload;
      })
      .addCase(editProperty.rejected, (state, { payload }) => {
        state.getOneLoading = false;
        if (payload) state.editError = payload;
      })
      .addCase(getPropertyFilters.fulfilled, (state, { payload }) => {
        const { totalCount, isArchive, ...filters } = payload;
        state[isArchive ? "archiveFilterCounts" : "filterCounts"] = filters;
        state[isArchive ? "archiveTotalCount" : "totalCount"] = totalCount;
      })
      .addCase(addPropertyLocation.fulfilled, (state, { payload }) => {
        state.filterCounts.locations = payload;
      })
      .addCase(editPropertyLocation.fulfilled, (state, { payload }) => {
        state.filterCounts.locations = payload;
      })
      .addCase(deletePropertyLocation.fulfilled, (state, { payload }) => {
        state.filterCounts.locations = payload;
      })
      .addCase(addPropertyEquipment.fulfilled, (state, { payload }) => {
        state.filterCounts.equipments = payload;
      })
      .addCase(editPropertyEquipment.fulfilled, (state, { payload }) => {
        state.filterCounts.equipments = payload;
      })
      .addCase(deletePropertyEquipment.fulfilled, (state, { payload }) => {
        state.filterCounts.equipments = payload;
      })
      .addCase(deleteProperty.fulfilled, (state, { payload }) => {
        state.data = payload.data;
        state.archiveData = payload.archive;
        state.archiveResultCount =
          state.archiveResultCount && state.archiveResultCount - 1;
      })
      .addCase(archiveProperty.fulfilled, (state, { payload }) => {
        state.data = payload;
      })
      .addCase(unarchiveProperty.fulfilled, (state, { payload }) => {
        state.archiveData = payload;
        state.archiveResultCount =
          state.archiveResultCount && state.archiveResultCount - 1;
      })
      .addCase(deleteAllArchives.fulfilled, (state) => {
        state.archiveData = [];
        state.archiveResultCount = 0;
      });
  },
});

export const {
  setCreateError,
  setEditError,
  setAddLocationError,
  setAddEquipmentError,
} = propertySlice.actions;

export default propertySlice.reducer;
