import { useEffect, useRef, useState } from "react";
import { crossIcon } from "../../../assets/svg";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import Svg from "../Svg/Svg";

import NewPortalProvider from "../../../providers/NewPortalProvider";
import TransitionProvider, {
  TransitionStyleTypes,
} from "../../../providers/TransitionProvider";
import { closeTooltip } from "../../../store/slices/UISlice";
import styles from "./Tooltip.module.scss";

const Tooltip = () => {
  const dispatch = useAppDispatch();
  const tooltipTxt = useAppSelector((state) => state.ui.tooltipTxt);
  const tooltipshowing = useAppSelector((state) => state.ui.tooltipshowing);
  const tooltipUpdateCount = useAppSelector(
    (state) => state.ui.tooltipUpdateCount
  );
  const timeOutRef = useRef<NodeJS.Timer | null>(null);
  const [isAnimated, setIsAnimated] = useState(false);

  useEffect(() => {
    if (tooltipshowing) {
      if (timeOutRef.current) {
        if (isAnimated) setIsAnimated(false);
        setTimeout(() => {
          setIsAnimated(true);
        }, 100);
        clearTimeout(timeOutRef.current);
        timeOutRef.current = null;
      }
      timeOutRef.current = setTimeout(() => {
        dispatch(closeTooltip());
        timeOutRef.current = null;
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tooltipUpdateCount]);

  const onClose = () => {
    dispatch(closeTooltip());
    if (timeOutRef.current) {
      clearTimeout(timeOutRef.current);
      timeOutRef.current = null;
    }
  };

  return (
    <NewPortalProvider>
      <TransitionProvider
        inProp={tooltipshowing}
        style={TransitionStyleTypes.top}
        className={`${styles.tooltip} ${
          tooltipshowing ? styles.tooltip_active : ""
        }  ${isAnimated ? styles.tooltip_anim : ""}`}
      >
        <span className={styles.tooltip__txt}>{tooltipTxt}</span>
        <button className={styles.tooltip__closeBtn} onClick={onClose}>
          <Svg className={styles.tooltip__crossIcon} id={crossIcon} />
        </button>
      </TransitionProvider>
    </NewPortalProvider>
  );
};

export default Tooltip;
