import styles from "./FilterSelectOption.module.scss";

type Props = {
  name: string;
  count?: number;
};

const FilterSelectOption = ({ name, count }: Props) => {
  const isEmpty = !count;
  return (
    <p
      className={`${styles.filterSelectOption} ${
        isEmpty ? styles.filterSelectOption_disabled : ""
      }`}
    >
      <span>{name}</span>
      <span className={styles.filterSelectOption__count}>({count})</span>
    </p>
  );
};

export default FilterSelectOption;
