import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { IProperty } from "../../../models/IProperty";
import { getOneProperty } from "../../../store/slices/propertiesSlice";
import AdminPropertiesForm from "../AdminPropertiesForm/AdminPropertiesForm";

type Props = {};

const AdminPropertiesEditForm = (props: Props) => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const properties = useAppSelector((state) => state.properties.data);

  const curProperty = (properties || ([] as IProperty[])).find(
    (item) => item.id.toString() === params?.id
  );

  useEffect(() => {
    if (params.id) dispatch(getOneProperty({ id: +params.id }));
  }, [params.id]);

  return (
    <AdminPropertiesForm
      title="Edit property"
      property={curProperty}
      isEdit={true}
    />
  );
};

export default AdminPropertiesEditForm;
