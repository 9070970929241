import { FormEventHandler, useState } from "react";
import { ErrorTypes } from "../../../../constants/errors";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { useFormValue } from "../../../../hooks/useFormValue";
import { ChangePasswordRequest } from "../../../../models/api/changePassword";
import {
  changePassword,
  setChangePassError,
} from "../../../../store/slices/userSlice";
import MainBtn from "../../../layout/MainBtn/MainBtn";
import MainInput from "../../../layout/MainInput/MainInput";

import AccordionProvider from "../../../layout/AccordionProvider/AccordionProvider";
import LoaderPopup from "../../../layout/LoaderPopup/LoaderPopup";
import styles from "./AdminSettingsWrapper.module.scss";

type Props = {};

const errors = {
  currentPassword: {
    [ErrorTypes.emptyField]: "Current password – required field",
    [ErrorTypes.invalidvalue]: "Invalid current password",
  },
  newPassword: {
    [ErrorTypes.emptyField]: "New password – required field",
    [ErrorTypes.invalidvalue]: "Invalid new password",
  },
  confirmPassword: {
    [ErrorTypes.emptyField]: "Password confirmation – required field",
    [ErrorTypes.invalidvalue]: "The passwords do not match",
  },
};

const AdminSettingsWrapper = (props: Props) => {
  const dispatch = useAppDispatch();
  const error = useAppSelector((state) => state.user.changePassError);
  const loading = useAppSelector((state) => state.user.changePassLoading);
  const { formData, onChange, getError } = useFormValue(
    {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    } as ChangePasswordRequest,
    setChangePassError,
    error
  );
  const [successTxtShowing, setSuccessTxtShowing] = useState(false);

  const errorTxt = (key: keyof ChangePasswordRequest) => (
    <AccordionProvider active={!!getError(key)}>
      <p className={`errorTxt ${getError(key) ? "errorTxt_active" : ""}`}>
        {errors[key]?.[
          getError(key) as ErrorTypes.emptyField | ErrorTypes.invalidvalue
        ] || ""}
        &nbsp;
      </p>
    </AccordionProvider>
  );

  const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    setSuccessTxtShowing(false);
    e.preventDefault();
    (async () => {
      try {
        const res = (await dispatch(changePassword(formData))) as {
          error?: unknown;
        };
        if (res.error) throw new Error("error");

        setSuccessTxtShowing(true);
      } catch (error) {}
    })();
  };

  return (
    <section className={styles.adminSettingsWrapper}>
      <h3 className={`titleH3Light ${styles.adminSettingsWrapper__title}`}>
        Settings
      </h3>
      <form onSubmit={onSubmit} className={styles.adminSettingsWrapper__form}>
        <h4 className={styles.adminSettingsWrapper__formTitle}>
          Change password
        </h4>
        <div className={styles.adminSettingsWrapper__field}>
          <label
            htmlFor="curPassword"
            className={styles.adminSettingsWrapper__label}
          >
            Current password
          </label>
          <MainInput
            className={styles.adminSettingsWrapper__input}
            onChange={onChange}
            type="password"
            value={formData.currentPassword}
            name="currentPassword"
            id="currentPassword"
          />
          {errorTxt("currentPassword")}
        </div>
        <div className={styles.adminSettingsWrapper__field}>
          <label
            htmlFor="newPassword"
            className={styles.adminSettingsWrapper__label}
          >
            New password
          </label>
          <MainInput
            className={styles.adminSettingsWrapper__input}
            onChange={onChange}
            type="password"
            value={formData.newPassword}
            name="newPassword"
            id="newPassword"
          />
          {errorTxt("newPassword")}
        </div>
        <div className={styles.adminSettingsWrapper__field}>
          <label
            htmlFor="curPassword"
            className={styles.adminSettingsWrapper__label}
          >
            Confirm new password
          </label>
          <MainInput
            className={styles.adminSettingsWrapper__input}
            onChange={onChange}
            type="password"
            value={formData.confirmPassword}
            name="confirmPassword"
            id="confirmPassword"
          />
          {errorTxt("confirmPassword")}
        </div>
        <p
          className={`${styles.adminSettingsWrapper__successTxt} ${
            successTxtShowing
              ? styles.adminSettingsWrapper__successTxt_active
              : ""
          }`}
        >
          Password successfully updated
        </p>

        <MainBtn className={styles.adminSettingsWrapper__btn}>Save</MainBtn>
      </form>
      <LoaderPopup show={loading} />
    </section>
  );
};

export default AdminSettingsWrapper;
