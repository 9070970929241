import { useRef, useState } from "react";
import { addIcon, editIcon } from "../../../../assets/svg";
import Svg from "../../../layout/Svg/Svg";

import { IFormFunction } from "../../../../models/UI/IFormFunctions";
import styles from "./AdminPropertiesFunctionButtons.module.scss";
import AdminPropertiesFunctionModal from "./AdminPropertiesFunctionModal/AdminPropertiesFunctionModal";

interface Props extends IFormFunction {
  id: number;
}

const AdminPropertiesFunctionButtons = ({
  initialValue,
  add,
  edit,
  clearAddError,
  addError,
  duplicateErrorTxt,
  id,
  editDisabled,
}: Props) => {
  const [addModalOpened, setAddModalOpened] = useState(false);
  const [editModalOpened, setEditModalOpened] = useState(false);
  const addButtonRef = useRef(null);
  const editButtonRef = useRef(null);

  return (
    <div className={styles.adminPropertiesFunctionButtons}>
      <div className={styles.adminPropertiesFunctionButtons__btnWrapper}>
        <button
          ref={addButtonRef}
          type="button"
          onClick={() => setAddModalOpened(true)}
          className={styles.adminPropertiesFunctionButtons__btn}
        >
          <Svg
            id={addIcon}
            className={styles.adminPropertiesFunctionButtons__btnIcon}
          />
        </button>
        <AdminPropertiesFunctionModal
          show={addModalOpened}
          onClose={() => setAddModalOpened(false)}
          onSave={add}
          buttonRef={addButtonRef}
          duplicateErrorTxt={duplicateErrorTxt}
          error={addError}
          clearAddError={clearAddError}
        />
      </div>
      <div className={styles.adminPropertiesFunctionButtons__btnWrapper}>
        <button
          ref={editButtonRef}
          type="button"
          onClick={() => setEditModalOpened(true)}
          disabled={editDisabled}
          className={styles.adminPropertiesFunctionButtons__btn}
        >
          <Svg
            id={editIcon}
            className={styles.adminPropertiesFunctionButtons__btnIcon}
          />
        </button>
        <AdminPropertiesFunctionModal
          show={editModalOpened}
          onClose={() => setEditModalOpened(false)}
          onSave={(value) => edit(id, value)}
          buttonRef={editButtonRef}
          initialValue={initialValue}
        />
      </div>
    </div>
  );
};

export default AdminPropertiesFunctionButtons;
