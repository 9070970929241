import { searchIcon } from "../../../../assets/svg";
import MainBtn from "../../../layout/MainBtn/MainBtn";
import Svg from "../../../layout/Svg/Svg";

import { useNavigate } from "react-router-dom";
import { adminAddPropertiyPagePath } from "../../../../router/path";
import PropertiesFilter from "../../../global/PropertiesFilter/PropertiesFilter";
import PropertiesTable from "../../PropertiesTable/PropertiesTable";
import styles from "./AdminPropeertiesWrapper.module.scss";

type Props = {};

const AdminPropeertiesWrapper = (props: Props) => {
  const navigate = useNavigate();

  return (
    <section className={styles.adminPropeertiesWrapper}>
      <h2 className={`${styles.adminPropeertiesWrapper__title} titleH3Light`}>
        Property
      </h2>
      <MainBtn
        onClick={() => navigate(adminAddPropertiyPagePath)}
        className={styles.adminPropeertiesWrapper__addBtn}
      >
        Add new
      </MainBtn>
      <div className={styles.adminPropeertiesWrapper__searchBlock}>
        <Svg
          id={searchIcon}
          className={styles.adminPropeertiesWrapper__searchIcon}
        />
      </div>
      <PropertiesFilter hideTabs hideTitle disableTranslate />
      <PropertiesTable />
    </section>
  );
};

export default AdminPropeertiesWrapper;
