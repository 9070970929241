import { NavLink } from "react-router-dom";
import { crossIcon, mailIcon, whatsappIcon } from "../../../../assets/svg";
import { mailAddress, phoneNumber } from "../../../../constants/contacts";
import { menuItems } from "../../../../constants/menuItems";
import Svg from "../../../layout/Svg/Svg";

import TransitionProvider, {
  TransitionStyleTypes,
} from "../../../../providers/TransitionProvider";
import { scrollTop } from "../../../../utils/functions/scrollTop";
import Backdrop from "../../../layout/Backdrop/Backdrop";
import styles from "./Sidebar.module.scss";

type Props = {
  show: boolean;
  onClose: () => void;
};

const Sidebar = ({ show, onClose }: Props) => {
  const setActiveNavLinkClass = () => {
    return ({ isActive }: { isActive: boolean }) =>
      isActive
        ? `${styles.sidebar__menuItem} ${styles.sidebar__menuItem_active}`
        : styles.sidebar__menuItem;
  };

  const activeNavLinks = setActiveNavLinkClass();

  return (
    <>
      <Backdrop inProp={show} onClose={onClose} />
      <TransitionProvider
        inProp={show}
        style={TransitionStyleTypes.left}
        className={`scrollbarDef ${styles.sidebar}`}
      >
        <div className={styles.sidebar__topBlock}>
          <button className={styles.sidebar__crossBtn} onClick={onClose}>
            <Svg id={crossIcon} className={styles.sidebar__crossIcon} />
          </button>
        </div>
        <nav className={styles.sidebar__menu}>
          <ul className={styles.sidebar__menuList}>
            {menuItems.map((item, index) => (
              <li className={styles.sidebar__menuListItem} key={item.link}>
                <NavLink
                  onClick={() => {
                    onClose();
                    scrollTop();
                  }}
                  to={item.link}
                  className={activeNavLinks}
                >
                  {item.text}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
        <div className={styles.sidebar__contactBlock}>
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://wa.me/${phoneNumber.replaceAll(" ", "")}`}
            className={styles.sidebar__contactLink}
          >
            <Svg id={whatsappIcon} className={styles.sidebar__contactIcon} />
            <span className={styles.sidebar__contactTxt}>{phoneNumber}</span>
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href={`mailto:${mailAddress}`}
            className={styles.sidebar__contactLink}
          >
            <Svg id={mailIcon} className={styles.sidebar__contactIcon} />
            <span className={styles.sidebar__contactTxt}>{mailAddress}</span>
          </a>
        </div>
      </TransitionProvider>
    </>
  );
};

export default Sidebar;
