import ImageWebp from "../../layout/ImageWebp/ImageWebp";
import Svg from "../../layout/Svg/Svg";

import { Link } from "react-router-dom";
import { logoImage, logoWebpImage } from "../../../assets/images";
import { fbIcon, mailIcon, whatsappIcon } from "../../../assets/svg";
import {
  facebookUrl,
  mailAddress,
  phoneNumber,
} from "../../../constants/contacts";
import { cookiePolicyPagePath, homePagePath } from "../../../router/path";
import { scrollTop } from "../../../utils/functions/scrollTop";
import styles from "./Footer.module.scss";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footer__main}>
        <div
          className={`${styles.footer__infoBlock} ${styles.footer__mainCol}`}
        >
          <Link to={homePagePath} onClick={scrollTop}>
            <ImageWebp
              src={logoImage}
              srcSet={logoWebpImage}
              alt="Reality Bulgaria"
              className={styles.footer__logo}
            />
          </Link>
          <h6 className={styles.footer__infoTxt}>
            Zajmujemy się sprzedażą nieruchomości w Bułgarii.
          </h6>
        </div>
        <div
          className={`${styles.footer__securityBlock} ${styles.footer__mainCol}`}
        >
          <h5 className={styles.footer__title}>Ochrona</h5>
          <Link
            onClick={scrollTop}
            to={cookiePolicyPagePath}
            className={`${styles.footer__linkTxt} ${styles.footer__securitylinkTxt}`}
          >
            Polityka prywatności
          </Link>
        </div>
        <div className={`${styles.footer__contacts} ${styles.footer__mainCol}`}>
          <h5 className={styles.footer__title}>Łączność</h5>
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://wa.me/${phoneNumber.replaceAll(" ", "")}`}
            className={styles.footer__contactLink}
          >
            <Svg id={whatsappIcon} className={styles.footer__iconImage} />
            <span className={styles.footer__linkTxt}>{phoneNumber}</span>
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href={`mailto:${mailAddress}`}
            className={styles.footer__contactLink}
          >
            <Svg id={mailIcon} className={styles.footer__iconImage} />
            <span className={styles.footer__linkTxt}>{mailAddress}</span>
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href={facebookUrl}
            className={styles.footer__contactLink}
          >
            <Svg id={fbIcon} className={styles.footer__iconImage} />
            <span className={styles.footer__linkTxt}>Subskrybuj</span>
          </a>
        </div>
      </div>
      <div className={styles.footer__copirightBlock}>
        <h6 className={styles.footer__copyrightTxt}>
          © Prawa autorskie {new Date().getFullYear()} Realitybulgaria.{" "}
          <span>Wszelkie prawa zastrzeżone.</span>
        </h6>
      </div>
    </footer>
  );
};

export default Footer;
