export const arrowDownIcon = "arrowDown";
export const burgerIcon = "burger";
export const crossIcon = "cross";
export const envelopIcon = "envelop";
export const phoneIcon = "phone";
export const faqIcon = "faq";
export const whatsappIcon = "whatsapp";
export const mailIcon = "mail";
export const fbIcon = "fb";
export const experienceIcon = "experience";
export const adviceIcon = "advice";
export const installmentIcon = "installment";
export const arrowLinkIcon = "arrowLink";
export const houseIcon = "house";
export const flatIcon = "flat";
export const buisnessCenterIcon = "buisnessCenter";
export const buildingIcon = "building";
export const locationIcon = "location";
export const sliderArrowRightIcon = "sliderArrowRight";
export const sliderArrowLeftIcon = "sliderArrowLeft";
export const envelopeTransparentIcon = "envelopeTransparent";
export const errorIcon = "error";

// admin panel
export const primaryIcon = "primary";
export const archiveIcon = "archive";
export const unArchiveIcon = "unArchive";
export const deleteIcon = "delete";
export const editIcon = "edit";
export const logoutIcon = "logout";
export const searchIcon = "search";
export const addIcon = "add";
export const leftBurgerIcon = "leftBurger";
export const recomendationIcon = "recomendation";
