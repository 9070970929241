import { OpenStreetMapProvider } from "leaflet-geosearch";

export const useMapSearch = () => {
  const provider = new OpenStreetMapProvider();

  const searchLocation = async (
    query: string,
    clb: (loc: { x: number; y: number }) => void
  ) => {
    const results = await provider.search({ query });
    if (results.length > 0) {
      const { x, y } = results[0];

      clb({ x, y });
    }
  };

  return searchLocation;
};
