import List from "../../layout/List/List";
import styles from "./ForBuyersList.module.scss";

const cards = [
  {
    title: "Wybór nieruchomości",
    list: [
      "Wybierasz obiekt lub kilka ofert",
      "Wyślemy Ci wszystkie informacje o wybranej przez Ciebie nieruchomości, film, możemy zorganizować wideowycieczkę online, ale oczywiście także wycieczkę indywidualną.",
    ],
  },
  {
    title: "Organizacja wycieczki indywidualnej",
    list: [
      <>
      <strong>Usługi bezpłatne:</strong>
      </>,
      [
        "Odbiór z lotniska dla Ciebie i maksymalnie 4 członków rodziny.",
        "Transport do wszystkich zarezerwowanych miejsc",
        "Powrót na lotnisko",
        "W przypadku płatności zadatku za rezerwację nieruchomości, którą zamierzasz kupić od naszej agencji, zakwaterujemy Cię w naszych apartamentach ZA DARMO na wymagany okres czasu",
      ],
    ],
  },
  {
    title: "Wybor kryteriów",
    list: [
      <>
        Jeśli nie możesz wybrać żadnej z podanych opcji, wyślij zadzwon do nas lub napisz email podając numer referencyjny oferty, abyśmy wiedzieli, co Cię interesuje. Określ również kryteria, które możemy zastosować, aby znaleźć odpowiednią nieruchomość:
      </>,
      [
        "Wielkość apartamentu (1 + kuchnia, 2 + kuchnia, 3 + kuchnia lub więcej, dom, studio, hotel).",
        "Odległość od morza, położenie.",
        "Miasto, region",
        "Limit ceny, ile chcesz zainwestować",
      ],
      <>
        Możliwość zakupu na raty. Jeśli chcesz kupić nieruchomość na raty, musisz nas o tym powiadomić.
      </>,
    ],
  },
  {
    title: "Dokonywanie rezerwacji",
    list: [
      "Jeśli zdecydujesz się na zakup nieruchomości, wyślemy Ci umowę rezerwacyjną, w której zarezerwujesz wybraną nieruchomość.",
      "Należy uiścić tzw. opłatę rezerwacyjną w wysokości 2 000 EUR, która następnie zostanie odliczona od całkowitej ceny zakupu.",
      "W przypadku odstąpienia od umowy z jakiegokolwiek powodu, jesteś zobowiązany do zwrócenia agentowi kosztów zakwaterowania, transferu na lotnisko, kosztów paliwa wydanych na oglądanie wybranego przez Ciebie apartamentu oraz czasu spędzonego przez agenta nieruchomości na wybranych przez Ciebie obiektach.",
    ],
  },
  {
    title: "Zawarcie umowy i płatność",
    list: [
      "Wystawiane jest pełnomocnictwo (umowa kupna-sprzedaży), w którym zobowiązujesz się do zapłaty pozostałej części ceny zakupu lub, w przypadku rat, ustalonej zaliczki.",
      "Następnie jest czas na przygotowanie dokumentów/pełnomocnictwa, umowy sprzedaży itp.",
      "Następnie w ustalonym terminie spotykamy się u notariusza, zawieramy umowę i przekazujemy Ci mieszkanie",
    ],
  },
  {
    title: "Koszty prawne",
    list: [
      "Ceny są podane poniżej i nie są wliczone w cenę zakupu:",
      [
        "Usługi prawne 900-1100 euro, w zależności od wielkości (bezpośrednia reprezentacja na podstawie pełnomocnictwa)",
        "Oficjalne tłumaczenia o wartości 650-950 EUR na język polski z bezpośrednim przedstawicielstwem pełnomocnika (tzn. nie trzeba lecieć do kraju, aby podpisać umowę kupna-sprzedaży)",
        "Poświadczenie notarialne €850 - €1100",
        "Czyli w sumie około 6-7% ceny zakupu.",
      ],
    ],
  },
  {
    title: "Koszty dodatkowe",
    list: [
      "Opłata za utrzymanie kompleksu hotelowego (jeśli dotyczy)",
      "Przepisanie rachunkow. energia i woda.",
      "Roczny ustawowy podatek od nieruchomości wynosi maksymalnie 100-300 euro, w zależności od lokalizacji zakupionej nieruchomości.",
    ],
  },
  {
    title: "Zakup na raty",
    list: [
      "W przypadku zakupu na raty przeniesienie własności następuje po zapłaceniu ostatniej raty i wtedy również płacisz podatek od nabycia nieruchomości.",
      "Nie ma możliwości zakupu na raty, aby od razu stać się właścicielem nieruchomości.",
    ],
  },
];

const ForBuyersList = () => {
  return (
    <div className={`container ${styles.forBuyersList}`}>   
      <h2 className={`${styles.forBuyersList__title} titleH2`}>
          Procedura wyboru i zakupu nieruchomości w Bułgarii
        </h2>      
      <div className={styles.forBuyersList__main}>
        {cards.map((item, index) => (
          <div className={styles.forBuyersList__item} key={index}>
            <div className={styles.forBuyersList__itemIndex}>{index + 1}</div>
            <List title={item.title} list={item.list} isTitleBlack={true} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ForBuyersList;
