import React, { ReactNode } from 'react'

import styles from "./AccordionProvider.module.scss"

type Props = {
    children: ReactNode,
    className?: string,
    innerClass?: string,
    active: boolean,
}

const AccordionProvider = ({children,active,className,innerClass}: Props) => {
  return (
    <div className={`${styles.accordionProvider} ${active ? styles.accordionProvider_active : ""} ${className || ""}`}>
        <div className={`${styles.accordionProvider__inner} ${innerClass || ""}`}>{children}</div>
    </div>
  )
}

export default AccordionProvider