import { useNavigate } from "react-router-dom";
import { homePagePath } from "../../../router/path";
import MainBtn from "../../layout/MainBtn/MainBtn";

import { errorImage, errorWebpImage } from "../../../assets/images";
import ImageWebp from "../../layout/ImageWebp/ImageWebp";
import styles from "./ErrorWrapper.module.scss";

const ErrorWrapper = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="headerDistance"></div>
      <section className={styles.errorWrapper}>
        <div className={styles.errorWrapper__main}>
          <h1 className={styles.errorWrapper__title}>404</h1>
          <p
            className={`contentTxt textCenter ${styles.errorWrapper__contentTxt}`}
          >
            Strona, której szukasz, nie została znaleziona. <br />
            Być może została przeniesiona lub usunięta.
          </p>
          <MainBtn onClick={() => navigate(homePagePath)}>
            Вернуться на Главную
          </MainBtn>
        </div>
        <ImageWebp
          srcSet={errorWebpImage}
          src={errorImage}
          alt="error"
          className={styles.errorWrapper__img}
        />
      </section>
    </>
  );
};

export default ErrorWrapper;
