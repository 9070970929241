import React, {FC, ReactElement} from 'react'
import { Navigate } from 'react-router-dom';
import {lsProps} from "../utils/lsProps";
import {getLSItem} from "../utils/functions/localStorage";
import { adminLoginPagePath, adminPropertiesPagePath } from './path';

interface PrivateRouteProps {
   element: ReactElement<any, any>,
   noAuth?: boolean,
}

const PrivateRoute:FC<PrivateRouteProps> = ({element,noAuth}) => {
   const token = getLSItem(lsProps.token)
   const isAuthenticated = !token
   const statement = noAuth ?
       !isAuthenticated :
       isAuthenticated
   let navigateTo = adminLoginPagePath

   if(!isAuthenticated) {
      navigateTo =  adminPropertiesPagePath
   } else {
      if(noAuth) {
         navigateTo = adminPropertiesPagePath
      }
   }

   return (
       statement ? <Navigate to={navigateTo} replace={true} /> : element
   )
}

export default PrivateRoute