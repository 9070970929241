import styles from "./Loader.module.scss";

type Props = {
  className?: string;
};

const Loader = ({className }: Props) => {
  return (
    <div className={`${styles.loader} ${className || ""}`} >
      <div className={styles.loader__wrapper}>
        <div className={styles.loader__inner}></div>
        <div className={styles.loader__anim}></div>
      </div>
      <h5 className={styles.loader__txt}>Loading...</h5>
    </div>
  );
};

export default Loader;
