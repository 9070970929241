import ImageWebp from "../../layout/ImageWebp/ImageWebp";
import Svg from "../../layout/Svg/Svg";

import {
  homeGallery1Image,
  homeGallery2Image,
  homeGallery3Image,
  homeGallery4Image,
  homeGallery5Image,
  homeGallery6Image,
  homeGallery7Image,
  homeGalleryWebp1Image,
  homeGalleryWebp2Image,
  homeGalleryWebp3Image,
  homeGalleryWebp4Image,
  homeGalleryWebp5Image,
  homeGalleryWebp6Image,
  homeGalleryWebp7Image,
} from "../../../assets/images";
import {
  adviceIcon,
  experienceIcon,
  installmentIcon,
} from "../../../assets/svg";

import styles from "./HomeAddvantages.module.scss";

const galleryImages = [
  [homeGallery1Image, homeGalleryWebp1Image],
  [homeGallery2Image, homeGalleryWebp2Image],
  [homeGallery3Image, homeGalleryWebp3Image],
  [homeGallery4Image, homeGalleryWebp4Image],
  [homeGallery5Image, homeGalleryWebp5Image],
  [homeGallery6Image, homeGalleryWebp6Image],
  [homeGallery7Image, homeGalleryWebp7Image],
];

const HomeAddvantages = () => {
  return (
    <section className={styles.homeAddvantages}>     
        <h2 className={`${styles.homeAddvantages__title} titleH2`}>
          Dlaczego warto wybrać nieruchomość z nami?
        </h2>
        <div className={styles.homeAddvantages__main}>
          <div className={styles.homeAddvantages__item}>
            <div className={styles.homeAddvantages__itemIconWrapper}>
              <Svg
                id={experienceIcon}
                className={`${styles.homeAddvantages__itemIcon} ${styles.homeAddvantages__itemIcon_experience}`}
              />
            </div>
            <h4 className={styles.homeAddvantages__itemTitle}>
              Wieloletnie doświadczenie
            </h4>
            <p className={styles.homeAddvantages__itemDescription}>
              Od kilku lat zajmujemy się sprzedażą i wynajmem nieruchomości w
              Bułgarii. W tym czasie zbudowaliśmy solidne podstawy zarówno w
              Czechach, jak i w Bułgarii. Pełna obsługa posprzedażowa jest dla
              nas oczywistością!!!
            </p>
          </div>
          <div className={styles.homeAddvantages__item}>
            <div className={styles.homeAddvantages__itemIconWrapper}>
              <Svg
                id={adviceIcon}
                className={`${styles.homeAddvantages__itemIcon} ${styles.homeAddvantages__itemIcon_advice}`}
              />
            </div>
            <h4 className={styles.homeAddvantages__itemTitle}>Porada</h4>
            <p className={styles.homeAddvantages__itemDescription}>
              Chętnie pomożemy w każdej sytuacji. Niezależnie od tego, czy masz
              problem z wynajęciem mieszkania, kupnem lub sprzedażą,
              wypożyczeniem samochodu czy jakimkolwiek innym problemem. Nie
              znamy słowa NIE lub NIE MOŻNA!
            </p>
          </div>
          <div className={styles.homeAddvantages__item}>
            <div className={styles.homeAddvantages__itemIconWrapper}>
              <Svg
                id={installmentIcon}
                className={`${styles.homeAddvantages__itemIcon} ${styles.homeAddvantages__itemIcon_installment}`}
              />
            </div>
            <h4 className={styles.homeAddvantages__itemTitle}>
              Na raty bez rejestrów
            </h4>
            <p className={styles.homeAddvantages__itemDescription}>
              Oferujemy sprzedaż mieszkań każdemu bez patrzenia w rejestry
              bankowe i pozabankowe. Nie przejmujemy się zupełnie niczym.
              Wystarczy nam jeden dowód osobisty i zaliczka w wysokości 10% ceny
              zakupu nieruchomości.
            </p>
          </div>
        </div>      
      <div className={styles.homeAddvantages__gallery}>
        {galleryImages.map((item, index) => (
          <div
            className={styles.homeAddvantages__galleryImgWrapper}
            key={index}
          >
            <ImageWebp
              src={item[0]}
              srcSet={item[1]}
              alt="gallery"
              useLoader
              className={styles.homeAddvantages__galleryImg}
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default HomeAddvantages;
