import Loader from "../Loader/Loader";
import styles from "./DataLoader.module.scss";

type Props = {
  isLoaded: boolean;
  show: boolean;
  text?: string;
};

const DataLoader = ({ isLoaded, show, text }: Props) => {
  return (
    <div
      className={`${styles.dataLoader} ${show ? styles.dataLoader_active : ""}`}
    >
      <div className={styles.dataLoader__inner}>
        {isLoaded ? (
          <h5 className={styles.dataLoader__emptyTxt}>
            {text || "Nic nie znaleziono"}
          </h5>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default DataLoader;
