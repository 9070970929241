import TransitionProvider, {
  TransitionStyleTypes,
} from "../../../providers/TransitionProvider";
import Backdrop from "../../layout/Backdrop/Backdrop";
import ImageWebp from "../../layout/ImageWebp/ImageWebp";
import Svg from "../../layout/Svg/Svg";

import { faqImage, faqWebpImage } from "../../../assets/images";
import { crossIcon } from "../../../assets/svg";
import styles from "./FaqModal.module.scss";

type Props = {
  show: boolean;
  onClose: () => void;
};

const FaqModal = ({ show, onClose }: Props) => {
  return (
    <>
      <Backdrop onClose={onClose} inProp={show} />
      <TransitionProvider
        inProp={show}
        style={TransitionStyleTypes.opacity}
        className={styles.faqModal}
      >
        <div className={` ${styles.faqModal__texts}`}>
          <h3 className={`${styles.faqModal__title} primaryTxt`}>
            Często zadawane pytania
          </h3>
          <div className={`scrollbarDef ${styles.faqModal__txtWrapper}`}>            
              <div className={styles.faqModal__txt}>
                <strong>
                  Gdzie powinienem się zatrzymać w przypadku poróży?
                </strong>
                <p>
                  Zapewniamy nocleg dla 2-4 osób GRATIS zapoznając się z naszymi
                  ofertami na 2-3 dni dla klientów, którzy wpłacili zaliczkę
                  przy zakupie apartamentu.
                </p>
                <strong>Co powiesz na transfer lotniskowy?</strong>
                <p>
                  Zapewniamy transport z lotniska i z powrotem na lotnisko w
                  celu zapoznania się z naszymi ofertami dla klientów, którzy
                  wpłacili zaliczkę przy zakupie apartamentu.
                </p>
                <strong>A co z ratami?</strong>
                <p>
                  Zapewniamy sprzedaż nieruchomości na raty bez zaglądania do
                  rejestrów.
                </p>
                <p>
                  Po zakupie nieruchomości pomożemy Ci również w dalszym
                  wynajmie lub odsprzedaży w przyszłości.
                </p>
                <strong>Jak przebiega Twój pobyt w Bułgarii?</strong>
                <p>
                  Zorganizujemy dla Ciebie stałe lub czasowe zamieszkanie w
                  Twoim mieszkaniu.
                </p>

                <strong>A co z rzeczami związanymi z nieruchomościami?</strong>

                <p>
                  Pomożemy Ci w zarządzaniu nieruchomością, wynajmie i usługach
                  pokrewnych (sprzątanie, zmiana, podlewanie kwiatów i wiele
                  innych). pobyt stały lub czasowy w Twoim apartamencie.
                </p>
                <strong>Chcę kupić samochód w Bułgarii.</strong>
                <p>
                  Pomożemy Ci nie tylko w wyborze, ale także w przekazaniu Ci
                  samochodu w Bułgarii.
                </p>
                <strong>Masz inne problemy.</strong>
                <p>Pomożemy z nimi również.</p>
              </div>            
          </div>
        </div>
        <div className={styles.faqModal__imgWrapper}>
          <ImageWebp
            pictureClass={styles.faqModal__pictureImg}
            src={faqImage}
            srcSet={faqWebpImage}
            alt="fAQ"
            useLoader
            className={styles.faqModal__img}
          />
        </div>
        <button className={styles.faqModal__crossBtn} onClick={onClose}>
          <Svg id={crossIcon} className={styles.faqModal__crossIcon} />
        </button>
      </TransitionProvider>
    </>
  );
};

export default FaqModal;
