import ContactWrapper from "../components/ContactPage/ContactWrapper/ContactWrapper"
import Head from '../components/global/Head/Head'

const ContactPage = () => {
  <Head
        keyword={"Kontakt Reality Bulgaria,  agencja nieruchomości, WhatsApp: +48 518 160 166, zjrealitybulgaria@gmail.com, bezpłatne konsultacje, zakup nieruchomości"}
        title={
          "Kontakt | WhatsApp: +48 518 160 166 | zjrealitybulgaria@gmail.com"
        }
        ogTitle={
          "Kontakt | WhatsApp: +48 518 160 166 | zjrealitybulgaria@gmail.com"
        }
        description={
          "Skontaktuj się z naszą agencją Reality Bulgaria, aby uzyskać bezpłatną konsultację i profesjonalną pomoc w zakupie nieruchomości."
        }
        ogDescription={
          "Skontaktuj się z naszą agencją Reality Bulgaria, aby uzyskać bezpłatną konsultację i profesjonalną pomoc w zakupie nieruchomości."
        }
      />
  return <ContactWrapper/>;

  
  
};

export default ContactPage;
