import { ReactElement } from "react";
import { Navigate } from "react-router-dom";
import AboutPage from "../pages/AboutPage";
import AdminArchivePage from "../pages/Admin/AdminArchive/AdminArchivePage";
import AdminEditArchivePage from "../pages/Admin/AdminArchive/AdminEditArchivePage";
import AdminLoginPage from "../pages/Admin/AdminLoginPage";
import AdminAddPropertiesPage from "../pages/Admin/AdminProperties/AdminAddPropertiesPage";
import AdminEditPropertiesPage from "../pages/Admin/AdminProperties/AdminEditPropertiesPage";
import AdminPropertiesPage from "../pages/Admin/AdminProperties/AdminPropertiesPage";
import AdminSettingsPage from "../pages/Admin/AdminSettingsPage";
import ForgotPasswordPage from "../pages/Admin/ForgotPasswordPage";
import ResetPasswordPage from "../pages/Admin/ResetPasswordPage";
import ContactPage from "../pages/ContactPage";
import CookiePrivacyPage from "../pages/CookiePrivacyPage";
import ErrorPage from "../pages/ErrorPage";
import ForBuyerPage from "../pages/ForBuyerPage";
import HomePage from "../pages/HomePage";
import InstallmentPlanPage from "../pages/InstallmentPlanPage";
import OurServicesPage from "../pages/OurServicesPage";
import PropertiesPage from "../pages/PropertiesPage";
import SinglePropertyPage from "../pages/SinglePropertyPage";

import PrivateRoute from "./PrivateRoute";

export const homePagePath = "/";
export const propertiesPagePath = "/properties";
export const aboutPagePath = "/about";
export const contactPagePath = "/contact";
export const forBuyerPagePath = "/forBuyer";
export const installmentPagePath = "/installment";
export const ourServicesPagePath = "/ourServices";
export const cookiePolicyPagePath = "/cookiePolicy";

export const errorPagePath = "/error";

// admin panel
export const adminPagePath = "/admin";

export const adminLoginPagePath = adminPagePath + "/login";
export const adminForgotPasswordPagePath = adminPagePath + "/forgotPassword";
export const adminResetPasswordPagePath = adminPagePath + "/resetPassword";

export const adminPropertiesPagePath = adminPagePath + "/properties";
export const adminEditPropertiyPagePath = adminPropertiesPagePath + "/edit";
export const adminAddPropertiyPagePath = adminPropertiesPagePath + "/add";
export const adminArchivePagePath = adminPagePath + "/archive";
export const adminEditArchivePagePath = adminArchivePagePath + "/edit";
export const adminSettingsPagePath = adminPagePath + "/settings";

interface IRoute {
  path: string;
  component: ReactElement<any, any>;
  children?: Omit<IRoute, "children">[];
}

export const routes: IRoute[] = [
  {
    path: homePagePath,
    component: <HomePage />,
  },
  {
    path: propertiesPagePath,
    component: <PropertiesPage />,
    children: [
      {
        path: ":id",
        component: <SinglePropertyPage />,
      },
    ],
  },
  {
    path: aboutPagePath,
    component: <AboutPage />,
  },
  {
    path: contactPagePath,
    component: <ContactPage />,
  },
  {
    path: forBuyerPagePath,
    component: <ForBuyerPage />,
  },
  {
    path: installmentPagePath,
    component: <InstallmentPlanPage />,
  },
  {
    path: ourServicesPagePath,
    component: <OurServicesPage />,
  },  
  {
    path: cookiePolicyPagePath,
    component: <CookiePrivacyPage />,
  },

  //   admin panel
  {
    path: adminPagePath,
    component: (
      <PrivateRoute
        element={<Navigate to={adminPropertiesPagePath} replace={true} />}
      />
    ),
  },
  {
    path: adminLoginPagePath,
    component: <PrivateRoute element={<AdminLoginPage />} noAuth={true} />,
  },
  {
    path: adminForgotPasswordPagePath,
    component: <PrivateRoute element={<ForgotPasswordPage />} noAuth={true} />,
  },
  {
    path: adminResetPasswordPagePath,
    component: (
      <PrivateRoute
        element={<Navigate to={adminLoginPagePath} replace={true} />}
      />
    ),
    children: [
      {
        path: ":token",
        component: (
          <PrivateRoute element={<ResetPasswordPage />} noAuth={true} />
        ),
      },
    ],
  },
  {
    path: adminPropertiesPagePath,
    component: <PrivateRoute element={<AdminPropertiesPage />} />,
  },
  {
    path: adminEditPropertiyPagePath,
    component: (
      <PrivateRoute
        element={<Navigate to={adminPropertiesPagePath} replace={true} />}
      />
    ),
    children: [
      {
        path: ":id",
        component: <PrivateRoute element={<AdminEditPropertiesPage />} />,
      },
    ],
  },
  {
    path: adminAddPropertiyPagePath,
    component: <PrivateRoute element={<AdminAddPropertiesPage />} />,
  },
  {
    path: adminArchivePagePath,
    component: <PrivateRoute element={<AdminArchivePage />} />,
  },
  {
    path: adminEditArchivePagePath,
    component: (
      <PrivateRoute
        element={<Navigate to={adminArchivePagePath} replace={true} />}
      />
    ),
    children: [
      {
        path: ":id",
        component: <PrivateRoute element={<AdminEditArchivePage />} />,
      },
    ],
  },
  {
    path: adminSettingsPagePath,
    component: <PrivateRoute element={<AdminSettingsPage />} />,
  },
  {
    path: errorPagePath,
    component: <ErrorPage />,
  },
  {
    path: "*",
    component: <Navigate to={errorPagePath} replace={true} />,
  },
];
