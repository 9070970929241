import React from 'react'

type Props = {}

const AdminEditArchivePage = (props: Props) => {
  return (
    <div>AdminEditArchivePage</div>
  )
}

export default AdminEditArchivePage