import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { getPropertyFilters } from "../../../store/slices/propertiesSlice";

import PropertyTypesTabBar from "../../global/PropertyTypesTabBar/PropertyTypesTabBar";
import MainBtn from "../../layout/MainBtn/MainBtn";

import { EPropertyTypes } from "../../../models/IProperty";
import { propertiesPagePath } from "../../../router/path";
import Head from "../../global/Head/Head";
import styles from "./SinglePropertyHeader.module.scss";

const SinglePropertyHeader = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const data = useAppSelector((state) => state.properties.data);

  const curItem = params.id
    ? data?.find((item) => item.id === +(params.id as string))
    : null;

  useEffect(() => {
    dispatch(getPropertyFilters());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (val: EPropertyTypes | "") => {
    navigate(`${propertiesPagePath}${val ? `?type=${val}` : ""}`);
  };

  return (
    <>
      <Head
        keyword={
          "nowe budownictwo, balkon, klimatyzacja, basen, sauna, kominek, winda, miejsce, parkingowe, parking podziemny, wypożyczalnia nart, telewizja kablowa, garaż, blisko, plaży, widok na morze, widok na góry, turystyczna lokalizacja"
        }
        title={curItem?.title || ""}
        ogTitle={curItem?.title || ""}
        description={
          "Oferujemy wiele różnych nieruchomości z balkonem, klimatyzacją, windą, parkingiem podziemnym, basenem, przy plaży, z widokiem na morze lub góry."
        }
        ogDescription={
          "Oferujemy wiele różnych nieruchomości z balkonem, klimatyzacją, windą, parkingiem podziemnym, basenem, przy plaży, z widokiem na morze lub góry."
        }
      />
      <header className={styles.singlePropertyHeader}>
        <PropertyTypesTabBar onChange={onChange} />
        <MainBtn
          onClick={() => navigate(propertiesPagePath)}
          className={styles.singlePropertyHeader__btn}
        >
          Rozpocznij nowe wyszukiwanie
        </MainBtn>
      </header>
    </>
  );
};

export default SinglePropertyHeader;
