import { combineReducers, configureStore } from "@reduxjs/toolkit";
import PropertiesReducer from "./slices/propertiesSlice";
import UIReducer from "./slices/UISlice";
import UserReducer from "./slices/userSlice";

const rootReducer = combineReducers({
  properties: PropertiesReducer,
  user: UserReducer,
  ui: UIReducer,
});

export const store = () => {
  return configureStore({
    reducer: rootReducer,
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof store>;
export type AppDispatch = AppStore["dispatch"];
