import {
  useDropdownSelect,
  UseDropdownSelectProps,
} from "../../../hooks/useDropdownSelect";

import TransitionProvider, {
  TransitionStyleTypes,
} from "../../../providers/TransitionProvider";
import Checkbox from "../Checkbox/Checkbox";
import Svg from "../Svg/Svg";

import { FC, Fragment, HTMLAttributes } from "react";
import { arrowDownIcon, crossIcon } from "../../../assets/svg";
import { ISelectValue } from "../../../models/UI/ISelectValue";

import styles from "./Select.module.scss";

export interface SelectProps extends UseDropdownSelectProps {
  className?: string;
  attributes?: HTMLAttributes<any>;
  isTransparent?: boolean;
  isInvalid?: boolean;
  useClearButton?: boolean;
  disableTranslate?: boolean;
}

const Select: FC<SelectProps> = ({
  name,
  valuesArr,
  onChange,
  className,
  attributes,
  disableState,
  openState,
  selectedValueProp,
  selectedChild,
  isMultiSelect,
  isWithInput,
  isTransparent,
  isInvalid,
  useClearButton,
  disableTranslate,
}) => {
  
  const {
    selectedRef,
    dropDownOpened,
    disabled,
    onToggleDropdowns,
    onChangeSelectValues,
    btnText,
    selectedValue,
    sortedContentArr,
    searchInputRef,
    onSearch,
    searchValue,
    clearValue,
  } = useDropdownSelect({
    onChange,
    selectedValueProp,
    valuesArr,
    selectedChild,
    disableState,
    openState,
    name,
    isMultiSelect,
    isWithInput,
  });

  const emptyFieldOption: ISelectValue[] = [
    {
      value: "",
      name: disableTranslate ? "No options" : "Brak opcji",
      disabled: true,
    },
  ];

  return (
    <div
      ref={selectedRef}
      className={`${styles["select"]} ${className ? className : ""} ${
        disabled ? styles["select_disabled"] : ""
      } ${isTransparent ? styles["select_transparent"] : ""}`}
    >
      <div
        className={`${styles["select__dropDownBtn"]} ${
          isInvalid ? styles["select__dropDownBtn_invalid"] : ""
        }`}
        onClick={onToggleDropdowns}
      >
        <div className={styles["select__dropDownBtnContent"]}>
          <input
            type="text"
            className={`${styles["select__selectDropdownBtnInput"]} ${
              !isWithInput || document.activeElement !== searchInputRef.current
                ? styles["select__selectDropdownBtnInput_hidden"]
                : ""
            }`}
            ref={searchInputRef}
            value={searchValue}
            onChange={onSearch}
          />
            <span className={styles["select__selectDropdownBtnText"]}>          
              {btnText}          
            </span>

          {!disabled &&
            (!useClearButton || (useClearButton && !selectedValue)) && (
              <Svg
                className={`${styles["select__arrowDownIcon"]} ${
                  dropDownOpened ? styles["select__arrowDownIcon_active"] : ""
                }`}
                id={arrowDownIcon}
              />
            )}
          {useClearButton && selectedValue && (
            <button className={styles["select__crossBtn"]} onClick={clearValue}>
              <Svg id={crossIcon} className={styles["select__crossIcon"]} />
            </button>
          )}
        </div>
      </div>
      <TransitionProvider
        inProp={dropDownOpened}
        style={TransitionStyleTypes.opacity}
        duration={100}
        className={styles["select__dropdownContent"]}
      >
        <div
          className={`${styles["select__dropdownContentItems"]} scrollbarDef`}
        >
          {(sortedContentArr.length ? sortedContentArr : emptyFieldOption).map(
            (item, index) => {
              const itemContent = item.item || item.name;
              const itemValue = item.value;

              return (
                <button
                  type="button"
                  {...attributes}
                  key={index}
                  disabled={item.disabled}
                  className={styles["select__dropdownItem"]}
                  onClick={() => onChangeSelectValues(itemValue)}
                >
                  {isMultiSelect ? (
                    <>
                      <Checkbox
                        value={itemValue}
                        name={"select"}
                        checked={selectedValue.includes(itemValue)}
                        onChange={() => onChangeSelectValues(itemValue)}
                        disableLabel={true}
                      >
                        {itemContent}
                      </Checkbox>
                    </>
                  ) : (
                    <span className={styles["select__dropdownItemTxt"]}>
                      {itemContent}
                    </span>
                  )}
                  {item.button}
                </button>
              );
            }
          )}
        </div>
      </TransitionProvider>
    </div>
  );
};

export default Select;
