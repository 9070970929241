import {
  archiveIcon,
  deleteIcon,
  editIcon,
  unArchiveIcon,
} from "../../../assets/svg";
import Svg from "../../layout/Svg/Svg";

import { ReactNode, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { typeOptionNames } from "../../../constants/propertyTexts";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { IProperty } from "../../../models/IProperty";
import { IFilters, IFiltersQuery } from "../../../models/UI/IFilters";
import { adminEditPropertiyPagePath } from "../../../router/path";
import {
  archiveProperty,
  deleteProperty,
  getArchivedPropertyList,
  getPropertyList,
  unarchiveProperty,
} from "../../../store/slices/propertiesSlice";
import { dispatchWithErrorHandling } from "../../../store/tools/dispatchWithErrorHandling";
import { scrollTop } from "../../../utils/functions/scrollTop";
import DataLoader from "../../layout/DataLoader/DataLoader";
import DeleteModal from "../../layout/DeleteModal/DeleteModal";
import PaginationStyled from "../../layout/PaginationStyled/PaginationStyled";
import styles from "./PropertiesTable.module.scss";

type Props = {
  isArchive?: boolean;
};

const flexIndexes = [1, 2, 2, 2, 1, 1, 1, 1];

const setRows = (
  onDelete: (id: number) => void,
  onArchive: (id: number) => void,
  onEdit: (id: number) => void,
  isArchive?: boolean
) =>
  [
    {
      title: "ID",
      key: "id",
    },

    {
      title: "Title",
      key: "title",
    },
    {
      title: "Location",
      key: "location",
      render: (item) => item.location?.name,
    },
    {
      title: "Property type",
      key: "type",
      render: (item) => typeOptionNames[item.type],
    },
    {
      title: "Rooms",
      key: "rooms",
    },
    {
      title: "Area",
      key: "flat",
    },
    {
      title: "Price",
      key: "price",
    },
    {
      title: "Action",
      key: "id",
      render: (item) => (
        <div className={styles.propertiesTable__buttonsWrapper}>
          <button
            title="Edit"
            onClick={() => onEdit(item.id)}
            className={styles.propertiesTable__colItemBtn}
          >
            <Svg
              id={editIcon}
              className={styles.propertiesTable__colItemBtnIcon}
            />
          </button>
          <button
            title="Delete"
            onClick={() => onDelete(item.id)}
            className={styles.propertiesTable__colItemBtn}
          >
            <Svg
              id={deleteIcon}
              className={styles.propertiesTable__colItemBtnIcon}
            />
          </button>
          <button
            title={isArchive ? "Unarchive" : "Archive"}
            onClick={() => onArchive(item.id)}
            className={styles.propertiesTable__colItemBtn}
          >
            <Svg
              id={isArchive ? unArchiveIcon : archiveIcon}
              className={styles.propertiesTable__colItemBtnIcon}
            />
          </button>
        </div>
      ),
    },
  ] as {
    title: string;
    key: keyof IProperty;
    render?: (item: IProperty) => ReactNode;
  }[];

const PropertiesTable = ({ isArchive }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const resultCount = useAppSelector(
    (state) =>
      state.properties[isArchive ? "archiveResultCount" : "resultCount"]
  );
  const properties = useAppSelector(
    (state) => state.properties[isArchive ? "archiveData" : "data"]
  );
  const loading = useAppSelector(
    (state) => state.properties[isArchive ? "getArchiveLoading" : "getLoading"]
  );
  const [deletingItemId, setDeletingItemId] = useState<number | null>(null);
  const [archiveItemId, setArchiveItemId] = useState<number | null>(null);

  const [curPage, setCurPage] = useState(1);

  useEffect(() => {
    const filters: IFiltersQuery = {
      minPrice: undefined,
      maxPrice: undefined,
      maxFlat: undefined,
      minFlat: undefined,
      type: undefined,
      page: undefined,
      sort: undefined,
      pageSize: undefined,
      location: undefined,
      rooms: undefined,
    };

    const queryParams = new URLSearchParams(location.search);

    if (queryParams.get("page"))
      setCurPage(+(queryParams.get("page") as string));

    for (let k in filters) {
      const key = k as keyof IFilters;
      if (queryParams.get(key)) {
        filters[key] = queryParams.get(key) as string;
      }
    }
    isArchive
      ? dispatch(getArchivedPropertyList(filters))
      : dispatch(getPropertyList(filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const onPageChange = (page: number) => {
    searchParams.set("page", page.toString());
    scrollTop();

    setSearchParams(searchParams);
  };

  const rows = setRows(
    (id) => setDeletingItemId(id),
    (id) => setArchiveItemId(id),
    (id) => {
      navigate(`${adminEditPropertiyPagePath}/${id}`);
      scrollTop();
    },
    isArchive
  );

  const onDeleteProperty = () => {
    if (deletingItemId) {
      return dispatchWithErrorHandling(
        dispatch,
        deleteProperty({ id: deletingItemId }),
        "Property deleted"
      );
    }
  };

  const onArchive = () => {
    if (archiveItemId) {
      const action = isArchive
        ? unarchiveProperty({ id: archiveItemId })
        : archiveProperty({ id: archiveItemId });

      return dispatchWithErrorHandling(
        dispatch,
        action,
        `Property ${isArchive ? "unarchived" : "archived"}`
      );
    }
  };

  return (
    <>
      {properties && properties.length ? (
        <div className={styles.propertiesTable}>
          <div className={styles.propertiesTable__wrapper}>
            <div className={styles.propertiesTable__header}>
              {rows.map((item, index) => (
                <h6
                  className={styles.propertiesTable__headerItem}
                  key={index}
                  style={{ flex: flexIndexes[index] }}
                >
                  {item.title}
                </h6>
              ))}
            </div>
            {properties &&
              properties.length &&
              properties.map((property) => (
                <div key={property.id} className={styles.propertiesTable__col}>
                  {rows.map((item, index) => (
                    <div
                      key={index}
                      style={{ flex: flexIndexes[index] }}
                      className={styles.propertiesTable__colItem}
                    >
                      {item?.render
                        ? item?.render(property)
                        : (property?.[item.key] as string | number)}
                    </div>
                  ))}
                </div>
              ))}
          </div>
        </div>
      ) : (
        <DataLoader
          isLoaded={!!properties && !loading}
          show={!properties || !properties.length}
          text="Nothing found"
        />
      )}

      <PaginationStyled
        onPageChange={onPageChange}
        totalCount={resultCount}
        curPage={curPage}
      />
      <DeleteModal
        show={!!deletingItemId}
        onClose={() => setDeletingItemId(null)}
        title={"Are you sure you want to delete this property?"}
        onConfirm={onDeleteProperty}
      />
      <DeleteModal
        show={!!archiveItemId}
        onClose={() => setArchiveItemId(null)}
        title={`Are you sure you want to ${
          isArchive ? "unarchive" : "archive"
        } this property?`}
        btnTxt={isArchive ? "Unarchive" : "Archive"}
        onConfirm={onArchive}
      />
    </>
  );
};

export default PropertiesTable;
