import { useNavigate } from "react-router-dom";

import Svg from "../../layout/Svg/Svg";

import {
  buildingIcon,
  buisnessCenterIcon,
  flatIcon,
  houseIcon,
  locationIcon,
} from "../../../assets/svg";
import { EPropertyTypes, IProperty } from "../../../models/IProperty";
import { propertiesPagePath } from "../../../router/path";
import { imagePath } from "../../../utils/functions/files";
import { formatNumber } from "../../../utils/functions/formatNumber";
import { scrollTop } from "../../../utils/functions/scrollTop";

import styles from "./PropertyCard.module.scss";

type Props = {
  item: IProperty;
  className?: string;
};

const propertytypeicons = {
  [EPropertyTypes.house]: houseIcon,
  [EPropertyTypes.building]: buildingIcon,
  [EPropertyTypes.buisnessCenter]: buisnessCenterIcon,
};

const PropertyCard = ({ item, className }: Props) => {
  const navigate = useNavigate();

  const mainImg =
    item.images.find((item) => item.isMainImage)?.path || item.images[0]?.path;
  return (
    <div
      onClick={() => {
        navigate(`${propertiesPagePath}/${item.id}`);
        scrollTop();
      }}
      className={`${styles.propertyCard} ${className || ""}`}
    >
      <img
        crossOrigin="anonymous"
        src={imagePath(mainImg)}
        alt="property"
        className={styles.propertyCard__img}
      />
      <div className={styles.propertyCard__main}>
        <div>
          <h5 className={styles.propertyCard__priceTxt}>
            € {formatNumber(item.price)}
          </h5>
          <h6 className={styles.propertyCard__titleTxt}>{item.title}</h6>
        </div>
        <div>
          <div className={styles.propertyCard__location}>
            <Svg
              id={locationIcon}
              className={styles.propertyCard__locationIcon}
            />
            <span className={styles.propertyCard__locationTxt}>
              {item.location.name}
            </span>
          </div>
          <div className={styles.propertyCard__footer}>
            <div className={styles.propertyCard__footerItem}>
              <Svg
                id={propertytypeicons[item.type]}
                className={`${styles.propertyCard__footerIcon} ${
                  styles[
                    `propertyCard__footerTypeIcon_${
                      propertytypeicons[item.type]
                    }`
                  ]
                }`}
              />
              {item.type !== EPropertyTypes.buisnessCenter && (
                <span className={styles.propertyCard__footerItemTxt}>
                  {item?.rooms}
                </span>
              )}
            </div>
            <div className={styles.propertyCard__footerItem}>
              <Svg
                id={flatIcon}
                className={styles.propertyCard__footerFloorIcon}
              />
              <span className={styles.propertyCard__footerItemTxt}>
                {item.flat} m²
              </span>
            </div>
            <p className={styles.propertyCard__idTxt}>
              <span className="primaryTxt">ID </span>
              {item.id}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyCard;
