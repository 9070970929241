import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../hooks/redux";

import PropertiesSlider from "../../global/PropertiesSlider/PropertiesSlider";

import styles from "./SinglePropertySlider.module.scss";

const SinglePropertySlider = () => {
  const params = useParams();
  const data = useAppSelector((state) => state.properties.data);

  const curItem = params.id
    ? data?.find((item) => item.id === +(params.id as string))
    : null;

  return (
    <>
      {curItem?.type && (
        <section className={styles.singlePropertySlider}>
          <PropertiesSlider
            type={curItem.type}
            id={params.id ? +params.id : undefined}
            title="Inne oferty"
          />
        </section>
      )}
    </>
  );
};

export default SinglePropertySlider;
