import { typeOptionNames } from "../../../constants/propertyTexts";
import { useAppSelector } from "../../../hooks/redux";
import { EPropertyTypes } from "../../../models/IProperty";

import styles from "./PropertyTypesTabBar.module.scss";

type Props = {
  onChange: (val: EPropertyTypes | "") => void;
  className?: string;
  activeTab?: EPropertyTypes | "all";
};

const tabs: { key: EPropertyTypes | "all"; name: string }[] = [
  {
    key: "all",
    name: "Wszystkie",
  },
  {
    key: EPropertyTypes.building,
    name: typeOptionNames.building,
  },
  {
    key: EPropertyTypes.house,
    name: typeOptionNames.house,
  },
  {
    key: EPropertyTypes.buisnessCenter,
    name: typeOptionNames.buisnessCenter,
  },
];

const PropertyTypesTabBar = ({ onChange, className, activeTab }: Props) => {
  const types = useAppSelector((state) => state.properties.filterCounts.types);
  const totalCount = useAppSelector((state) => state.properties.totalCount);

  const counts: { [key in EPropertyTypes | "all"]: number } = {
    all: totalCount || 0,
    [EPropertyTypes.building]: types.building,
    [EPropertyTypes.house]: types.house,
    [EPropertyTypes.buisnessCenter]: types.buisnessCenter,
  };

  return (
    <div
      className={`${styles.propertyTypesTabBar} ${
        totalCount === null ? "hidden" : ""
      } ${className || ""}`}
    >
      {tabs.map((item) => (
        <button
          disabled={totalCount === null || !counts[item.key]}
          onClick={() => onChange(item.key === "all" ? "" : item.key)}
          key={item.key}
          className={`${styles.propertyTypesTabBar__typeBtn} ${
            activeTab === item.key
              ? styles.propertyTypesTabBar__typeBtn_active
              : ""
          }`}
        >          
            {item.name} ({counts[item.key]})          
        </button>
      ))}
    </div>
  );
};

export default PropertyTypesTabBar;
