import React, {FC, memo, ReactNode} from 'react';

import styles from "./MainBtn.module.scss"

interface MainBtnProps extends React.ButtonHTMLAttributes<any> {
    className?: string,
    isNegative?: boolean,
    children: ReactNode
}

const MainBtn: FC<MainBtnProps> = memo(({className,isNegative, children, ...properties}) => {
    return (
        <button
            className={`${styles['mainBtn']} ${isNegative ? styles.mainBtn_negative : ""} ${className ? className : ''}`}
            {...properties}
        >{children}</button>
    );
})

export default MainBtn;