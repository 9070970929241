import { memo } from "react";
import { useImageLoader } from "../../../hooks/useImageLoader";
import styles from "./Header.module.scss";

type Props = {
  backgroundClass: string;
  title: string;
  subtitle: string;
  mainImage: string;
  webpImage: string;
};

const Header = ({
  backgroundClass,
  title,
  subtitle,
  mainImage,
  webpImage,
}: Props) => {
  const loading = useImageLoader(webpImage, mainImage);

  return (
    <header
      className={`${styles.header} ${
        loading ? styles.header_load : ""
      } ${backgroundClass}`}
    >
      <h1 className={styles.header__title}>{title}</h1>
      <h2 className={styles.header__subtitle}>{subtitle}</h2>
    </header>
  );
};

export default memo(Header);
