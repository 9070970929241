import { useRef, useState } from "react";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper/types";

import styles from "./SinglePropertyImagesSlider.module.scss";

import {
  sliderArrowLeftIcon,
  sliderArrowRightIcon,
} from "../../../../assets/svg";
import { IPropertyImage } from "../../../../models/IProperty";
import { imagePath } from "../../../../utils/functions/files";
import Svg from "../../../layout/Svg/Svg";

type Props = {
  images: IPropertyImage[] | undefined;
};

const SinglePropertyImagesSlider = ({ images }: Props) => {
  const dotsSliderRef = useRef<SwiperType | null>(null);
  const mainSliderRef = useRef<SwiperType | null>(null);
  const nextButtonRef = useRef(null);
  const prevButtonRef = useRef(null);
  const [activeDot, setActiveDot] = useState(0);

  const sortedImages = (images || []).slice().sort((a, b) => {
    if (a.isMainImage === true && b.isMainImage !== true) {
      return -1;
    } else if (a.isMainImage !== true && b.isMainImage === true) {
      return 1;
    } else {
      return -1;
    }
  });

  const onSlideMainSlider = (swiper: SwiperType) => {
    setActiveDot(swiper.activeIndex);
    dotsSliderRef.current?.slideTo(swiper.activeIndex);
  };

  const onClickDot = (index: number) => {
    setActiveDot(index);
    dotsSliderRef.current?.slideTo(index);
    mainSliderRef.current?.slideTo(index);
  };

  return (
    <div className={styles.singlePropertyImagesSlider}>
      <div className={styles.singlePropertyImagesSlider__mainSliderWrapper}>
        {images?.length ? (
          <Swiper
            onSlideChange={onSlideMainSlider}
            onSwiper={(swiper: SwiperType) => {
              mainSliderRef.current = swiper;
            }}
            className={styles.singlePropertyImagesSlider__mainSlider}
            slidesPerView={1}
            modules={[Navigation]}
            navigation={{
              nextEl:
                styles.singlePropertyImagesSlider__mainSliderArrowBtn_right,
              prevEl:
                styles.singlePropertyImagesSlider__mainSliderArrowBtn_left,
            }}
            onBeforeInit={(swiper: SwiperType) => {
              if (typeof swiper.params.navigation === "object")
                swiper.params.navigation.nextEl = nextButtonRef.current;
              if (typeof swiper.params.navigation === "object")
                swiper.params.navigation.prevEl = prevButtonRef.current;
            }}
          >
            {sortedImages.map((item) => (
              <SwiperSlide key={item.id}>
                <img
                  crossOrigin="anonymous"
                  src={imagePath(item.path)}
                  alt="slide"
                  className={styles.singlePropertyImagesSlider__mainSliderImg}
                />
              </SwiperSlide>
            ))}
            <button
              ref={prevButtonRef}
              className={`${styles.singlePropertyImagesSlider__mainSliderArrowBtn} ${styles.singlePropertyImagesSlider__mainSliderArrowBtn_left}`}
            >
              <Svg
                id={sliderArrowLeftIcon}
                className={styles.singlePropertyImagesSlider__arrowIcon}
              />
            </button>
            <button
              ref={nextButtonRef}
              className={`${styles.singlePropertyImagesSlider__mainSliderArrowBtn} ${styles.singlePropertyImagesSlider__mainSliderArrowBtn_right}`}
            >
              <Svg
                id={sliderArrowRightIcon}
                className={styles.singlePropertyImagesSlider__arrowIcon}
              />
            </button>
          </Swiper>
        ) : (
          <div
            className={styles.singlePropertyImagesSlider__mainSliderImg}
          ></div>
        )}
      </div>
      <div className={styles.singlePropertyImagesSlider__dotsSliderWrapper}>
        {images?.length ? (
          <Swiper
            className={styles.singlePropertyImagesSlider__dotsSlider}
            slidesPerView={5}
            spaceBetween={10}
            noSwiping
            onSwiper={(swiper: SwiperType) => {
              dotsSliderRef.current = swiper;
            }}
          >
            {sortedImages.map((item, index) => (
              <SwiperSlide key={item.id}>
                <img
                  crossOrigin="anonymous"
                  onClick={() => onClickDot(index)}
                  src={imagePath(item.path)}
                  alt="slide"
                  className={`${
                    styles.singlePropertyImagesSlider__dotsSliderImg
                  } ${
                    activeDot === index
                      ? styles.singlePropertyImagesSlider__dotsSliderImg_active
                      : ""
                  }`}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <div className={styles.singlePropertyImagesSlider__dotsSliderIloader}>
            <div
              className={styles.singlePropertyImagesSlider__dotsSliderImg}
            ></div>
            <div
              className={styles.singlePropertyImagesSlider__dotsSliderImg}
            ></div>
            <div
              className={styles.singlePropertyImagesSlider__dotsSliderImg}
            ></div>
            <div
              className={styles.singlePropertyImagesSlider__dotsSliderImg}
            ></div>
            <div
              className={styles.singlePropertyImagesSlider__dotsSliderImg}
            ></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SinglePropertyImagesSlider;
