import { unwrapResult } from "@reduxjs/toolkit";
import { openTooltip } from "../slices/UISlice";
import { AppDispatch } from "../store";

export async function dispatchWithErrorHandling(
  dispatch: AppDispatch,
  action: any,
  tooltipTxt?: string
) {
  try {
    const result = await dispatch(action);

    unwrapResult(result);
    if (tooltipTxt) dispatch(openTooltip(tooltipTxt));
  } catch (error) {
    throw new Error(`Error occurred: ${error}`);
  }
}
