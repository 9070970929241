import AboutHeader from '../components/AboutPage/AboutHeader/AboutHeader'
import AboutWrapper from '../components/AboutPage/AboutWrapper/AboutWrapper'
import Head from '../components/global/Head/Head'


const AboutPage = () => {
  return (
    <>
    <Head
        keyword={"agencja nieruchomości, deweloper, raty, obsługa transakcji, doradztwo w zakresie, nieruchomości, mieszkania w Bułgarii, sprzedaż ratalna nieruchomości,"}
        title={
          "Agencja nieruchomości | Finansowanie, doradztwo, wsparcie"
        }
        ogTitle={
          "Agencja nieruchomości | Finansowanie, doradztwo, wsparcie"
        }
        description={
          "Oferujemy sprzedaż nieruchomości w Bułgarii, w tym sprzedaż na raty z pierwszą wpłatą 10% ceny, a pozostałe 90% rozłożone na stałe raty do 10 lat."
        }
        ogDescription={
          "Oferujemy sprzedaż nieruchomości w Bułgarii, w tym sprzedaż na raty z pierwszą wpłatą 10% ceny, a pozostałe 90% rozłożone na stałe raty do 10 lat."
        }
      />
      <AboutHeader/>
      <div className='container'>
        <AboutWrapper/>
      </div>
    </>
  )
}

export default AboutPage