import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import { useAppDispatch } from "../../hooks/redux";
import AppRouter from "../../router/AppRouter";
import { adminPagePath } from "../../router/path";
import { checkIsLoggedIn } from "../../store/slices/userSlice";
import AdminNavbar from "../Admin/AdminNavbar/AdminNavbar";
import AdminWrapper from "../Admin/AdminWrapper/AdminWrapper";
import Footer from "../global/Footer/Footer";
import Head from "../global/Head/Head";
import Navbar from "../global/Navbar/Navbar";
import Tooltip from "../layout/Tooltip/Tooltip";

function App() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const isAdmin = location.pathname.startsWith(adminPagePath);

  useEffect(() => {
    dispatch(checkIsLoggedIn());
  });

  const Wrapper = isAdmin ? AdminWrapper : Fragment;
  return (
    <>
      <Head
        keyword={
          "apartamenty, domy, nieruchomości komercyjne, mieszkania, nieruchomości w Bułgarii, kupno mieszkania, sprzedaż domu, domy nad morzem, nieruchomości komercyjne, inwestycje w Bułgarii, agencja nieruchomości, biznes w Bułgarii, nieruchomości wypoczynkowe w Bułgarii, ceny nieruchomości w Bułgarii, Bułgaria nieruchomości, dom w Bułgarii, mieszkanie w Bułgarii, nieruchomość Bułgaria, tani dom w Bułgarii, tanie mieszkanie w Bułgarii, na raty, apartament Bułgaria "
        }
        title={"Nieruchomości w Bułgarii. Pierwsza wpłata w wysokości 10% ceny."}
        ogTitle={"Nieruchomości w Bułgarii. Pierwsza wpłata w wysokości 10% ceny."}
        description={
          "Duży wybór mieszkań, domów i nieruchomości komercyjnych nad morzem z korzystnymi warunkami, szerokim zakresem usług i pełną obsługą transakcji."
        }
        ogDescription={
          "Duży wybór mieszkań, domów i nieruchomości komercyjnych nad morzem z korzystnymi warunkami, szerokim zakresem usług i pełną obsługą transakcji."
        }
      />
      {isAdmin ? <AdminNavbar /> : <Navbar />}
      <Wrapper>
        <AppRouter />
      </Wrapper>

      {!isAdmin && <Footer />}
      <Tooltip />
    </>
  );
}

export default App;
