import PropertiesFilter from "../components/global/PropertiesFilter/PropertiesFilter";
import PropertiesList from "../components/PropertiesPage/PropertiesList/PropertiesList";
import Head from '../components/global/Head/Head'

const PropertiesPage = () => {
  return (
    <>
    <Head
       keyword={"Bułgaria, nieruchomość nad morzem, inwestycje w nieruchomości, Słoneczny Brzeg, Burgas, Elenite, Warna, Złote Piaski, tanie nieruchomości, znane regiony w Bułgarii, nieruchomości w kurorcie, Rynek nieruchomości w Bułgarii, sprzedaż ratalna nieruchomości"}
       title={
         "Domy, Mieszkania, Apartamenty w Bułgarii"
       }
       ogTitle={
         "Domy, Mieszkania, Apartamenty w Bułgarii"
       }
       description={
         "Nieruchomości w Słonecznym Brzegu, Warnie, Elenite i innych atrakcyjnych regionach Bułgarii. Przystępne ceny, możliwość rat."
       }
       ogDescription={
         "Nieruchomości w Słonecznym Brzegu, Warnie, Elenite i innych atrakcyjnych regionach Bułgarii. Przystępne ceny, możliwość rat."
       }
     />
    <main className="container">
      <div className="headerDistance"></div>
      <PropertiesFilter />
      <PropertiesList />
    </main>
   </>
  );
};

export default PropertiesPage;
