import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../hooks/redux";
import { useFilter } from "../../../hooks/useFilter";

import PropertyTypesTabBar from "../../global/PropertyTypesTabBar/PropertyTypesTabBar";
import MainBtn from "../../layout/MainBtn/MainBtn";
import SecondayBtn from "../../layout/SecondayBtn/SecondayBtn";
import Select from "../../layout/Select/Select";

import { EPropertyTypes } from "../../../models/IProperty";
import { IFilters, IFiltersQuery } from "../../../models/UI/IFilters";
import styles from "./PropertiesFilter.module.scss";

const typeResultNames = {
  all: ["nieruchomosci", "nieruchomość"],
  [EPropertyTypes.building]: ["apartamentów", "apartamentów"],
  [EPropertyTypes.house]: ["domów", "dom"],
  [EPropertyTypes.buisnessCenter]: ["obiektów biznesowych", "obiekt biznesowy"],
};

interface Props {
  hideTabs?: boolean;
  hideTitle?: boolean;
  disableTranslate?: boolean;
  isArchive?: boolean;
}

const PropertiesFilter = ({
  hideTabs,
  hideTitle,
  disableTranslate,
  isArchive,
}: Props) => {
  const location = useLocation();
  const resultCount = useAppSelector((state) => state.properties.resultCount);
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTypeTab, setActiveTypeTab] = useState("");

  const updateQueryUrl = (data: IFiltersQuery) => {
    const queryParams = new URLSearchParams(location.search);

    const filters = data;
    for (let k in filters) {
      const key = k as keyof typeof filters;
      if (filters[key]) searchParams.set(key, filters[key] as string);
      if (!filters[key] && queryParams.has(key)) searchParams.delete(key);
    }
    setSearchParams(searchParams);
  };
  const {
    onSubmit,
    onClearFilters,
    onTabChange,
    setFormData,
    locationSelectProps,
    minFlatSelectProps,
    maxFlatSelectProps,
    typeSelectProps,
    minPriceSelectProps,
    maxPriceSelectProps,
    floorSelectProps,
    initialState,
  } = useFilter(updateQueryUrl, disableTranslate, isArchive);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const filters = { ...initialState };
    for (let k in filters) {
      const key = k as keyof IFilters;

      if (queryParams.get(key)) {
        filters[key] = queryParams.get(key) || "";
      }

      setFormData(filters);
    }
    setActiveTypeTab(queryParams.get("type") || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <section className={styles.propertiesFilter}>
      {!hideTitle && (
        <>
          <h2 className={`titleH3 ${styles.propertiesFilter__title}`}>
            Znajdź nieruchomość
          </h2>
        </>
      )}
      <form onSubmit={onSubmit} className={styles.propertiesFilter__form}>
        <div className={styles.propertiesFilter__formCol}>
          <Select {...locationSelectProps} />
        </div>
        <div className={styles.propertiesFilter__formCol}>
          <Select {...typeSelectProps} />
        </div>
        <div className={styles.propertiesFilter__formCol}>
          <Select {...floorSelectProps} />
        </div>
        <div className={styles.propertiesFilter__formRow}>
          <Select
            {...minFlatSelectProps}
            className={styles.propertiesFilter__formRowSelect}
          />
          <Select
            {...maxFlatSelectProps}
            className={styles.propertiesFilter__formRowSelect}
          />
        </div>
        <div className={styles.propertiesFilter__formRow}>
          <Select
            {...minPriceSelectProps}
            className={styles.propertiesFilter__formRowSelect}
          />
          <Select
            {...maxPriceSelectProps}
            className={styles.propertiesFilter__formRowSelect}
          />
        </div>
        <div className={styles.propertiesFilter__formRow}>
          <MainBtn className={styles.propertiesFilter__btn}>
            <>{disableTranslate ? "Search" : "Wyszukaj"}</>
          </MainBtn>
          <SecondayBtn
            type="button"
            onClick={onClearFilters}
            className={`${styles.propertiesFilter__btn} ${styles.propertiesFilter__clearBtn}`}
          >
            <>{disableTranslate ? "Reset" : "Zresetuj filtr"}</>
          </SecondayBtn>
        </div>
        <div className={styles.propertiesFilter__formCol}></div>
        <div className={styles.propertiesFilter__formCol}></div>
      </form>
      {!hideTabs && (
        <>
          <PropertyTypesTabBar
            activeTab={(activeTypeTab as EPropertyTypes) || "all"}
            onChange={onTabChange}
            className={styles.propertiesFilter__tabBar}
          />
          <>
            <h6 className={styles.propertiesFilter__countTxt}>
              {resultCount
                ? `Znaleziono ${resultCount}
                ${
                  typeResultNames[
                    (activeTypeTab || "all") as keyof typeof typeResultNames
                  ][resultCount === 1 ? 1 : 0]
                }`
                : "Nic nie znaleziono"}
            </h6>
          </>
        </>
      )}
    </section>
  );
};

export default PropertiesFilter;
