import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { getOneProperty } from "../../../store/slices/propertiesSlice";

import AccordionProvider from "../../layout/AccordionProvider/AccordionProvider";
import List from "../../layout/List/List";
import Map from "../../layout/Map/Map";
import SinglePropertyImagesSlider from "./SinglePropertyImagesSlider/SinglePropertyImagesSlider";

import {
  objectStatusNames,
  typeOptionNames,
} from "../../../constants/propertyTexts";
import { formatDateLong } from "../../../utils/functions/date";
import { formatNumber } from "../../../utils/functions/formatNumber";
import { getPropertyLocation } from "../../../utils/functions/getPropertyLocation";
import styles from "./SinglePropertyMain.module.scss";

const SinglePropertyMain = () => {
  const dispatch = useAppDispatch();
  const params = useParams();

  const data = useAppSelector((state) => state.properties.data);

  const curItem = params.id
    ? data?.find((item) => item.id === +(params.id as string))
    : null;

  useEffect(() => {
    if (params.id) dispatch(getOneProperty({ id: +params.id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const curLocationOnMap = curItem?.locationOnMap
    ? getPropertyLocation(curItem?.locationOnMap)
    : null;

  return (
    <section className={styles.singlePropertyMain}>
      <div className={styles.singlePropertyMain__wrapper}>
        <div className={styles.singlePropertyMain__main}>
          <SinglePropertyImagesSlider images={curItem?.images} />
        </div>
        <aside className={styles.singlePropertyMain__aside}>
          <h3
            className={`${styles.singlePropertyMain__titleTxt} ${
              !curItem?.title ? "txtLoader" : ""
            }`}
          >
            {curItem?.title}&nbsp;
          </h3>

          <h4
            className={`${styles.singlePropertyMain__priceTxt} ${
              !curItem?.price ? "txtLoader" : ""
            }`}
          >
            € {curItem?.price && formatNumber(curItem?.price)}
          </h4>
          <div className={styles.singlePropertyMain__info}>
            <div className={styles.singlePropertyMain__infoItem}>
              <p className={styles.singlePropertyMain__infoItemTitle}>
                Lokalizacja:
              </p>
              <p
                className={`${styles.singlePropertyMain__infoItemTxt} ${
                  !curItem?.location ? "txtLoader" : ""
                }`}
              >
                {curItem?.location?.name}&nbsp;
              </p>
            </div>

            <div className={styles.singlePropertyMain__infoItem}>
              <p className={styles.singlePropertyMain__infoItemTitle}>
                Typ nieruchomości:
              </p>
              <p
                className={`${styles.singlePropertyMain__infoItemTxt} ${
                  !curItem?.type ? "txtLoader" : ""
                }`}
              >
                {curItem?.type && typeOptionNames[curItem?.type]}&nbsp;
              </p>
            </div>

            <AccordionProvider
              active={!!curItem?.rooms}
              innerClass={`${styles.singlePropertyMain__infoItem} ${
                !curItem?.rooms
                  ? styles.singlePropertyMain__infoItem_hidden
                  : ""
              }`}
            >
              <p className={styles.singlePropertyMain__infoItemTitle}>
                Pokoje:
              </p>
              <p className={styles.singlePropertyMain__infoItemTxt}>
                {curItem?.rooms}&nbsp;
              </p>
            </AccordionProvider>
            <div className={styles.singlePropertyMain__infoItem}>
              <p className={styles.singlePropertyMain__infoItemTitle}>
                Powierzchnia:
              </p>
              <p
                className={`${styles.singlePropertyMain__infoItemTxt} ${
                  !curItem?.flat ? "txtLoader" : ""
                }`}
              >
                {curItem?.flat && formatNumber(curItem?.flat)} m²
              </p>
            </div>

            <AccordionProvider
              active={!!curItem?.plotSize}
              innerClass={`${styles.singlePropertyMain__infoItem} ${
                !curItem?.plotSize
                  ? styles.singlePropertyMain__infoItem_hidden
                  : ""
              }`}
            >
              <p className={styles.singlePropertyMain__infoItemTitle}>
                Działka:
              </p>
              <p
                className={`${styles.singlePropertyMain__infoItemTxt} ${
                  !curItem?.flat ? "txtLoader" : ""
                }`}
              >
                {curItem?.flat && formatNumber(curItem?.plotSize)} m²
              </p>
            </AccordionProvider>
            <div className={styles.singlePropertyMain__infoItem}>
              <p className={styles.singlePropertyMain__infoItemTitle}>
                Piętro:
              </p>
              <p className={styles.singlePropertyMain__infoItemTxt}>
                {curItem?.floor}&nbsp;
              </p>
            </div>
            <div className={styles.singlePropertyMain__infoItem}>
              <p className={styles.singlePropertyMain__infoItemTitle}>
                Stan obiektu:
              </p>
              <p
                className={`${styles.singlePropertyMain__infoItemTxt} ${
                  !curItem?.objectStatus ? "txtLoader" : ""
                }`}
              >
                {curItem?.objectStatus &&
                  objectStatusNames[curItem?.objectStatus]}
                &nbsp;
              </p>
            </div>
            <AccordionProvider
              active={!!curItem?.distanceFromTheBeach}
              innerClass={`${styles.singlePropertyMain__infoItem} ${
                !curItem?.distanceFromTheBeach
                  ? styles.singlePropertyMain__infoItem_hidden
                  : ""
              }`}
            >
              <p className={styles.singlePropertyMain__infoItemTitle}>
                Odległość od plaży:
              </p>
              <p className={styles.singlePropertyMain__infoItemTxt}>
                {curItem?.distanceFromTheBeach} m
              </p>
            </AccordionProvider>
            <div className={styles.singlePropertyMain__infoItem}>
              <p className={styles.singlePropertyMain__infoItemTitle}>
                Identyfikator obiektu:
              </p>
              <p
                className={`${styles.singlePropertyMain__infoItemTxt} ${
                  !curItem?.id ? "txtLoader" : ""
                }`}
              >
                {curItem?.id}&nbsp;
              </p>
            </div>
            <div className={styles.singlePropertyMain__infoItem}>
              <p className={styles.singlePropertyMain__infoItemTitle}>
                Wstawiono:
              </p>
              <p
                className={`${styles.singlePropertyMain__infoItemTxt} ${
                  !curItem?.createdAt ? "txtLoader" : ""
                }`}
              >
                {curItem?.createdAt && formatDateLong(curItem?.createdAt)}
                &nbsp;
              </p>
            </div>
          </div>
        </aside>
      </div>
      <div className={styles.singlePropertyMain__wrapper}>
        <div className={styles.singlePropertyMain__main}>
          <h5 className={styles.singlePropertyMain__descriptionTitle}>
            Opis nieruchomości
          </h5>
          <div
            className={`contentTxt ${
              styles.singlePropertyMain__descriptionTxt
            } ${!curItem?.description ? "txtLoader" : ""}`}
            dangerouslySetInnerHTML={{ __html: curItem?.description || "" }}
          ></div>
        </div>

        <aside className={styles.singlePropertyMain__aside}>
          {curItem?.equipments?.length && (
            <div className={styles.singlePropertyMain__equipments}>
              <h5 className={styles.singlePropertyMain__equipmentsTitle}>
                Dodatkowe informacje
              </h5>
              <div className={styles.singlePropertyMain__equipmentsList}>
                <List
                  showBulletForSingle={true}
                  ItemclassName={styles.singlePropertyMain__equipmentsListItem}
                  list={curItem?.equipments
                    ?.slice(0, Math.round(curItem?.equipments.length / 2))
                    .map((item) => item.name)}
                />
                <List
                  showBulletForSingle={true}
                  ItemclassName={styles.singlePropertyMain__equipmentsListItem}
                  list={curItem?.equipments
                    ?.slice(Math.round(curItem?.equipments.length / 2))
                    .map((item) => item.name)}
                />
              </div>
            </div>
          )}
          <h5 className={styles.singlePropertyMain__equipmentsTitle}>
            Lokalizacja
          </h5>
          <div className={styles.singlePropertyMain__mapWrapper}>
            {curLocationOnMap && curLocationOnMap.every((item) => item) && (
              <Map
                initialLocation={[curLocationOnMap[0], curLocationOnMap[1]]}
              />
            )}
          </div>
        </aside>
      </div>
    </section>
  );
};

export default SinglePropertyMain;
